import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class ImagePackService extends BaseAPIService {

    private ROUTES = {
        select: '/imagepack/:id',
        edit: '/imagepack/id',
        list: '/imagepack/list',
        itemCreate: '/imagepack/items',
        itemDelete: '/imagepack/item/:id',
        itemUpdate: '/imagepack/item',
        copy: '/imagepack/copy',
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    select(context: any): Promise<any> {
        return this.GET(this.ROUTES.select, context).toPromise();
    }

    create(body: any): Promise<any> {
        return this.http.post(this.ROUTES.edit, body).toPromise();
    }

    update(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.edit, body).toPromise();
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    itemCreate(body: any): Promise<any> {
        return this.http.post(this.ROUTES.itemCreate, body).toPromise();
    }

    itemUpdate(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.itemUpdate, body).toPromise();
    }

    itemDelete(context: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.itemDelete, context)).toPromise();
    }

    copy(body: any): Promise<any> {
        return this.http.post(this.ROUTES.copy, body).toPromise();
    }


}
