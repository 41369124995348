import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class CategoryService extends BaseAPIService {

    private ROUTES = {
        list: '/category/list',
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    save(body): Promise<any> {
        return this.POST(this.ROUTES.list, null, body).toPromise();
    }
}
