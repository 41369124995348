import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ComponentRef } from '@angular/core';
import { Helpers } from '../../../helpers';
import { toolAttribute } from "../../../../types/types";


declare var $;

@Component({
    selector: "app-tooltips",
    templateUrl: "./tooltips.component.html",
    styleUrls: ['./tooltips.component.scss'],
})

export class TooltipsComponent implements OnInit {


    @Input() tools: toolAttribute[] = [];

    @Output() toolEvent = new EventEmitter();

    constructor() {

    }

    eventEmit(actionName) {
        this.toolEvent.emit(actionName)
    }

    ngOnInit() {

    }
    ngAfterViewInit() {
    }

}