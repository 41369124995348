<div class="m-stack__item">
    <div class="item-header">
        <span>
            {{codeNaming(code)}}
        </span>
        <div class="row btn-group">
            <button class="btn btn-outline-success ml-3" (click)="getReservation()" type="button" data-toggle="modal"
                    [attr.data-target]="'#theme-reservation-'+code">
                예약
            </button>
            <button type="submit" class="btn btn-primary ml-3" (click)="saveList()">
                순서저장
            </button>
        </div>
    </div>
    <div *ngIf="viewThemes.length == 0; else themeList">
        등록된 탭이 없습니다.
    </div>
    <ng-template #themeList>
        <div class="table-responsive tabs">
            <table class="table table-hover m-table">
                <thead class="thead-light">
                <tr>
                    <th>ID</th>
                    <th>타이틀</th>
                    <th>플랫폼</th>
                    <th class="text-center">컴포넌트</th>
                    <th class="text-center">모두보기 타입</th>
                    <th class="text-center">상태</th>
                    <th class="text-center">기능</th>
                </tr>
                </thead>
                <tbody [dragula]="code" [(dragulaModel)]="viewThemes">
                <tr *ngFor="let theme of viewThemes; let index=index" (click)="goToEdit(theme.id)"
                    class="cursor-default dragula-table-tr">
                    <td style="min-width:70px;width: 70px;">{{theme.id}}</td>
                    <td style="min-width:250px;">{{theme.title}}</td>
                    <td style="min-width:250px;">
                        <div class="platform">
                            <img *ngIf="theme.android" src="./assets/icons/ic_platform_google.png"/>
                            <img *ngIf="theme.ios" src="./assets/icons/ic_platform_apple.png"/>
                            <img *ngIf="theme.onestore" src="./assets/icons/ic_platform_onestore.png"/>
                            <img *ngIf="theme.web" src="./assets/icons/ic_platform_web.png"/>
                        </div>
                    </td>
                    <td class="text-center" style="min-width:130px;">{{typeNaming(theme.type)}}</td>
                    <td class="text-center" style="min-width:130px;">{{viewAllTypeNaming(theme.view_all_type)}}</td>
                    <td class="text-center"
                        style="min-width:90px;width:90px;">{{theme.status == 'S3' ? '활성화' : '비활성화'}}</td>
                    <td class="text-center" style="min-width:120px;width:120px;" (click)="eventPrevent($event)">
                        <div (click)="toLink(theme.id)" class="cursor-pointer">
                            <i class="fa fa-clipboard m-font-metal"></i>
                            <span class="theme--hidden">URL복사</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </ng-template>
</div>

<div class="modal fade" id="theme-reservation-{{code}}" tabindex="-1" role="dialog"
     aria-labelledby="themeReservationLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="themeReservationLabel">
                    <b>테마 순서 및 상태 예약</b>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="theme-layer position-relative">
                    <div [dragula]="'reservation_'+ code" [(dragulaModel)]="reservationThemes" class="w-100">
                        <div *ngFor="let theme of reservationThemes">
                            <div class="theme-title">
                                <span>
                                    {{theme.id}} : {{theme.title}}
                                </span>

                                <span class="m-switch m-switch--outline m-switch--primary reservation-status">
                                    <label class="mb-0">
                                        <input type="checkbox" [ngModel]="theme.status == 'S3'"
                                               (change)="statusChange($event, theme)" name="status"/>
                                        <span></span>
                                    </label>
                                </span>
                            </div>
                            <div class="pt-3 pb-3 w-25">
                                <div class="mb-3">
                                    <h6>예약 제목</h6>
                                    <input class="form-control m-input mt-2" type="text" [(ngModel)]="theme.title"/>
                                </div>
                                <div>
                                    <h6>예약 부제목</h6>
                                    <input class="form-control m-input mt-2" type="text" [(ngModel)]="theme.subtitle"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row mt-5">
                    <div class="col-sm-8">
                        <h6>
                            예약 시간
                        </h6>
                        <div class="m-form__group form-group row ">
                            <div class="input-group date col-lg-12">
                                <input type="text" class="form-control m-input"
                                       id="reservation_datetimepicker-{{code}}"/>
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="la la-calendar glyphicon-th"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 mt-4">
                        <div class="m-form__group form-group  m--align-right ">
                            <button (click)="addReservation()"
                                    class="btn btn-primary m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill">
                                테마 예약하기
                            </button>
                        </div>
                    </div>
                </div>
                <h6>
                    예약 로그
                </h6>
                <div class="m-scrollable">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="m-section__content">
                                <div class="m-demo">
                                    <div class="m-demo__preview bg-light">
                                        <div class="table-responsive">
                                            <table class="table table-hover">
                                                <thead>
                                                <tr>
                                                    <td class="w-50 m--font-success font-weight-bold">시간</td>
                                                    <td colspan="2" class="w-50 font-weight-bold">상태 <small>클릭시 예약정보
                                                        출력</small></td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr [ngClass]="{'not-booked-row' : reservation.status != 'booked' }"
                                                    *ngFor="let reservation of reservationList"
                                                    (click)="showReservationDetail(reservation)">
                                                    <td class="w-50 m--font-success">{{reservation.reservation_time | date:'yyyy-MM-dd HH:mm'}}</td>
                                                    <td class="w-25">{{reservation.status == 'booked' ? '예약됨' : (reservation.status == 'fulfill' ? '실행됨' : '취소됨')}}</td>
                                                    <td class="w-25">
                                                        <span (click)="cancelReservation(reservation)"
                                                              class="cancel-btn cursor-pointer"
                                                              *ngIf="reservation.status == 'booked'">
                                                        취소하기
                                                        </span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="m-section__content">
                                <div class="m-demo">
                                    <div class="m-demo__preview bg-white">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                <tr>
                                                    <td class="w-10 m--font-success font-weight-bold">ID</td>
                                                    <td class="w-55 m--font-success font-weight-bold">테마 제목</td>
                                                    <td class="w-55 m--font-success font-weight-bold">테마 부제목</td>
                                                    <td class="w-25 font-weight-bold">상태</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr *ngFor="let reservation of detailReservationList">
                                                    <td class="w-10 m--font-success">{{reservation.id}}</td>
                                                    <td class="w-55 m--font-success text-ellipsis">{{reservation.title}}</td>
                                                    <td class="w-55 m--font-success text-ellipsis">{{reservation.subtitle}}</td>
                                                    <td class="w-25">{{reservation.status == 'S3' ? '활성화' : '비활성화'}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
