import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare var toastr: any;

@Component({
    selector: 'app-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
    @Input('value')
    public list: any[] = [];
    @Input()
    public type: 'string' | 'number' | 'object' = 'string';
    @Input()
    public inputType: 'text' | 'select' = "text";
    @Input()
    public objectList: any[] = [];
    @Input()
    public objectValue: string = 'num';
    @Input()
    public objectName: string = 'name';
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
    public text: string = "";
    @Input()
    public placeholder: string = '';

    constructor() {
    }

    ngOnInit() {
    }

    selectTag(value) {
        if (!(typeof value === 'number') && !value.length) {
            return;
        }
        value = this.type === 'object' ?
            this.objectList.find(a => a[this.objectValue] == value) : value;
        this.addTargetTag(value);
    }

    inputTag(event) {
        if (event.keyCode !== 13) {
            return;
        }
        const value = event.target.value;
        const valueArr = value.split(',');
        valueArr.forEach(v => {
            if ((typeof v === 'number') || v.length) {
                this.addTargetTag(v);
            }
        });
        if (this.inputType == 'text') {
            this.text = '';
        }
        this.valueChange.emit(this.list);
    }

    addTargetTag(value) {
        value = typeof value === 'string' ? value.trim() : value;
        if (this.type === 'number') {
            if (isNaN(value)) {
                toastr.warning(`${value}는 숫자가 아닙니다.`);
                return;
            }
            value = parseInt(value);
        }
        if (this.list.includes(value)) {
            let valueName = this.type === 'object' ? value[this.objectName] : value;
            toastr.warning(`${valueName}는 이미 추가된 값입니다.`);
            return;
        }
        this.list.push(value);
    }

    deleteTag(index: number) {
        this.list.splice(index, 1);
        this.valueChange.emit(this.list);
    }
}
