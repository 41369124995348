import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { DragulaService } from "ng2-dragula";
import { ThemeService } from "../../../_services/theme/theme.service";
import { PremiumService } from "../../../_services/premium/premium.service";

declare var toastr: any;

@Component({
    selector: "app-theme-views-select",
    templateUrl: "theme-views-select.component.html",
    styleUrls: ["theme-views-select.component.scss"]
})
export class ThemeViewsSelectComponent implements OnInit {

    @Input('items')
    set _items(value) {
        this.items = value;
    }
    @Input()
    private list = [];
    @Output()
    public itemsChange: EventEmitter<any> = new EventEmitter<any>();
    private items = [];
    @Input()
    type: string = 'all'
    public themeViewList = [];
    public searchThemeViewList = [];
    @Input()
    excludeList = [''];
    @Input()
    useCustom: boolean = false;
    @Input()
    multiSelect: boolean = false;

    public inputValue: string = '';
    public searchMultiThemeViewList = [];

    constructor(
        private http: HttpClient,
        private router: Router,
        private dragulaService: DragulaService,
        private themeService: ThemeService,
        private premiumService: PremiumService
    ) {

    }

    ngOnInit() {
        this.getData();
    }

    async getData() {
        const itemList = await this.themeService.themeViewList();

        this.searchThemeViewList = this.useCustom ? this.list : itemList.filter(i => this.type !== 'premium' || i.type === 'premium')
            .map((item: any) => {
                let type = item.type;
                let title = item.meta.title;
                const typeName = type == 'item' ? '무료' : (type == 'premium' ? '프리미엄' : '스토리');
                let status = item.status == 'S3' ? '활성' : '비활성';
                return {
                    id: item.id,
                    rel_id: item.rel_id,
                    title,
                    type,
                    typeName,
                    status,
                    bindLabel: `[${type} ${item.rel_id}] ${title ? title : ''}`
                }
            });
        this.setItemsData();
    }

    async getCustomData() {
        const itemList = await this.premiumService.listSimple();
        this.searchThemeViewList = itemList.map(r => (
            {
                id: r.id,
                rel_id: r.id,
                title: r.title,
                type: 'premium',
                status: r.status,
                bindLabel: `[프리미엄 ${r.id}] - ${r.title} ${r.PremiumItemPromotions.length != 0 ?
                    `(프로모션 : ${r.PremiumItemPromotions[0].title})` : ''}`,
                PremiumItemPromotions: r.PremiumItemPromotions
            }
        ));
        this.setItemsData();
    }

    setItemsData() {
        this.themeViewList = this.items.map(i => this.searchThemeViewList.find(s => i.type === s.type && i.rel_id === s.rel_id));
        this.searchThemeViewList = this.searchThemeViewList.filter(s => !this.items.find(i => i.type === s.type && i.rel_id === s.rel_id));
    }

    addItem(event: any) {
        if (event) {
            event['isAdd'] = true;
            this.themeViewList.push(event);
            _.remove(this.searchThemeViewList, i => i.id == event.id);
            this.searchThemeViewList = [...this.searchThemeViewList];
        }
        this.itemsChange.emit(this.themeViewList.map(t => ({ type: t.type, rel_id: t.rel_id })));
    }

    deleteItem(i) {
        let item = this.themeViewList.slice(i, i + 1)[0];
        this.searchThemeViewList = [...this.searchThemeViewList, item];
        this.themeViewList.splice(i, 1)[0];
        this.itemsChange.emit(this.themeViewList.map(t => ({ type: t.type, rel_id: t.rel_id })));
    }

    drop() {
        this.itemsChange.emit(this.themeViewList.map(t => ({ type: t.type, rel_id: t.rel_id })));
    }

    findMultiItems(input, array) {
        const inputIds = input.split(',').map(id => id.trim());

        const result = inputIds
            .map(id => array.find(item => item.id.toString() === id))
            .filter(item => item !== undefined);

        this.searchMultiThemeViewList = result;

    }

    addMultiSelectItem() {
        this.findMultiItems(this.inputValue, this.searchThemeViewList)
        if (this.searchMultiThemeViewList) {
            this.searchMultiThemeViewList.map((item) => {
                return item['isAdd'] = true;
            })
            this.themeViewList = [...this.searchMultiThemeViewList, ...this.themeViewList];
            this.itemsChange.emit(this.themeViewList.map(t => ({ type: t.type, rel_id: t.rel_id })));
        }
    }

    getMultiSelectData() {
        this.findMultiItems(this.inputValue, this.searchThemeViewList)
    }

}
