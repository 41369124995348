<div class="form-group m-form__group ">
  <div>
    <div class="m-dropzone dropzone m-dropzone--success uploader-image d-flex justify-content-center position-relative"
         action="api/upload/multiV2"
         [id]="uploaderId"
    >
      <div class="m-dropzone__msg dz-message needsclick" center-vh fx-col>
        <h3 *ngIf="!innerImageSrc && !small" class="m-dropzone__msg-title">클릭하거나 파일을 드래그하세요.</h3>
        <span *ngIf="!innerImageSrc && !small" class="m-dropzone__msg-desc">이미지 파일만 가능</span>
        <img class="uploaded-image" *ngIf="innerImageSrc" [src]="innerImageSrc">
      </div>
      <button *ngIf="innerImageSrc" type="button" class="close" (click)="deleteImage()">&times;</button>
      <div class="add-src" (click)="openSrcModal(true)">
        <i class="la la-pencil"></i>
      </div>
    </div>
  </div>
</div>

<div id="src-modal" class="svg-modal modal normal-modal" [class.active]="srcModal">
  <div class="modal-dialog" style="max-width: 650px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          이미지 주소 등록
        </h5>
        <button class="close" type="button" (click)="openSrcModal(false)">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex">
        <input type="text" class="form-control mr-3" [(ngModel)]="inputSrc">
        <button class="btn btn-danger btn-sm px-4" (click)="addSrc()">등록</button>
      </div>
    </div>
  </div>
</div>
