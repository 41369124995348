<div class="row">
  <label class="col-form-label col-12" style="display: flex; justify-content: space-between">
    <div>
    {{ label }}
    <small class="text-danger ml-1" *ngIf="required">*필수</small>
    </div>
  </label>
  <div class="col-12 {{imageClass}}">
    <app-image-uploader
            [uploaderId]="uploaderId"
            [maxWidth]="true"
            [innerImageSrc]="value"
            (successImageUrl)="successImageUrl($event)">
    </app-image-uploader>
  </div>
</div>
