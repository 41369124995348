import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class LeafletService extends BaseAPIService {

    private ROUTES = {
        getList: 'leaflet/list',
        getListByType: 'leaflet/list/:type',
        getLeaflet: '/leaflet/:id',
        create: '/leaflet/id',
        update: '/leaflet/:id',
        delete: '/leaflet/:id',
        deleteLeaflets: '/leaflet/delete',
        currVal: '/leaflet/id/nextval',
        getGiftCategoriesList: '/leaflet/categories/list/:id',
        copy: '/leaflet/copy'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.getList).toPromise();
    }

    getListByType(type: string): Promise<any> {
        return this.GET(this.ROUTES.getListByType, { type: type }).toPromise();
    }

    leaflet(id: number): Promise<any> {
        return this.GET(this.ROUTES.getLeaflet, { id: id }).toPromise();
    }

    create(body: any): Promise<any> {
        return this.http.post(this.ROUTES.create, body).toPromise();
    }

    update(id: number, body: any): Promise<any> {
        return this.http.patch(this.inject(this.ROUTES.update, { id: id }), body).toPromise();
    }

    delete(content: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.delete, content)).toPromise();
    }

    deleteLeaflets(leafletIds: number[]): Promise<any> {
        return this.http.post(this.ROUTES.deleteLeaflets, { leafletIds }).toPromise();
    }

    currVal(): Promise<any> {
        return this.GET(this.ROUTES.currVal).toPromise();
    }

    giftCategoryList(id: number): Promise<any> {
        return this.GET(this.ROUTES.getGiftCategoriesList, { id: id }).toPromise();
    }

    copyLeaflet(id: number): Promise<any> {
        return this.http.post(this.ROUTES.copy, { id }).toPromise()
    }

}
