import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-tag-item-list',
    templateUrl: "./tag-item-list.component.html",
    styleUrls: ["./tag-item-list.component.scss"]
})
export class TagItemListComponent {

    @Input() public name: string;
    @Output() onTagSelect = new EventEmitter();
    @Input() public selectedTag: string;
    @Input()
    set tagList(tagList: string[]) {
        this._tagList = tagList;
        this.selectedTag = tagList && tagList[0] ? tagList[0] : null;
        this.selectTag(this.selectedTag);
    }
    _tagList: string[];

    selectTag(tag: string) {
        this.onTagSelect.emit(tag);
        this.selectedTag = tag;
    }

}
