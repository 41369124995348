<div class="d-flex mb-3" [ngClass]="column ? 'flex-column' : ''">
  <label class="col-form-label mr-2">
    {{ label }}
  </label>
  <span class="m-switch m-switch--outline m-switch--primary" [class.m-switch--sm]="small">
      <label>
          <input type="checkbox" [ngModel]="isActive" (ngModelChange)="changeActive($event)"/>
          <span></span>
      </label>
  </span>
</div>
