<div class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-3" id="m_login"
     style="background-image: url(./assets/app/media/img//bg/bg-2.jpg);">
    <div class="m-grid__item m-grid__item--fluid	m-login__wrapper">
        <div class="m-login__container">
            <div class="m-login__logo">
                <a href="#">
                    <img src="./assets/app/media/img//logos/damun.png">
                </a>
            </div>
            <div class="m-login__signin">
                <div class="m-login__head">
                    <h3 class="m-login__title">
                        Sign in To Forceteller Studio!
                    </h3>
                </div>
            </div>
            <div class="m-stack m-stack--ver m-stack--general " style="margin-top:5vw ; display: flex; flex-direction: column">
                <div class="m-stack__item m-stack__item--center">
                    <div class="m-login__form-action">
                        <div #btnLogin style="display:flex;justify-content: center"></div>
                        <!--form (ngSubmit)="signin('google')" #f="ngForm" class="m-login__form m-form" action="" style="margin:2rem auto  ">
                            <button style="width: 175px" [disabled]="loading" [ngClass]="{'m-loader m-loader--right m-loader--light': loading}"
                                    class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air  m-login__btn  google">
                                <div style="display: inline-flex;align-items: center">
                                    <div class="login-btn-icon google">

                                    </div>
                                    <div style="margin-top: 2px">
                                        구글!
                                    </div>
                                </div>
                            </button>
                        </form-->
                    </div>
                </div>
            </div>
            <ng-template #alertSignin></ng-template>
            <div class="m-login__account">
            </div>
        </div>
    </div>
</div>
