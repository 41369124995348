import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ForceService } from 'app/_services/force/force.service';
declare let toastr: any;
declare let mApp: any;

@Component({
    selector: 'app-force-pack-promotion-upload',
    templateUrl: './force-pack-promotion-upload.component.html',
    styleUrls: ['./force-pack-promotion-upload.component.scss'],
})
export class ForcePackPromotionUploadComponent implements OnInit {
    @Input()
    public active = false;
    @Output()
    public activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public onSuccess: EventEmitter<any> = new EventEmitter<any>();

    public uploadList = [];
    public forceChk = false;
    public isUploading = false;

    public dateRegex = /(\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) (0?[0-9]|1[0-9]|2[0-4]):(0[0-9]|[0-5][0-9]):(0[1-9]|[0-5][0-9]))/;

    constructor(private forceService: ForceService) { }

    ngOnInit(): void { }

    close() {
        this.activeChange.emit(false);
    }

    mAppDisplay(bool) {
        if (bool) {
            mApp.block(`#uploadForm`, {
                overlayColor: '#000000',
                type: 'loader',
                state: 'primary',
                message: 'Please wait...'
            });
        } else {
            mApp.unblock(`#uploadForm`);
        }
    }

    successCsv({ response }) {
        this.uploadList = this.uploadList.concat(response);
        this.forceChk = true;
    }

    addItem() {
        this.uploadList = [
            ...this.uploadList,
            {
                forcepackId: null,
                bonusForce: 0,
                startsAt: '',
                endsAt: '',
            },
        ];
    }

    deleteItem(index: number) {
        this.uploadList = this.uploadList.filter((v, i) => i !== index);
    }

    upload() {
        this.mAppDisplay(true);
        this.isUploading = true;
        this.forceService
            .reservation(this.uploadList)
            .then(() => {
                this.mAppDisplay(false);
                toastr.success('업로드가 완료되었습니다.');
                this.onSuccess.emit();
                this.close();
            })
            .catch((e) => {
                this.mAppDisplay(false);
                toastr.error(e.error.message);
            })
            .finally(() => {
                this.mAppDisplay(false);
                this.isUploading = false;
            });
    }

    download() {
        const url = `${window.location.protocol}//${window.location.host}/api/upload/example/download/csv?keys=forcepackId,bonusForce,startsAt,endsAt`;
        window.open(url, 'blank');
    }
}
