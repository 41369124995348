import {
    Component,
    ContentChild, EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    TemplateRef, ViewChild
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { groupItem } from 'types/types';
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
    selector: 'app-new-tag',
    templateUrl: './new-tag.component.html',
    styleUrls: ['./new-tag.component.scss']
})
export class NewTagComponent implements OnInit {
    @Input()
    searchItems = [];
    @Input()
    items = [];
    @Output()
    onAddItem: EventEmitter<any> = new EventEmitter<any>();

    @Output() itemsChange: EventEmitter<any> = new EventEmitter<any>();
    @ContentChild('content', { static: false }) contentElement: TemplateRef<any>

    @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

    templateRef: TemplateRef<any>;

    addItem = (event) => {
        this.ngSelect.handleClearClick();
        this.ngSelect.close();
        this.onAddItem.emit(event);
    }

    constructor(public readonly injector: Injector) {
    }

    ngOnInit() {
    }

    selectItem(item) {
        this.ngSelect.handleClearClick();
        this.itemsChange.emit([item, ...this.items])
    }

    drop(event: CdkDragDrop<groupItem[]>) {
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    }

    delete(index) {
        this.items.splice(index, 1);
    }

    enter(search: NgSelectComponent, event) {
        if (event.key == 'Enter') {
            this.ngSelect.handleClearClick();
        }
    }

}
