import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'form-select',
    templateUrl: 'select.component.html',
    styleUrls: []
})
export class SelectComponent {

    @Input() label = '';
    @Input() placeholder = '';
    @Input() items = [];
    @Input() bindValue = "";
    @Input() bindLabel = "";
    @Input() required = false;
    @Input() disabled = false;

    @Output()
    valueChange = new EventEmitter<string>();
    @Output()
    change = new EventEmitter();

    _value;

    constructor() {
    }

    @Input()
    get value(): string | number {
        return this._value;
    }

    set value(val: string | number) {
        this._value = val;
        this.valueChange.emit(this._value);
    }

}
