<div class="modal-header">
  <h5 class="modal-title">
    <b>추천 아이템 수정 생성</b>
  </h5>
</div>
<div class="modal-body">
  <form-input label="제목" placeholder="추천 제목 문구" [(value)]="recommend.title"></form-input>
  <form-input label="설명" placeholder="추천 아이템 설명" [(value)]="recommend.description"></form-input>
  <form-input label="링크" placeholder="추천 링크" [(value)]="recommend.link"></form-input>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary mr-2" (click)="closeModal()">
    취소
  </button>
  <button type="button" class="btn btn-primary" (click)="submit()">
    확인
  </button>
</div>
