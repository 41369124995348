import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class FortuneService extends BaseAPIService {

    private ROUTES = {
        themeList: '/event/list',
        tagList: '/items/tag/list',
        keywordList: '/items/keyword/list',
        list: '/items/list/simple',
        listV2: 'items/list/v2',
        extraList: '/extra/list',
        extra: '/extra/id',
        allTagList: '/items/tags/all'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    themeList(): Promise<any> {
        return this.GET(this.ROUTES.themeList).toPromise();
    }

    tagList(): Promise<any> {
        return this.GET(this.ROUTES.tagList).toPromise();
    }

    keywordList(): Promise<any> {
        return this.GET(this.ROUTES.keywordList).toPromise();
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    extraList(): Promise<any> {
        return this.GET(this.ROUTES.extraList).toPromise();
    }

    getExtra(id): Promise<any> {
        return this.GET(this.ROUTES.extra, null, { params: { extra_id: id } }).toPromise();
    }

    createExtra(body): Promise<any> {
        return this.POST(this.ROUTES.extra, null, body).toPromise();
    }

    updateExtra(body): Promise<any> {
        return this.PATCH(this.ROUTES.extra, null, body).toPromise();
    }

    allTagList(): Promise<any> {
        return this.GET(this.ROUTES.allTagList).toPromise();
    }
}
