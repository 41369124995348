<div class="col-2 p-0" center-vh>
    <span class="item-status" [ngClass]="(data.status === 'D2' || data.status === 'S3') ? 'active' : ''"><i
            class="fa fa-circle"></i>{{(data.status === 'D2' || data.status === 'S3') ? '활성' : '비활성'}}</span>
</div>
<div class="m-stack m-stack--ver col-10">
  <div class="row h-100">
    <div class="item-type col-12">
      <span class="item-title mr-2">{{data.type}} {{data.id}}</span>
      <span *ngIf="data.PremiumItemPromotions && data.PremiumItemPromotions.length > 0" class="promo-title text-info">프로모션 : {{data.PremiumItemPromotions[0].title}}</span>
    </div>
    <div class="item-title-div col-12">
      <span class="item-title">{{data.title}}</span>
    </div>
  </div>
  <div class="item-delete">
    <a class="item-delete-btn" (click)="delete(index)">
      제거
    </a>
  </div>
</div>
