import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class StatsticService extends BaseAPIService {

    private ROUTES = {
        celebRank: '/content/celebs/rank',
        celebDaily: '/content/celebs/daily',
        subscriber: '/statistics/subscriber',
        widget: '/statistics/subscriber/widget',
        total: '/statistics/subscriber/total',
        premium: '/statistics/premium-fortune',
        smallPayment: '/statistics/small-payment',
        sync: '/statistics/sync/:type',
        package: '/statistics/package-fortune',
    };

    constructor(protected http: HttpClient) {
        super(http);
    }


    celebRank(dateOne, dateTwo, limit): Promise<any> {
        return this.GET(this.ROUTES.celebRank, null, { params: { dateOne, dateTwo, limit } }).toPromise();
    }

    celebDaily(dateOne, dateTwo): Promise<any> {
        return this.GET(this.ROUTES.celebDaily, null, { params: { dateOne, dateTwo } }).toPromise();
    }

    subscriber(startDate, endDate, option): Promise<any> {
        return this.GET(this.ROUTES.subscriber, null, { params: { startDate, endDate, ...option } }).toPromise();
    }

    widget(startDate, endDate): Promise<any> {
        return this.GET(this.ROUTES.widget, null, { params: { startDate, endDate } }).toPromise();
    }

    total(): Promise<any> {
        return this.GET(this.ROUTES.total).toPromise();
    }

    premium(dateOne, dateTwo): Promise<any> {
        return this.GET(this.ROUTES.premium, null, { params: { dateOne, dateTwo } }).toPromise();
    }

    smallPayment(dateOne, dateTwo): Promise<any> {
        return this.GET(this.ROUTES.smallPayment, null, { params: { dateOne, dateTwo } }).toPromise();
    }

    sync(type, options): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.sync, { type }), options).toPromise();
    }
    package(dateOne, dateTwo): Promise<any> {
        return this.GET(this.ROUTES.package, null, { params: { dateOne, dateTwo } }).toPromise();
    }
}
