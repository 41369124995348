import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PremiumItemRecommendationsAttribute } from "../../../../types/types";

@Component({
    selector: "app-recommend",
    templateUrl: "recommend.component.html",
    styleUrls: ["recommend.component.scss"]
})
export class RecommendComponent {

    _recommend: PremiumItemRecommendationsAttribute;

    constructor(private activeModal: NgbActiveModal) {
    }

    @Input()
    get recommend(): PremiumItemRecommendationsAttribute {
        return this._recommend;
    }

    set recommend(val: PremiumItemRecommendationsAttribute) {
        if (val) {
            this._recommend = val;
        } else {
            this._recommend = { title: '', description: '', link: '' };
        }
    }

    closeModal() {
        this.activeModal.close();
    }

    submit() {
        this.activeModal.close(this._recommend);
    }

}
