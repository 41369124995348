import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class PopularSearchService extends BaseAPIService {

    private ROUTES = {
        list: 'items/fortune_item/:type/popular/words',
        save: 'items/fortune_item/:type/popular/word'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(type): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.list, { type: type })).toPromise();
    }

    save(type, body): Promise<any> {
        return this.http.post(this.inject(this.ROUTES.save, { type: type }), body).toPromise();
    }

}
