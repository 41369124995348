import { Action, ActionCreator } from "redux";
import { FortuneItemsAttribute } from "../../../types/types";

export const Edit_FortuneItem = '[FortuneItem] Update FortuneItemLiveStatus';
export const Dirty_FortuneItemEdit = '[FortuneItem] Set Dirty Edit';
export const Dirty_FortuneItemCategories = '[FortuneItem] Set Dirty Categories';
// export const SET_NOTICE_RESERVATION = '[reservation] Set NoticeReservation';


//기존 액션에 추가적으로 가져가야할 데이터를 명시해준다.
export interface EditFortuneItemAction extends Action {
    fortuneItem: { FortuneItem: FortuneItemsAttribute },
}

export interface SetDirtyFortuneItemEditAction extends Action {
    isDirty: boolean
}
export interface SetDirtyFortuneItemCategoiesAction extends Action {
    isDirty: boolean
}


// 이렇게 되면 해당 액션을 내뱉는 함수가 만들어진다.

export const editFortuneItemAction: ActionCreator<EditFortuneItemAction> = (data: { FortuneItem: FortuneItemsAttribute }) => ({
    type: Edit_FortuneItem,
    fortuneItem: data,
});

export const setDirtyFortuneItemEditAction: ActionCreator<SetDirtyFortuneItemEditAction> = (data: boolean) => {
    return {
        type: Dirty_FortuneItemEdit,
        isDirty: data,
    }
}
export const setDirtyFortuneItemCategoriesAction: ActionCreator<SetDirtyFortuneItemCategoiesAction> = (data: boolean) => {
    return {
        type: Dirty_FortuneItemCategories,
        isDirty: data,
    }
}



