import { Action, ActionCreator } from "redux";
import { FortuneItemsAttribute } from "../../../types/types";

export const Dirty_check = '[Dirty] check dirty';
// export const SET_NOTICE_RESERVATION = '[reservation] Set NoticeReservation';


//기존 액션에 추가적으로 가져가야할 데이터를 명시해준다.
export interface SetDirtyAction extends Action {
    isDirty: boolean
}


// 이렇게 되면 해당 액션을 내뱉는 함수가 만들어진다.

export const setDirtyAction: ActionCreator<SetDirtyAction> = (data: boolean) => {
    return {
        type: Dirty_check,
        isDirty: data,
    }
}



