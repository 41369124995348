<div class="modal" [class.active]="active">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="csvPromotionsModalLabel">
                    <b>선물 생성</b>
                </h5>
                <button type="button" class="close" (click)="close()">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <h6>CSV 양식 샘플</h6>
                    <table class="table table-striped m-table">
                        <thead class="m-datatable__head thead-light">
                        <tr>
                            <th>type</th>
                            <th>discount</th>
                            <th>title</th>
                            <th>description</th>
                            <th>caution</th>
                            <th>ids</th>
                            <th>url</th>
                            <th>thumbnailImage</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span class="m-badge m-badge--secondary m-badge--wide">할인권</span></td>
                            <td>30</td>
                            <td>할인권 이름</td>
                            <td>할인권 설명</td>
                            <td>- 본 할인권은 ... - 타 프로모션 ... - 본 유의사항 ...</td>
                            <td>1, 4, 10, 21, 52, 66</td>
                            <td>/premium/98</td>
                            <td>https://static...jpg</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <app-ctsv-uploader
                            class="app-content-body-max-width"
                            apiUrl="/api/upload/csv"
                            uploaderId="ctsv-uploader"
                            (successCsv)="successCsv($event)">
                    </app-ctsv-uploader>
                </div>
                <div class="my-2 d-flex justify-content-between">
                    <button class="btn btn-primary" [disabled]="!uploadList.length || !f.form.valid || isUploading" form="uploadForm">해당 리스트 업로드</button>
                    <a class="btn btn-outline-info m-btn m-btn--icon  m-btn--icon-only m-btn--pill m-btn--air"
                       href="javascript:;" (click)="addItem()">
                        <i class="la la-plus"></i>
                    </a>
                </div>
                <form id="uploadForm" #f="ngForm" (ngSubmit)="upload()">
                    <table class="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline">
                        <thead style="background: #ebedf2" class="m-datatable__head">
                        <tr>
                            <th style="width: 120px">
                                타입
                            </th>
                            <th style="width: 65px">
                                할인율
                            </th>
                            <th>
                                제목
                            </th>
                            <th>
                                설명
                            </th>
                            <th>
                                유의사항
                            </th>
                            <th>
                                프리미엄ids
                            </th>
                            <th>
                                사용 url
                            </th>
                            <th>
                                썸네일 이미지
                            </th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of uploadList; let i = index" class="banner-area-list-row">

                            <td class="form-group has-danger">
                                <select class="form-control" [(ngModel)]="item.type" name="type{{i}}" required>
                                    <option value="discount">할인권</option>
                                </select>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['type'+i]?.touched) && f.controls['type'+i]?.invalid">값을 입력해주세요</div>
                            </td>

                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.discount" name="discount{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['discount'+i]?.touched) && f.controls['discount'+i]?.invalid">값을 입력해주세요</div>
                            </td>

                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.title" name="title{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['title'+i]?.touched) && f.controls['title'+i]?.invalid">값을 입력해주세요</div>
                            </td>

                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.description" name="description{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['description'+i]?.touched) && f.controls['description'+i]?.invalid">값을 입력해주세요</div>
                            </td>

                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.caution" name="caution{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['caution'+i]?.touched) && f.controls['caution'+i]?.invalid">값을 입력해주세요</div>
                            </td>

                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.premiumIds" name="premiumIds{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['premiumIds'+i]?.touched) && f.controls['premiumIds'+i]?.invalid">값을 입력해주세요</div>
                            </td>

                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.url" name="url{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['url'+i]?.touched) && f.controls['url'+i]?.invalid">값을 입력해주세요</div>
                            </td>

                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.thumbnailImage" name="thumbnailImage{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['thumbnailImage'+i]?.touched) && f.controls['thumbnailImage'+i]?.invalid">값을 입력해주세요</div>
                            </td>

                            <td class="form-group has-danger">
                                <a href="javascript:;" class="btn btn-danger m-btn m-btn--icon m-btn--icon-only mx-2"
                                   (click)="deleteItem(i)">
                                    <i class="fa fa-trash cc_pointer"></i>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    </div>
</div>
