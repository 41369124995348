import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class HelpService extends BaseAPIService {

    private ROUTES = {
        listByUser: '/help/list/user/:id',
        logs: '/help/logs',
        faq: '/cs/faq/:id',
        faqEdit: '/cs/faq/id',
        order: '/cs/faq/list/order',
        list: '/cs/faqs'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    listByUser(context: any): Promise<any> {
        return this.GET(this.ROUTES.listByUser, context).toPromise();
    }

    logs(): Promise<any> {
        return this.GET(this.ROUTES.logs).toPromise();
    }

    faq(context: any): Promise<any> {
        return this.GET(this.ROUTES.faq, context).toPromise();
    }

    create(body: any): Promise<any> {
        return this.http.post(this.ROUTES.faqEdit, body).toPromise();
    }

    update(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.faqEdit, body).toPromise();
    }

    order(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.order, body).toPromise();
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }
}
