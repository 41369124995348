<div>
    <div class="m-form__group form-group">
        <label class="col-form-label">할인권 추가</label>
        <div>
            <ng-select bindLabel="bindLabel"
                       placeholder="ID 또는 제목으로 검색하세요"
                       [items]=searchGiftViewList
                       [virtualScroll]="true"
                       (change)="addGift($event)">
                <ng-template ng-label-tmp>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="m-form__group form-group" *ngIf="multiSelect === true">
        <label class="col-form-label">다중 선택 <span class="small ml-1" style="color: #575962; opacity: 0.55">* id로 다중 선택하여 등록하고 엔터를 눌러주세요.</span></label>
        <input placeholder="ex) 6,14,15,26" (input)="getMultiSelectData()" (change)="addMultiSelectItem()" type="text" class="form-control" [(ngModel)]="inputValue">
        <div class="mt-3" *ngFor="let multiItem of searchMultiGiftViewList">
            <span style="color: #5866dd" [ngClass]="multiItem.gift.type" class="gift-status regular mt-3">[{{getGiftTypeName(multiItem.gift.type)}}] {{multiItem.gift_id}} - {{multiItem.title}}</span>
        </div>
    </div>
    <div>등록된 할인권</div>
    <div *ngIf="giftViewList.length == 0; else hasGifts">
        <label class="col-form-label">등록된 할인권이 없습니다.</label>
    </div>
    <ng-template #hasGifts>
        <div dragula="themeItem" class="gift-wrapper" [(dragulaModel)]="giftViewList" (dragulaModelChange)="drop()">
            <div class="m-stack__demo-item gift-row row"
                 [class.added-gift]="gift && gift.isAdd"
                 *ngFor="let gift of giftViewList; let i = index">
                <div class="col-2 d-flex justify-content-center align-items-center">
                    <span class="gift-status" [ngClass]="gift.gift.type">
                        <i class="fa fa-circle"></i>
                    </span>
                </div>
                <div [id]="i" class="m-stack m-stack&#45;&#45;ver col-10">
                    <div class="row h-100">
                        <div class="gift-type col-12">
                            <span class="gift-title">{{ getGiftTypeName(gift.gift.type) }} {{ gift.gift_id }}</span>
                        </div>
                        <div class="gift-title-div col-12">
                            <span class="item-title">{{ gift.title }}</span>
                        </div>
                    </div>
                    <div class="gift-delete">
                        <a class="gift-delete-btn" (click)="deleteGift(i)">제거</a>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
