<div class="">
    <div class="m-form__group form-group">
        <label class="col-form-label">
            아이템
        </label>
        <div class="">
            <ng-select [items]="searchThemeViewList"
                       bindLabel="bindLabel"
                       placeholder="ID 또는 제목으로 검색하세요"
                       [virtualScroll]="true"
                       (change)="addItem($event)">
                <ng-template ng-label-tmp>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="m-form__group form-group" *ngIf="multiSelect === true">
        <label class="col-form-label">다중 선택 <span class="small ml-1" style="color: #575962; opacity: 0.55">* id로 다중 선택하여 등록하고 엔터를 눌러주세요.</span></label>
        <input placeholder="ex) 6,14,15,26" (input)="getMultiSelectData()" (change)="addMultiSelectItem()" type="text" class="form-control" [(ngModel)]="inputValue">
        <div class="mt-3" *ngFor="let multiItem of searchMultiThemeViewList">
            <span [ngStyle]="{
               color : multiItem.status === '활성' ? '#5866dd' : 'gray',
               opacity: multiItem.status !== '활성' && 0.55
            }" class="regular mt-3">[{{multiItem.status}}] {{multiItem.rel_id}} - {{multiItem.title}}</span>
        </div>
    </div>
    <div class="m-form__group form-group">
        <div class="not-item">
            등록된 아이템
        </div>
        <div *ngIf="themeViewList.length == 0; else hasItems">
            등록된 아이템이 없습니다.
        </div>
        <ng-template #hasItems>
            <div dragula="themeItem" [(dragulaModel)]="themeViewList"
                 (dragulaModelChange)="drop()"
                 class="item-wrapper">
                <div class="m-stack__demo-item item-row row"
                     [class.added-theme-item]="item && item.isAdd"
                     *ngFor="let item of themeViewList; let i = index">
                    <div class="col-2 d-flex justify-content-center align-items-center">
                                                        <span class="item-status"
                                                              [ngClass]="item.status == '활성' ? 'active' : ''"><i
                                                                class="fa fa-circle"></i> {{item.status}}</span>
                    </div>
                    <div [id]="i" class="m-stack m-stack&#45;&#45;ver col-10">
                        <div class="row h-100">
                            <div class="item-type col-12">
                                <span class="item-title">{{item.typeName}} {{item.rel_id == -1 ? '' : item.rel_id}}</span>
                                <span class="text-info ml-2" *ngIf="item.promotion">{{item.promotion}}</span>
                            </div>
                            <div class="item-title-div col-12">
                                <span class="item-title">{{item.title}}</span>
                            </div>
                        </div>
                        <div class="item-delete">
                            <a class="item-delete-btn" (click)="deleteItem(i)">
                                제거
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
