import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare let swal: any;

@Component({
    selector: "force-recharge",
    templateUrl: "force-recharge.component.html",
    styleUrls: ['./force-recharge.component.scss']
})
export class ForceRechargeComponent implements OnInit {

    @Input() userInfo;
    @Input() purchasableList;

    @Output() refreshData = new EventEmitter();

    public orderId: String = '';
    public isFirstTimeBonus: boolean = false;

    // 2018.10.31 기준으로 디바이스별로 포스를 지급하지 않고 'all'로 통일하도록 변경
    public selectedDevice: any = { name: 'all', platform: 'all' };
    public selectedPurchasable: any;

    constructor(
        private http: HttpClient
    ) {
    }

    ngOnInit() {
    }

    selectForcePack($event) {
        let _founded = this.purchasableList.find(d => d.id == $event.target.value)
        if (_founded) {
            this.selectedPurchasable = _founded
        } else {
            this.selectedPurchasable = null
        }
    }

    giveForce() {
        if (!this.selectedDevice || !this.selectedPurchasable) {
            return
        }

        this.http
            .patch('user/cs/force', {
                userId: this.userInfo.id,
                purchasable: this.selectedPurchasable,
                device: this.selectedDevice,
                isFirstTimeBonus: this.isFirstTimeBonus,
                orderId: this.orderId
            })
            .toPromise()
            .then(r => {
                swal('Success!', '성공적으로 지급 되었습니다.', 'success')
                this.refreshData.emit();
            })
            .catch(e => {
                console.log(e);
                if (e.status == 400) {
                    swal('Error!', e.error.message, 'warning');
                    return;
                }
                swal('Error!', '지급에 실패하였습니다. 관리자에게 문의하세요.', 'error')
            })
    }

}
