<div class="modal-header">
  <h4 class="modal-title">섹션 예약</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-footer">
  <h6>예약 시간</h6>
  <div class="d-flex mt-2 w-100 align-items-center">
    <div class="reservation-time">
      <div class="m-form__group row ">
        <div class="input-group date col-lg-12">
          <input type="text" class="form-control m-input" id="section-reserve" />
          <div class="input-group-append">
            <span class="input-group-text">
                <i class="la la-calendar glyphicon-th"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="status d-flex align-items-center ml-3">
      <div class="input-group">
        <span class="m-switch m-switch--outline m-switch--primary">
          <label><input type="checkbox" [(ngModel)]="status"/><span></span></label>
        </span>
      </div>
      <label class="col-form-label">활성</label>
    </div>
    <div class="m-form__group ml-3">
      <button (click)="addReserve()" class="btn btn-primary m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill">
        예약하기
      </button>
    </div>
  </div>
  <div class="d-flex mt-3 w-100 flex-column">
    <h6>예약 로그</h6>
    <div class="m-scrollable">
      <div class="row">
        <div class="col-12">
          <div class="m-section__content">
            <div class="m-demo">
              <div class="m-demo__preview bg-light">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <td class="w-50 m--font-success font-weight-bold">시간</td>
                      <td class="w-25 font-weight-bold">명령</td>
                      <td colspan="2" class="w-25 font-weight-bold">상태</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr [ngClass]="{'not-booked-row' : reservation.status != 'booked' }"
                        *ngFor="let reservation of reserveList">
                      <td class="m--font-success">{{reservation.reservation_time | date:'yyyy-MM-dd HH:mm'}}</td>
                      <td>{{reservation.task === 'on' ? '활성화' : '비활성화'}}</td>
                      <td>{{reservation.status === 'booked' ? '예약됨' : (reservation.status === 'fulfill' ? '실행됨' : '취소됨')}}</td>
                      <td>
                        <span *ngIf="reservation.status === 'booked'" (click)="cancelReserve($event, reservation)" class="cancel-btn cursor-pointer">
                          취소하기
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
