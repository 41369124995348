import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: "item",
    templateUrl: "item.component.html",
    styleUrls: ["item.component.scss"]
})
export class ItemComponent implements OnInit {

    @Input()
    public data;

    @Input()
    public index;

    @Output()
    public deleteEmit: EventEmitter<number> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    delete(i) {
        this.deleteEmit.emit(i);
    }

}
