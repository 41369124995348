import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class NotificationService extends BaseAPIService {

    private ROUTES = {
        get: 'user/:userId/notifications'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    getList(context: any): Promise<any> {
        return this.GET(this.ROUTES.get, context).toPromise();
    }
}
