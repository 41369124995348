import { AfterViewInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppSection, ReservationsAttribute } from 'types/types';
import * as _ from 'lodash';
import * as moment from 'moment/moment';
import { ReservationService } from 'app/_services/reservation/reservation.service';

declare let $: any;
declare let toastr: any;

@Component({
    selector: 'app-reservation-dialog',
    templateUrl: './reservation-dialog.component.html',
    styleUrls: ['./reservation-dialog.component.scss']
})
export class ReservationDialogComponent implements AfterViewInit {

    _section: AppSection;
    reserveTime: string | null = null;
    reserveList: ReservationsAttribute[] = [];
    status = false;

    constructor(
        public activeModal: NgbActiveModal,
        private reservationService: ReservationService,
    ) {
    }

    ngAfterViewInit() {
        $('#section-reserve').datetimepicker({
            todayHighlight: true,
            autoclose: true,
            pickerPosition: 'bottom-left',
            todayBtn: true,
            stepping: 1,
            format: 'yyyy-mm-dd hh:ii'
        });

        $(document).on('change', '#section-reserve', (e) => {
            this.reserveTime = _.isEmpty(e.target.value) ? null : moment(e.target.value).utc().format();
        });
        this.getReservation();
        // this.status = this.section.status === 'S3';
    }

    @Input()
    set section(section) {
        this._section = section;
        this.status = section.status === 'S3';
    }

    get section() {
        return this._section;
    }

    async getReservation() {
        this.reserveList = await this.reservationService.getSection('section_items', { id: this.section.id });
        this.reserveList.sort((a, b) => {
            const statusOrder = {
                'booked': -1,
                'fulfill': 1,
            };

            return (statusOrder[a.status] || Infinity) - (statusOrder[b.status] || Infinity);
        })
    }

    addReserve() {
        if (moment(this.reserveTime).format('x') < moment().format('x') || this.reserveTime == null) {
            toastr.warning('예약시간이 현재시간보다 전에 있습니다. 확인해주세요.');
            return false;
        }
        this.reservationService.setSection({
            type: 'section_items',
            task: this.status ? 'on' : 'off',
            reservation_time: +moment(this.reserveTime).format('x'),
            ref_id: this.section.id
        }).then(() => {
            this.getReservation();
            toastr.success('예약 되었습니다.');
        }).catch(e => {
            console.log(e);
            toastr.success('에러 발생!! 예약실패!!');
        })
    }

    cancelReserve(event, reservation) {
        event.stopPropagation();

        this.reservationService.cancel(reservation)
            .then(() => {
                this.getReservation();
                toastr.success('예약 취소 되었습니다. ');
            })
            .catch(e => {
                console.log(e);
                toastr.success('에러 발생!!!!');
            });
    }
}
