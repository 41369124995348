import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'form-color',
    templateUrl: 'color.component.html'
})
export class ColorComponent {

    @Input() label = '';
    @Input() placeholder = '';
    @Input() required = false;

    @Output()
    valueChange = new EventEmitter<string>();

    _value;

    constructor() {
    }

    @Input()
    get value(): string {
        if (this._value) {
            return this._value;
        }
        return "";
    }

    set value(val: string) {
        this._value = val;
        this.valueChange.emit(this._value);
    }

}
