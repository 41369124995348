import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges,
    AfterViewChecked
} from '@angular/core';
import { AuthService } from 'ng2-ui-auth';
import { UtilService } from "../../../util/util.service";
import { Router } from "@angular/router";

declare let Dropzone: any;
declare let swal: any;
declare let toastr: any;

@Component({
    selector: 'app-image-uploader',
    templateUrl: 'image-uploader.component.html',
    styleUrls: ['image-uploader.component.scss']
})

export class ImageUploaderComponent implements OnInit, OnChanges, AfterViewChecked {
    public dropzone;
    @Input('innerImageSrc') innerImageSrc: string = '';
    @Input('small') small: boolean = false;
    @Input('maxWidth') maxWidth: boolean = false;
    @Input('index') index: number = 0;
    @Input('uploaderId') uploaderId: string;
    @Input('dragMode') dragMode: boolean;
    @Input('dropId') dropId;
    @Input('disabled') disabled: boolean = false;
    @Output() public successImageUrl = new EventEmitter();
    @Output() public innerImageSrcChange = new EventEmitter();
    public srcModal = false;
    public inputSrc = '';
    public imageFileSize = '';

    constructor(private auth: AuthService, private router: Router) {
    }

    ngAfterViewChecked(): void {
        this.initUploader()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.router.url.includes('/live/image-pack/edit')) {
            if (changes?.innerImageSrc?.currentValue) {
                fetch(this.innerImageSrc).then((response) => {
                    let byte = Number(response.headers.get('Content-Length'))
                    this.imageFileSize = UtilService.convertByte(byte, 1)
                }).catch(error => console.log(error))
            }
        }
    }

    ngOnInit() {

    }

    initUploader() {
        try {
            if ($(`#${this.uploaderId}`)) {
                this.dropzone = new Dropzone(`#${this.uploaderId}`, {
                    url: '/api/upload/multiV2',
                    paramName: "file", // The name that will be used to transfer the file
                    maxFiles: 1,
                    maxFilesize: 5, // MB
                    addRemoveLinks: true,
                    dictRemoveFile: '파일 삭제',
                    acceptedFiles: "image/*",
                    headers: { 'Authorization': `Bearer ${this.auth.getToken()}` },
                    accept: function(file, done) {
                        done();
                    },
                    sending: function(file, xhr, formData) {
                        // Will send the filesize along with the file as POST data.
                        // 추가적인 데이터를 여기에 입력하십시오! req. body 객체에서 확인할수 있음!
                        formData.append("extra", true);
                    },
                    success: (result) => {
                        setTimeout(() => {
                            this.dropzone.files.forEach(file => {
                                this.dropzone.removeFile(file)
                            })
                        }, 1000);
                        const fileSize = result.size
                        if (fileSize > 409600) {
                            toastr.warning('업로드는 가능하지만, 파일 사이즈가 400KB를 초과합니다.')
                        }
                        this.successImageUrl.emit(JSON.parse(result.xhr.response).files.fileURL);
                        this.innerImageSrcChange.emit(JSON.parse(result.xhr.response).files.fileURL);
                    },
                    error: (err) => {
                        console.log(err)
                        setTimeout(() => {
                            this.dropzone.files.forEach(file => {
                                this.dropzone.removeFile(file)
                            })
                        }, 1000);
                        this.successImageUrl.emit('assets/custom/sahwa-upload-fail.png')
                    },
                })
            }
        } catch (e: any) {
        }
    }


    deleteImage() {
        swal({
            title: '정말 삭제하시겠습니까?',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: '취소',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.successImageUrl.emit('');
                this.innerImageSrcChange.emit('');
                this.imageFileSize = '';
            }
        });

    }

    ngAfterViewInit() {
        this.initUploader();
    }

    ngOnDestroy() {
        if (this.dropzone) {
            this.dropzone.destroy();
        }
    }

    addSrc() {
        setTimeout(() => {
            this.dropzone.files.forEach(file => {
                this.dropzone.removeFile(file)
            })
        }, 1000);
        this.successImageUrl.emit(this.inputSrc);
        this.innerImageSrcChange.emit(this.inputSrc);
        fetch(this.inputSrc).then((response) => {
            let byte = Number(response.headers.get('Content-Length'))
            if (byte > 409600) {
                toastr.warning('업로드는 가능하지만, 파일 사이즈가 400KB를 초과합니다.')
            }
        }).catch(error => console.log(error))
        this.initUploader();
    }

    openSvgModal(type: boolean) {
        event.stopPropagation();
        this.srcModal = type;
        if (type) {
            document.querySelector('body').classList.add('overflow-hidden');
        } else {
            document.querySelector('body').classList.remove('overflow-hidden');
        }
    }
}
