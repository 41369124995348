<app-header-nav id="mobile-header" [style]="style"></app-header-nav>
<div [style]="{background : style.aside_nav_color}" id="m_aside_left" class="m-grid__item m-aside-left">
    <div [style]="{color : style.text_color}" id="m_ver_menu" class="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark"
         m-menu-vertical="1" m-menu-scrollable="1" m-menu-dropdown-timeout="400">
        <ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
            <li class="m-menu__item" routerLinkActive="m-menu__item--active">
                <a routerLink="/index" class="m-menu__link">
                    <i class="m-menu__link-icon flaticon-line-graph" [style]="{color: style.icon_color}"></i>
                    <span [style]="{color : style.text_color}">Dashboard</span>
                </a>
            </li>
            <ng-container *ngFor="let menu of menus; index as i">
                <li *ngIf="menu.subMenus"  class="m-menu__item m-menu__item--submenu"
                    routerLinkActive="m-menu__item--open">
                    <a class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon" [ngClass]="menu.icon || 'flaticon-apps'" [style]="{color: style.icon_color}"></i>
                        <span [style]="{color : style.text_color}">
                            {{menu.name}}
                        </span>
                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <div class="m-menu__submenu">
                        <span class="m-menu__arrow"></span>
                        <ul class="m-menu__subnav">
                            <ng-container *ngFor="let subMenu of menu.subMenus">
                                <li class="m-menu__item" [ngClass]="{'m-menu__item--submenu': subMenu.subMenus}"
                                    [routerLinkActive]="subMenu.subMenus? 'm-menu__item--open' : 'm-menu__item--active'">
                                    <a *ngIf="subMenu.link" [routerLink]="[subMenu.link]" class="m-menu__link">
                                        <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                        <span [style]="{color : style.text_color}">{{subMenu.name}}</span>
                                    </a>
                                    <a *ngIf="subMenu.href" [attr.href]="subMenu.href" target="_blank" class="m-menu__link">
                                        <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                        <span [style]="{color : style.text_color}">{{subMenu.name}}</span>
                                    </a>
                                    <a *ngIf="subMenu.subMenus" class="m-menu__link m-menu__toggle">
                                        <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                        <span [style]="{color : style.text_color}">{{subMenu.name}}</span>
                                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                                    </a>
                                    <div *ngIf="subMenu.subMenus" class="m-menu__submenu">
                                        <span class="m-menu__arrow"></span>
                                        <ul class="m-menu__subnav">
                                            <li *ngFor="let subMenu2 of subMenu.subMenus" class="m-menu__item" routerLinkActive="m-menu__item--active">
                                                <a [routerLink]="subMenu2.link" class="m-menu__link">
                                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                    <span [style]="{color : style.text_color}">{{subMenu2.name}}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </li>
                <li *ngIf="!menu.subMenus" class="m-menu__item" routerLinkActive="m-menu__item--active">
                    <a [routerLink]="menu.link" class="m-menu__link">
                        <i class="m-menu__link-icon" [ngClass]="menu.icon || 'flaticon-avatar'" [style]="{color: style.icon_color}"></i>
                                <span [style]="{color : style.text_color}">
                                    {{menu.name}}
                                </span>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
    <div id="additional-btn-group" [style]="{color : style.text_color }">
        <div *ngIf="production == true"
             class="lang-group m-nav__item m-topbar__languages m-dropdown m-dropdown--small m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width"
             m-dropdown-toggle="click" aria-expanded="true">
            <div class="m-dropdown__wrapper">
                <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
                      style="left: auto; right: 5px;"></span>
                <div class="m-dropdown__inner">
                    <div class="m-dropdown__body">
                        <div class="m-dropdown__content">
                            <ul class="m-nav m-nav--skin-light">
                                <li *ngFor="let lang of langs" class="m-nav__item"
                                    [ngClass]="{'m-nav__item--active' : lang.code == thisLang.code}">
                                    <a [attr.href]="lang.url" class="m-nav__link"
                                       [ngClass]="{'m-nav__link--active' : lang.code == thisLang.code}">
                                        <span class="m-nav__link-icon">{{lang.flag}}</span>
                                        <span class="m-nav__link-title m-topbar__language-text m-nav__link-text">{{lang.name}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div><!-- end dropdown wrapper-->
            <span class="lang-btn m-nav__link m-dropdown__toggle">
												<span class="m-nav__link-text">
													<span class="m-nav__link-icon">{{thisLang.flag}}</span>&nbsp;&nbsp;{{thisLang.code}}
												</span>
            </span>
        </div>

        <div class="logout-btn" (click)="logout()">
            <i class="la la-sign-out"></i>
            <span>로그아웃</span>
        </div>

    </div>
</div>
