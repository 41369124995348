<div class="modal-header">
  <h5 class="modal-title">
    아이템 편집
  </h5>
  <button type="button" class="close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row mb-4">
    <div class="col-6">
      <div class="form-group row">
        <label class="col-form-label col-12">기존 등록된 아이템</label>
        <div class="col-12">
          <div *ngIf="reservationItems.length == 0; else hasItems">등록된 아이템이 없습니다.</div>
          <ng-template #hasItems>
            <div cdkDropList (cdkDropListDropped)="drop($event)" class="group-item">
              <div cdkDrag *ngFor="let item of reservationItems; let i = index">
                <span>{{item.id}} : {{item.title}}</span>
                <div class="item-cancel-btn" (click)="deleteItem(i)">취소</div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group row">
        <label class="col-form-label col-12">검색으로 등록</label>
        <div class="col-12">
          <ng-select [items]="searchItems"
                     bindLabel="bindLabel"
                     placeholder="ID 또는 이름으로 검색하세요"
                     [virtualScroll]="true"
                     (change)="selectChange($event)">
            <ng-template ng-label-tmp>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row" center-v>
    <div class="col-6">
      <div class="form-group row">

        <div class="input-group date col-12">
          <input class="form-control" [(ngModel)]="reservationTime" id="reservation_datetimepicker"/>
          <div class="input-group-append">
            <span class="input-group-text"><i class="la la-calendar glyphicon-th"></i></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <button type="button" class="btn btn-primary btn-outline-info" (click)="reservation()">아이템 예약</button>
    </div>
  </div>
  <div class="row">
    <label class="col-form-label col-12">예약 로그</label>
    <div class="col-md-6 col-sm-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th class="m--font-success">시간</th>
            <th colspan="2">상태</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let log of reservationLogs" (click)="showDetail(log)">
              <td class="m-list-badge__label w-50">{{log.reservation_time | date: 'yyyy-MM-dd HH:mm' :'+0900'}}</td>
              <td class="w-25">{{log.status == 'booked' ? '예약됨' : log.status == 'fulfill' ? '실행됨' : '취소됨'}}</td>
              <td class="w-25"><a class="m-list-badge__item cancel-btn" *ngIf="log.status=='booked'" (click)="cancelReservation($event, log.id)">취소하기</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-6 col-sm-12">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th class="w-10 m--font-success font-weight-bold">ID</th>
            <th class="w-55 m--font-success font-weight-bold">아이템명</th>
            <th class="w-25 font-weight-bold">상태</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let logItem of reservationLogItems">
            <td class="w-10 m--font-success">{{logItem.id}}</td>
            <td class="m--font-success text-ellipsis">{{logItem.title}}</td>
            <td class="w-25">{{logItem.status == 'D2' || logItem.status == 'S3' ? '활성화' : '비활성화'}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
