import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

declare let $: any;

@Component({
    selector: 'app-image-selector',
    templateUrl: 'image-selector.component.html',
    styleUrls: ['image-selector.component.scss']
})
export class ImageSelectorComponent implements OnInit, OnDestroy {

    @Input('imageDatas') imageDatas: any[];
    @Input('subject') subject: string;
    @Input('nodisplay') nodisplay: boolean
    @Output('applyEvent') applyEvent = new EventEmitter();
    @Output('noDataEvent') noDataEvent = new EventEmitter();
    @Input('disabled') disabled;
    @Input('zoom') zoom: boolean;
    @Input('selectedData') selectedData: ImageDatas;

    public isExistSelectedData: boolean = false;

    ngOnInit() {

    }

    ngOnDestroy(): void {
        $('.modal').modal('hide');
        $('.modal-backdrop').remove();
    }

    convertDataIdName() {

        if (this.selectedData) {
            let found = this.imageDatas.find(idata => {
                return idata.id == this.selectedData.id;
            })
            if (found) {
                return `${found.id >= 0 ? found.id + ' :' : ''} ${found.name}`
            } else {
                return `선택된 ${this.subject} 없음`
            }
        } else {
            return `선택된 ${this.subject} 없음`
        }
    }

    constructor() {
    }

    applySelectedData() {
        if (this.isExistSelectedData) {
            this.selectedData = this.imageDatas.find(data => {
                return data.selected
            })
            this.applyEvent.emit(this.selectedData);
            $('#image-selector-modal-' + this.subject).modal('hide')

        }
    }

    public openSelectModal() {
        if (this.disabled) {
            return
        }

        if (this.imageDatas.length == 0) {
            this.noDataEvent.emit();
            return
        }
        $('#image-selector-modal-' + this.subject).modal()
    }

    unselectData() {
        this.isExistSelectedData = false;
    }

    selectData($event, data) {
        let previousSelected = this.imageDatas.find(data => {
            return data.selected
        })
        if (previousSelected) {
            previousSelected.selected = false
        }
        data.selected = true;
        this.isExistSelectedData = true;
    }
}


export interface ImageDatas {
    id: number;
    name: string;
    image_url: string;
    selected?: boolean
}
