import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class CouponService extends BaseAPIService {

    private ROUTES = {
        list: '/coupon/collection/list',
        get: '/coupon/collection/:id',
        edit: '/coupon/collection',
        count: '/coupon/collection/:id/count',
        company: '/coupon/company',
        histories: '/coupon/collection/:id/history',
        history: '/coupon/history/:id',
        leaflet: '/coupon/leaflet',
        serialNumber: '/serialnumber',
        encrypt: '/coupon/encrypt',
        couponCreated: '/coupon/history/created/:historyId'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    get(context: any): Promise<any> {
        return this.GET(this.ROUTES.get, context).toPromise();
    }

    create(body: any): Promise<any> {
        return this.http.post(this.ROUTES.edit, body).toPromise();
    }

    update(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.edit, body).toPromise();
    }

    delete(context: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.get, context)).toPromise();
    }

    history(context: any, body: any): Promise<any> {
        return this.http.post(this.inject(this.ROUTES.histories, context), body).toPromise();
    }

    company(): Promise<any> {
        return this.GET(this.ROUTES.company).toPromise();
    }

    historyDetail(context: any): Promise<any> {
        return this.GET(this.ROUTES.history, context).toPromise();
    }

    historyUpdate(context: any, body: any): Promise<any> {
        return this.http.patch(this.inject(this.ROUTES.history, context), body).toPromise();
    }

    historyDelete(context: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.history, context)).toPromise();
    }

    serialNumber(body: any): Promise<any> {
        return this.http.post(this.ROUTES.serialNumber, body).toPromise();
    }

    encrypt(body: any): Promise<any> {
        return this.http.post(this.ROUTES.encrypt, body).toPromise();
    }

    count(context: any): Promise<any> {
        return this.GET(this.ROUTES.count, context).toPromise();
    }

    leaflet(): Promise<any> {
        return this.GET(this.ROUTES.leaflet).toPromise();
    }

    couponCreated(historyId: number): Promise<any> {
        return this.GET(this.inject(this.ROUTES.couponCreated, { historyId })).toPromise();
    }

}
