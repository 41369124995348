import { Pipe, PipeTransform } from '@angular/core';

/*
 * Enables the key/value pair of an item to be
 * visible within an ngFor
 * Usage:
 *   value of iteratable | keyValue
 * Example:
 * <ul>
 *   <li *ngFor='key of demo | keyValue'>
 *   Key: {{key.key}}, value: {{key.value}}
 *   </li>
 * </ul>
 * https://gist.github.com/bombadillo/b6bfe0386d8483d0c0f1eb81566f2f34 출처
*/
@Pipe({ name: 'keyValue' })
export class KeyValuePipe implements PipeTransform {
    transform<T, U extends { [id: string]: T } | { [id: number]: T }>(value: U, args?: any): { key: keyof U, value: U[keyof U] }[] {
        const keys: { key: keyof U, value: U[keyof U] }[] = [];
        for (const key in value) {
            keys.push({ key: key, value: value[key] });
        }
        if (args == 'desc') {
            keys.sort((a, b): number => {
                return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
            });
        }

        return keys;
    }
}
