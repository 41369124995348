import { Injectable, Directive, Input, ElementRef } from "@angular/core";


declare let mApp: any;

@Directive({
    selector: '[blockUI]',
    host: { '(click)': 'blockAndProcessLoader($event)' },
})
export class BlockUIDirective {

    @Input() blockTargetId: string;
    @Input() message: string;

    constructor(private el: ElementRef) {

    }

    blockAndProcessLoader() {
        mApp.block(`#${this.blockTargetId}`, {
            overlayColor: '#000000',
            type: 'loader',
            state: 'primary',
            message: this.message || 'Processing...'
        });





    }
    unblock() {
        mApp.unblock(`#${this.blockTargetId}`);
    }



}