import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReservationsAttribute, ThemeAttribute } from "types/types";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import * as _ from "lodash";
import * as moment from "moment";
import { DragulaService } from "ng2-dragula";
import { environment } from 'environments/environment';

declare let toastr: any;
declare let swal: any;
declare let $: any

@Component({
    selector: "theme-package",
    templateUrl: "theme-package.component.html",
    styleUrls: ["theme-package.component.scss"]
})
export class ThemePackageComponent implements OnInit {

    @Input()
    public viewThemes: ThemeAttribute[];
    @Input()
    public code: string;
    @Output()
    public getData = new EventEmitter();

    // 예약에 필요한 변수들
    public reservationThemes: ThemeAttribute[];
    public savedIndex: number = 0;
    public reservationTime = null;
    public reservationList: ReservationsAttribute[] = [];
    public detailReservationList: any[] = new Array();

    constructor(
        private http: HttpClient,
        private router: Router,
        private dragulaService: DragulaService
    ) {

    }

    ngOnInit() {
        $('.custom-popover').popover({
            placement: 'right',
            trigger: 'hover'
        });
    }

    ngAfterViewInit() {
        $(`#reservation_datetimepicker-${this.code}`).datetimepicker({
            todayHighlight: true,
            autoclose: true,
            pickerPosition: 'bottom-left',
            todayBtn: true,
            stepping: 1,
            format: 'yyyy-mm-dd hh:ii'
        });

        $(document).on('change', `#reservation_datetimepicker-${this.code}`, (e) => {
            this.reservationTime = _.isEmpty(e.target.value) ? null : moment(e.target.value).utc().format();
        });
    }

    goToEdit(themeId) {
        this.router.navigate([`/tab/theme/edit/${themeId}`]);
    }

    eventPrevent(e) {
        e.stopPropagation();
    }

    toLink(id) {
        let textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.innerText = `/theme/${id}`;
        document.body.appendChild(textArea);
        textArea.select();
        try {
            document.execCommand('copy');
            toastr.success('클립보드에 간편 URL이 복사되었습니다.');
        } catch (e: any) {
            toastr.warning('복사 실패');
        } finally {
            document.body.removeChild(textArea)
        }
    }

    saveList() {
        let modifyThemes = _.map(this.viewThemes, (theme, index) => {
            return {
                theme: theme,
                priority: index
            }
        });
        swal({
            title: '저장하시겠습니까?',
            type: 'info',
            showCancelButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.http
                    .patch('theme/list/priority', {
                        themes: modifyThemes,
                    })
                    .toPromise()
                    .then((result) => {
                        this.getData.emit("ok");
                        toastr.success('저장 성공!!');
                    }).catch(e => {
                        console.log(e);
                        toastr.warning('실패!');
                    });
                return true
            } else if (result.dismiss === 'cancel') {
                return false
            }
        });
    }

    codeNaming(code: string) {
        return environment.themes[code] || code;
    }

    typeNaming(type: string) {
        return environment.themeItems[type]?.name || type;
    }

    viewAllTypeNaming(type: string) {
        if (type == 'row') {
            return '로우형';
        } else if (type == 'grid') {
            return '그리드형';
        } else {
            return '해당없음';
        }
    }

    /* 예약 기능들 */

    statusChange($event: any, theme) {
        theme.status = $event.target.checked ? 'S3' : 'S2';
    }

    getReservation() {
        this.reservationThemes = _.cloneDeep(this.viewThemes);
        this.http
            .get(`reservation/theme/list`)
            .toPromise()
            .then((result: any) => {
                this.reservationList = result.filter(r => {
                    let task = JSON.parse(r.task);
                    return task.target == this.code;
                }).sort((a, b) => {
                    if (a.status == 'booked' && b.status !== 'booked') {
                        return -1
                    } else if (a.status !== 'booked' && b.status == 'booked') {
                        return 1
                    } else if (a.status !== 'booked' && b.status !== 'booked') {
                        if (a.status == 'fulfill' && b.status !== 'fulfill') {
                            return -1
                        } else if (a.status !== 'fulfill' && b.status == 'fulfill') {
                            return 1
                        } else {
                            return 0
                        }
                    }
                });
            })
            .catch(e => {
                console.log(e)
            });
    }

    showReservationDetail(reservation: ReservationsAttribute) {
        this.detailReservationList = JSON.parse(reservation.param);
    }


    cancelReservation(reservation) {
        this.http
            .patch('reservation/cancel', reservation, { observe: 'response' })
            .toPromise()
            .then((result) => {
                this.getReservation();
                toastr.success('예약 취소 되었습니다. ');
            })
            .catch(e => {
                console.log(e);
                toastr.success('에러 발생!!!!');
            })
    }

    addReservation() {
        if (moment(this.reservationTime).format('x') < moment().format('x') || this.reservationTime == null) {
            toastr.warning('예약시간을 확인하세요');
            return false;
        }

        let param = _.cloneDeep(this.reservationThemes).map((theme, index) => {
            return {
                id: theme.id,
                status: theme.status,
                title: theme.title,
                subtitle: theme.subtitle,
                priority: index
            }
        });

        this.http.post('reservation/theme', {
            info: {
                type: 'theme',
                task: JSON.stringify({ status: 'update', target: this.code }),
                reservation_time: moment(this.reservationTime).format('x'),
                param: param,
                ref_id: 0
            }
        }, { observe: 'response' })
            .toPromise()
            .then((result) => {
                this.getReservation();
                toastr.success('예약 되었습니다.');
            })
            .catch(e => {
                console.log(e);
                toastr.success('에러 발생!! 예약실패!!');
            })
    }

}
