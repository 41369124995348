<div class="target" *ngIf="target">
    <div class="form-group preset-group">
        <button type="button" class="btn btn-outline-info" (click)="openTargetUse()">고급 설정{{targetCount()}}</button>
    </div>
    <div class="form-group" *ngIf="targetUse.genders.use">
        <label class="col-form-label">
            성별
        </label>
        <select class="form-control" name="view_all_type"
                [ngModel]="getGender()" (ngModelChange)="setGender($event)">
            <option [ngValue]="null">전체</option>
            <option value="M">남성</option>
            <option value="F">여성</option>
        </select>
    </div>
    <div class="form-group" [hidden]="!targetUse.birth.use">
        <label class="col-form-label">
            생년월일
        </label>
        <app-datepicker #dateValidate
                        [(value)]="target.birth.start"
                        [(secondValue)]="target.birth.end"
                        type="date"
                        [range]="true"
        ></app-datepicker>
    </div>
    <div class="form-group" *ngIf="targetUse.hmUnsure.use">
        <label class="col-form-label">
            시간 모름
        </label>
        <div class="status-checkbox">
            <span class="m-switch m-switch--outline m-switch--primary">
                <label>
                    <input type="checkbox" [(ngModel)]="target.hmUnsure"
                           name="hmUnsure"/>
                    <span></span>
                </label>
            </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.animals.use">
        <label class="col-form-label">
            일주 동물
            <small class="text-info ml-1">중복 선택</small>
        </label>
        <div class="mb-2">
            <button class="btn btn-sm m-btn--pill btn-secondary m-btn m-btn--custom mr-2 mb-2"
                    *ngFor="let name of animalName" (click)="selectAnimal(name)">
                {{name}}</button>
            <button class="btn btn-sm m-btn--pill btn-outline-danger mb-2"
                    (click)="target.animals.length = 0">전체삭제
            </button>
        </div>
        <app-tag [(value)]="target.animals" [objectList]="animals" inputType="select" type="object"
        ></app-tag>
    </div>
    <div class="form-group" *ngIf="targetUse.originalAnimals.use">
        <label class="col-form-label">
            연주 동물
            <small class="text-info ml-1">중복 선택</small>
        </label>
        <div class="mb-2">
            <button class="btn btn-sm m-btn--pill btn-secondary m-btn m-btn--custom mr-2 mb-2"
                    *ngFor="let name of animalName" (click)="selectOriginalAnimal(name)">
                {{name}}</button>
            <button class="btn btn-sm m-btn--pill btn-outline-danger mb-2"
                    (click)="target.originalAnimals.length = 0">전체삭제
            </button>
        </div>
        <app-tag [(value)]="target.originalAnimals" [objectList]="originalAnimals" inputType="select" type="object"
        ></app-tag>
    </div>
    <div class="form-group" *ngIf="targetUse.zodiacs.use">
        <label class="col-form-label">
            별자리
            <small class="text-info ml-1">중복 선택</small>
        </label>
        <app-tag [(value)]="target.zodiacs" [objectList]="zodiacs" inputType="select" type="object"
        ></app-tag>
    </div>
    <div class="form-group" *ngIf="targetUse.anonymous.use">
        <label class="col-form-label">
            게스트 여부
        </label>
        <div class="status-checkbox">
            <span class="m-switch m-switch--outline m-switch--primary">
                <label>
                    <input type="checkbox" [(ngModel)]="target.anonymous"
                           name="anonymous"/>
                    <span></span>
                </label>
            </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.version.use">
        <label class="col-form-label">
            클라이언트 버전
        </label>
        <div class="row">
            <div class="col-5 input-group">
                <select class="form-control" [(ngModel)]="target.version.start">
                    <option *ngFor="let v of versionList" [value]="v.ver">
                        {{v.ver}}
                    </option>
                </select>
            </div>
            <span icon-m opacity-50>~</span>
            <div class="col-5 input-group">
                <select class="form-control" [(ngModel)]="target.version.end">
                    <option *ngFor="let v of versionList" [value]="v.ver">
                        {{v.ver}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.userIds.use">
        <label class="col-form-label">
            user_id
            <small class="text-info ml-1">입력 후 엔터</small>
        </label>
        <app-tag *ngIf="target.userIds.length < 300" [(value)]="target.userIds" inputType="text"
                 type="number"></app-tag>
        <p *ngIf="target.userIds.length >= 300">{{target.userIds.length}}명</p>
    </div>
    <div class="form-group" [hidden]="!targetUse.signup.use">
        <label class="col-form-label">
            가입 일시
        </label>
        <app-datepicker #dateValidate
                        [(value)]="target.signup.start"
                        [(secondValue)]="target.signup.end"
                        type="both"
                        [range]="true"
        ></app-datepicker>
    </div>
    <div class="form-group" *ngIf="targetUse.isFirstSignUp.use">
        <label class="col-form-label">
            첫 가입 여부
        </label>
        <div class="status-checkbox">
            <span class="m-switch m-switch--outline m-switch--primary">
                <label>
                    <input type="checkbox" [(ngModel)]="target.isFirstSignUp"
                           name="isFirstSignUp"/>
                    <span></span>
                </label>
            </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.daysAfterSignup.use">
        <label class="col-form-label d-block">
            ~일 이내 가입한 유저
        </label>
        <input [(ngModel)]="target.daysAfterSignup" type="number" class="form-control w-25 d-inline-block"/> 일 이내
    </div>
    <div class="form-group" *ngIf="targetUse.daysPassAfterSignup.use">
        <label class="col-form-label d-block">
            가입한지 ~일 이후 유저
        </label>
        <input [(ngModel)]="target.daysPassAfterSignup" type="number" class="form-control w-25 d-inline-block"/> 일 이후
    </div>
    <div class="form-group" *ngIf="targetUse.dayOfWeeks.use">
        <label class="col-form-label">
            요일
            <small class="text-info ml-1">중복 선택</small>
        </label>
        <div class="mb-2">
            <button class="btn btn-sm m-btn--pill btn-secondary m-btn m-btn--custom mr-2 mb-2"
                    *ngFor="let name of dayOfWeekName" (click)="selectDay(name)">
                {{name}}</button>
            <button class="btn btn-sm m-btn--pill btn-outline-danger mb-2"
                    (click)="target.dayOfWeeks.length = 0">전체삭제
            </button>
        </div>
        <app-tag [(value)]="target.dayOfWeeks" [objectList]="dayOfWeeks" inputType="select"
                 type="object"
        ></app-tag>
    </div>

    <div class="form-group" *ngIf="targetUse.levels.use">
        <label class="col-form-label">
            포덕 레벨
            <small class="text-info ml-1">중복 선택</small>
        </label>
        <div class="mb-2">
            <button class="btn btn-sm m-btn--pill btn-secondary m-btn m-btn--custom mr-2 mb-2"
                    *ngFor="let name of levelNames" (click)="selectLevel(name)">
                {{name}}</button>
            <button class="btn btn-sm m-btn--pill btn-outline-danger mb-2"
                    (click)="target.levels.length = 0">전체삭제
            </button>
        </div>
        <app-tag [(value)]="target.levels" [objectList]="levels" inputType="select"
                 type="object"
        ></app-tag>
    </div>

    <div class="form-group" *ngIf="targetUse.jpLevels.use">
        <label class="col-form-label">
            일본 레벨
            <small class="text-info ml-1">중복 선택</small>
        </label>
        <div class="mb-2">
            <button class="btn btn-sm m-btn--pill btn-secondary m-btn m-btn--custom mr-2 mb-2"
                    *ngFor="let name of jpLevelNames" (click)="selectJpLevel(name)">
                {{name}}</button>
            <button class="btn btn-sm m-btn--pill btn-outline-danger mb-2"
                    (click)="target.jpLevels.length = 0">전체삭제
            </button>
        </div>
        <app-tag [(value)]="target.jpLevels" [objectList]="jpLevels" inputType="select"
                 type="object"
        ></app-tag>
    </div>

    <div class="form-group" *ngIf="targetUse.birthday.use">
        <label class="col-form-label">
            생일
        </label>
        <div class="status-checkbox">
                                      <span class="m-switch m-switch--outline m-switch--primary">
                                          <label>
                                              <input type="checkbox" [(ngModel)]="target.birthday"
                                                     name="birthday"/>
                                              <span></span>
                                          </label>
                                      </span>
        </div>
    </div>

    <div class="form-group" *ngIf="targetUse.giftIds.use">
        <label class="col-form-label">
            gift(선물함) id
            <small class="text-info ml-1">입력 후 엔터 / 선물함에서 사용(USED)된 gift ids가 있다면 포스트가 노출되지 않습니다.</small>
        </label>
        <app-tag [(value)]="target.giftIds" inputType="text" type="number"></app-tag>
    </div>

    <div class="form-group" *ngIf="targetUse.notUsedGiftIds.use">
        <label class="col-form-label">
            사용하지 않은 gift id
            <small class="text-info ml-1">입력 후 엔터 / 선물함에서 사용안(NOT_USED)된 gift ids가 있다면 포스트가 노출됩니다.</small>
        </label>
        <app-tag [(value)]="target.notUsedGiftIds" inputType="text" type="number"></app-tag>
    </div>

    <div class="form-group" *ngIf="targetUse.existGiftIds.use">
        <label class="col-form-label">
            존재하는 gift id
            <small class="text-info ml-1">입력 후 엔터 / 선물함에서 사용여부 상관없이 gift ids가 있다면 포스트가 노출되지 않습니다.</small>
        </label>
        <app-tag [(value)]="target.existGiftIds" inputType="text" type="number"></app-tag>
    </div>


    <div class="form-group" [hidden]="!targetUse.purchaseDate.use">
        <label class="col-form-label">
            포스 충전 일시
        </label>
        <app-datepicker #dateValidate
                        [(value)]="target.purchaseDate.start"
                        [(secondValue)]="target.purchaseDate.end"
                        type="both"
                        [range]="true"
        ></app-datepicker>
    </div>

    <div class="form-group" [hidden]="!targetUse.notPurchaseDate.use">
        <label class="col-form-label">
            포스 충전 안 할 일시
        </label>
        <app-datepicker #dateValidate
                        [(value)]="target.notPurchaseDate.start"
                        [(secondValue)]="target.notPurchaseDate.end"
                        type="both"
                        [range]="true"
        ></app-datepicker>
    </div>

    <div class="form-group" [hidden]="!targetUse.lastPurchaseDate.use">
        <label class="col-form-label">
            마지막 포스 충전 일시
        </label>
        <app-datepicker #dateValidate
                        [(value)]="target.lastPurchaseDate.start"
                        [(secondValue)]="target.lastPurchaseDate.end"
                        type="both"
                        [range]="true"
        ></app-datepicker>
    </div>
    <div class="form-group" [hidden]="!targetUse.lastLoginDate.use">
        <label class="col-form-label">
            마지막 로그인 일시
        </label>
        <app-datepicker #dateValidate
                        [(value)]="target.lastLoginDate.start"
                        [(secondValue)]="target.lastLoginDate.end"
                        type="both"
                        [range]="true"
        ></app-datepicker>
    </div>
    <div class="form-group" [hidden]="!targetUse.timezone.use">
        <label class="col-form-label">
           글로벌 유저 시간
        </label>
        <app-datepicker #dateValidate
                        [isMilliSecond]="true"
                        [(value)]="target.timezone.start"
                        [(secondValue)]="target.timezone.end"
                        type="both"
                        [range]="true"
        ></app-datepicker>
    </div>
    <div class="form-group" *ngIf="targetUse.fatebookCount.use">
        <label class="col-form-label">
            프리미엄 운세 보유 수
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control"
                       [(ngModel)]="target.fatebookCount.start" autocomplete="off">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control"

                       [(ngModel)]="target.fatebookCount.end" autocomplete="off">
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.canSlotMachine.use">
        <label class="col-form-label">
            슬롯머신 여부
        </label>
        <div class="status-checkbox">
                                      <span class="m-switch m-switch--outline m-switch--primary">
                                          <label>
                                              <input type="checkbox" [(ngModel)]="target.canSlotMachine"
                                                     name="canSlotMachine"/>
                                              <span></span>
                                          </label>
                                      </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.chulcheck.use">
        <label class="col-form-label">
            출석체크 여부
        </label>
        <div class="status-checkbox">
                                      <span class="m-switch m-switch--outline m-switch--primary">
                                          <label>
                                              <input type="checkbox" [(ngModel)]="target.chulcheck"
                                                     name="chulcheck"/>
                                              <span></span>
                                          </label>
                                      </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.push.use">
        <label class="col-form-label">
            앱내 푸쉬 설정 여부
        </label>
        <div class="status-checkbox">
                           <span class="m-switch m-switch--outline m-switch--primary">
                               <label>
                                   <input type="checkbox" [(ngModel)]="target.push"
                                          name="push"/>
                                   <span></span>
                               </label>
                           </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.total.use">
        <label class="col-form-label">
            보유 전체 포스
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control"
                       placeholder=""
                       [(ngModel)]="target.total.start" autocomplete="off">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control input-group"

                       [(ngModel)]="target.total.end" autocomplete="off">
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.force.use">
        <label class="col-form-label">
            보유 포스
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control"
                       [(ngModel)]="target.force.start" autocomplete="off">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control"
                       [(ngModel)]="target.force.end" autocomplete="off">
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="targetUse.bonus.use">
        <label class="col-form-label">
            보유 보너스포스
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control"
                       [(ngModel)]="target.bonus.start" autocomplete="off">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control"
                       [(ngModel)]="target.bonus.end" autocomplete="off">
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="targetUse.chargeRecord.use">
        <label class="col-form-label">
            충전한 포스 금액
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control"
                       [(ngModel)]="target.chargeRecord.start" autocomplete="off">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control"
                       [(ngModel)]="target.chargeRecord.end" autocomplete="off">
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="targetUse.withdrawalRecord.use">
        <label class="col-form-label">
            포스 소진 금액
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control"
                       [(ngModel)]="target.withdrawalRecord.start" autocomplete="off">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control"
                       [(ngModel)]="target.withdrawalRecord.end" autocomplete="off">
            </div>
        </div>
    </div>

    <div class="form-group" *ngIf="targetUse.fortuneItemIds.use">
        <label class="col-form-label">
            이용한 무료 운세 id (OR)
            <small class="text-info ml-1">입력 후 엔터</small>
        </label>
        <app-tag [(value)]="target.fortuneItemIds" inputType="text" type="number"></app-tag>
    </div>
    <div class="form-group" *ngIf="targetUse.fortuneItemNoIds.use">
        <label class="col-form-label">
            이용안한 무료 운세 id (AND)
            <small class="text-info ml-1">입력 후 엔터</small>
        </label>
        <app-tag [(value)]="target.fortuneItemNoIds" inputType="text" type="number"
        ></app-tag>
    </div>
    <div class="form-group" *ngIf="targetUse.fortuneItemTags.use">
        <label class="col-form-label">
            이용한 무료 운세 태그 (OR)
            <small class="text-info ml-1">입력 후 엔터</small>
        </label>
        <app-tag [(value)]="target.fortuneItemTags" inputType="text" type="string"
        ></app-tag>
    </div>
    <div class="form-group" *ngIf="targetUse.fortuneItemCategories.use">
        <label class="col-form-label">
            이용한 무료 운세 장르 (OR)
            <small class="text-info ml-1">입력 후 엔터</small>
        </label>
        <app-tag [(value)]="target.fortuneItemCategories" inputType="text" type="string"
        ></app-tag>
    </div>
    <div class="form-group" *ngIf="targetUse.fortuneItemRecord.use">
        <label class="col-form-label d-block">
            ~일 이내 이용한 무료 운세 id (OR)
        </label>
        <input [(ngModel)]="target.fortuneItemRecord.day" type="number" class="form-control w-25 d-inline-block"/> 일 이내
        <small class="text-info mt-2 mb-2 d-block">입력 후 엔터</small>
        <app-tag [(value)]="target.fortuneItemRecord.ids" inputType="text" type="number"
        ></app-tag>
    </div>
    <div class="form-group" *ngIf="targetUse.premiumItemIds.use">
        <label class="col-form-label">
            이용한 프리미엄 id (OR)
            <small class="text-info ml-1">입력 후 엔터</small>
        </label>
        <app-tag [(value)]="target.premiumItemIds" inputType="text" type="number"
        ></app-tag>
    </div>
    <div class="form-group" *ngIf="targetUse.premiumItemNoIds.use">
        <label class="col-form-label">
            이용안한 프리미엄 id (AND)
            <small class="text-info ml-1">입력 후 엔터</small>
        </label>
        <app-tag [(value)]="target.premiumItemNoIds" inputType="text" type="number"
        ></app-tag>
    </div>
    <div class="form-group" *ngIf="targetUse.premiumItemRecord.use">
        <label class="col-form-label d-block">
            ~일 이내 이용한 프리미엄 id (OR)
        </label>
        <input [(ngModel)]="target.premiumItemRecord.day" type="number" class="form-control w-25 d-inline-block"/> 일 이내
        <small class="text-info mt-2 mb-2 d-block">입력 후 엔터</small>
        <app-tag [(value)]="target.premiumItemRecord.ids" inputType="text" type="number"
        ></app-tag>
    </div>
    <div class="form-group" *ngIf="targetUse.daysWithinLastPremium.use">
        <label class="col-form-label d-block">
            ~일 이내 프리미엄 구입한 유저
        </label>
        <input [(ngModel)]="target.daysWithinLastPremium" type="number" class="form-control w-25 d-inline-block"/> 일 이내
    </div>
    <div class="form-group" *ngIf="targetUse.daysAfterLastPremium.use">
        <label class="col-form-label d-block">
            마지막 프리미엄 구매 후 ~일 지난 유저
        </label>
        <input [(ngModel)]="target.daysAfterLastPremium" type="number" class="form-control w-25 d-inline-block"/> 일 지남
    </div>
    <div class="form-group" *ngIf="targetUse.dailyScore.use">
        <label class="col-form-label">
            오늘의 운세 점수
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control m-input"
                       name="subTitle"
                       [(ngModel)]="target.dailyScore.start">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control m-input"
                       name="subTitle"
                       [(ngModel)]="target.dailyScore.end"></div>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.friendCount.use">
        <label class="col-form-label">
            전체 궁합 상대
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control m-input"
                       name="subTitle"
                       [(ngModel)]="target.friendCount.start">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control m-input"
                       name="subTitle"
                       [(ngModel)]="target.friendCount.end"></div>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.friendInviteCount.use">
        <label class="col-form-label">
            초대한 궁합 상대
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control"
                       name="subTitle"
                       [(ngModel)]="target.friendInviteCount.start">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control"
                       name="subTitle"
                       [(ngModel)]="target.friendInviteCount.end"></div>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.friendAddCount.use">
        <label class="col-form-label">
            추가한 궁합 상대
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control"
                       name="subTitle"
                       [(ngModel)]="target.friendAddCount.start">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control"
                       name="subTitle"
                       [(ngModel)]="target.friendAddCount.end"></div>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.friendCelebCount.use">
        <label class="col-form-label">
            추가한 연예인
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control m-input"
                       name="subTitle"
                       [(ngModel)]="target.friendCelebCount.start">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control m-input"
                       name="subTitle"
                       [(ngModel)]="target.friendCelebCount.end">
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.custom.use">
        <div>
            <label class="col-form-label">
                커스텀 변수
            </label>
            <button class="btn btn-outline-info m-btn m-btn--icon  m-btn--icon-only m-btn--pill m-btn--air float-right"
                    href="#" (click)="makeCustomValueInput()">
                <i class="la la-plus"></i>
            </button>
        </div>
        <div code class="form-group m-form__group row" *ngFor="let target of customTarget ; let i = index ">
            <div class="col-3">
                <input code type="text" class="form-control m-input" placeholder="변수 명" [(ngModel)]="target[0]">
            </div>
            <div class="col-9 d-flex">
                <div style="flex:1;">
                    <input type="text" *ngIf="target[2] == 'text';" class="form-control" placeholder="변수 값"
                           [(ngModel)]="target[1]">
                    <textarea *ngIf="target[2] == 'textarea'" class="form-control m-input" rows="10" cols=150
                              placeholder="변수 값" [(ngModel)]="target[1]"></textarea>
                </div>
                <button href="#" class="btn btn-secondary m-btn m-btn--icon m-btn--icon-only ml-2"
                        (click)="target[2] = target[2] == 'text' ? 'textarea' : 'text'">
                    <i class="fa fa-expand cc_pointer"></i>
                </button>
                <button class="btn btn-danger m-btn m-btn--icon m-btn--icon-only mx-2"
                        (click)="this.customTarget.splice(i,1)">
                    <i class="fa fa-trash cc_pointer"></i>
                </button>
            </div>
        </div>
        <div *ngIf="customTarget.length == 0">
            등록된 커스텀 변수가 없습니다.
        </div>
    </div>

    <div class="form-group" *ngIf="targetUse.isWelcomeUser.use">
        <label class="col-form-label">
            웰컴 유저 여부 *웰컴 이벤트에서 사용합니다.
        </label>
        <div class="status-checkbox">
                           <span class="m-switch m-switch--outline m-switch--primary">
                               <label>
                                   <input type="checkbox" [(ngModel)]="target.isWelcomeUser"
                                          name="push"/>
                                   <span></span>
                               </label>
                           </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.isCheckTime.use">
        <label class="col-form-label">
            비활성 여부 *체크하면 00:00~00:30분에 비활성화 시킵니다.
        </label>
        <div class="status-checkbox">
                           <span class="m-switch m-switch--outline m-switch--primary">
                               <label>
                                   <input type="checkbox" [(ngModel)]="target.isCheckTime"
                                          name="push"/>
                                   <span></span>
                               </label>
                           </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.hasCharged.use">
        <label class="col-form-label">
            포스 충전 여부
        </label>
        <div class="status-checkbox">
                           <span class="m-switch m-switch--outline m-switch--primary">
                               <label>
                                   <input type="checkbox" [(ngModel)]="target.hasCharged"
                                          name="push"/>
                                   <span></span>
                               </label>
                           </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.daysAfterLastCharge.use">
        <label class="col-form-label">
            마지막 충전 후 n~m 일 (
        <app-checkbox class="mb-2" [disabled]="targetChoice.daysAfterLastChargeInputOneValue.disabled"
                      [(value)]="targetChoice.daysAfterLastChargeInputOneValue.use" (click)="clearDaysAfterLastCharge()"  text="n일 이후로 설정하기"></app-checkbox> )
        </label>
        <div *ngIf="!targetChoice.daysAfterLastChargeInputOneValue.use">
            <div class="row">
                <div class="col-5">
                    <input type="number" class="form-control m-input"
                           name="subTitle"
                           [(ngModel)]="target.daysAfterLastCharge.start">
                </div>
                ~
                <div class="col-5">
                    <input type="number" class="form-control m-input"
                           name="subTitle"
                           [(ngModel)]="target.daysAfterLastCharge.end"></div>
            </div>
        </div>
        <div *ngIf="targetChoice.daysAfterLastChargeInputOneValue.use">
            <div class="row">
                <div class="col-5">
                    <input type="number" class="form-control m-input w-75 d-inline-block"
                           name="subTitle"
                           [(ngModel)]="target.daysAfterLastCharge.end">일 이후</div>

            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.purchaseCount.use">
        <label class="col-form-label">
            최근 30일 이내 포스 충전 횟수
        </label>
        <div class="row">
            <div class="col-5">
                <input type="number" class="form-control"
                       [(ngModel)]="target.purchaseCount.start" autocomplete="off">
            </div>
            ~
            <div class="col-5">
                <input type="number" class="form-control"
                       [(ngModel)]="target.purchaseCount.end" autocomplete="off">
            </div>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.hasPremiumItem.use">
        <label class="col-form-label">
            프리미엄 운세 보유 여부
        </label>
        <div class="status-checkbox">
                           <span class="m-switch m-switch--outline m-switch--primary">
                               <label>
                                   <input type="checkbox" [(ngModel)]="target.hasPremiumItem"
                                          name="push"/>
                                   <span></span>
                               </label>
                           </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.hasFortuneItem.use">
        <label class="col-form-label">
            30일 이내 무료 운세 이용 여부
        </label>
        <div class="status-checkbox">
                           <span class="m-switch m-switch--outline m-switch--primary">
                               <label>
                                   <input type="checkbox" [(ngModel)]="target.hasFortuneItem"
                                          name="push"/>
                                   <span></span>
                               </label>
                           </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.hasVoucher.use">
        <label class="col-form-label">
            30일 이내 소액아이템 이용 여부
        </label>
        <div class="status-checkbox">
                           <span class="m-switch m-switch--outline m-switch--primary">
                               <label>
                                   <input type="checkbox" [(ngModel)]="target.hasVoucher"
                                          name="push"/>
                                   <span></span>
                               </label>
                           </span>
        </div>
    </div>
    <div class="form-group" *ngIf="targetUse.hasPremiumItemIn30Day.use">
        <label class="col-form-label">
            30일 이내 프리미엄 운세 이용 여부
        </label>
        <div class="status-checkbox">
                           <span class="m-switch m-switch--outline m-switch--primary">
                               <label>
                                   <input type="checkbox" [(ngModel)]="target.hasPremiumItemIn30Day"
                                          name="push"/>
                                   <span></span>
                               </label>
                           </span>
        </div>
    </div>
</div>
<div class="modal fade" id="target-modal" role="dialog" aria-labelledby="themeReservationLabel" *ngIf="target"
     aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document" style="min-height: 0px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="themeReservationLabel">
                    <b>사용할 설정을 선택해 주세요</b>
                </h5>
                <button type="button" class="close" (click)="closeTargetUse()">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-3">
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.genders.disabled"
                                      [(value)]="targetUse.genders.use" text="성별"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.birth.disabled"
                                      [(value)]="targetUse.birth.use" text="생년월일"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.hmUnsure.disabled"
                                      [(value)]="targetUse.hmUnsure.use" text="시간 모름"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.animals.disabled"
                                      [(value)]="targetUse.animals.use" text="일주 동물"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.originalAnimals.disabled"
                                      [(value)]="targetUse.originalAnimals.use" text="연주 동물"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.zodiacs.disabled"
                                      [(value)]="targetUse.zodiacs.use" text="별자리"></app-checkbox>
                        <app-checkbox class="d-block mb-4" [disabled]="targetUse.anonymous.disabled"
                                      [(value)]="targetUse.anonymous.use" text="게스트 여부"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.version.disabled"
                                      [(value)]="targetUse.version.use" text="클라이언트 버전"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.userIds.disabled"
                                      [(value)]="targetUse.userIds.use" text="user id"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.signup.disabled"
                                      [(value)]="targetUse.signup.use" text="가입 일시"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.daysAfterSignup.disabled"
                                      [(value)]="targetUse.daysAfterSignup.use" text="~일 이내 가입한 유저"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.daysPassAfterSignup.disabled"
                                      [(value)]="targetUse.daysPassAfterSignup.use" text="가입한지 ~일 이후 유저"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.dayOfWeeks.disabled"
                                      [(value)]="targetUse.dayOfWeeks.use" text="요일"></app-checkbox>
                        <app-checkbox class="d-block mb-4" [disabled]="targetUse.isFirstSignUp.disabled"
                                      [(value)]="targetUse.isFirstSignUp.use" text="첫 가입 여부"></app-checkbox>
                    </div>
                    <div class="col-3">
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.purchaseDate.disabled"
                                      [(value)]="targetUse.purchaseDate.use" text="포스 충전 일시"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.notPurchaseDate.disabled"
                                      [(value)]="targetUse.notPurchaseDate.use" text="포스 충전 안 할 일시"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.lastPurchaseDate.disabled"
                                      [(value)]="targetUse.lastPurchaseDate.use" text="마지막 포스 충전 일시"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.lastLoginDate.disabled"
                                      [(value)]="targetUse.lastLoginDate.use" text="마지막 로그인 일시"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.timezone.disabled"
                                      [(value)]="targetUse.timezone.use" text="글로벌 유저 시간"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.fatebookCount.disabled"
                                      [(value)]="targetUse.fatebookCount.use" text="프리미엄 운세 보유 수"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.push.disabled"
                                      [(value)]="targetUse.push.use" text="앱내 푸쉬 알림 설정"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.canSlotMachine.disabled"
                                      [(value)]="targetUse.canSlotMachine.use" text="슬롯머신"></app-checkbox>
                        <app-checkbox class="d-block mb-4" [disabled]="targetUse.chulcheck.disabled"
                                      [(value)]="targetUse.chulcheck.use" text="출석체크"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.total.disabled"
                                      [(value)]="targetUse.total.use" text="보유 전체 포스"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.force.disabled"
                                      [(value)]="targetUse.force.use" text="보유 포스"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.bonus.disabled"
                                      [(value)]="targetUse.bonus.use" text="보유 보너스포스"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.chargeRecord.disabled"
                                      [(value)]="targetUse.chargeRecord.use" text="충전한 포스 금액"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.withdrawalRecord.disabled"
                                      [(value)]="targetUse.withdrawalRecord.use" text="포스 소진 금액"></app-checkbox>
                    </div>
                    <div class="col-3">
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.fortuneItemIds.disabled"
                                      [(value)]="targetUse.fortuneItemIds.use" text="이용한 무료 운세 id"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.fortuneItemNoIds.disabled"
                                      [(value)]="targetUse.fortuneItemNoIds.use" text="이용안한 무료 운세 id"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.fortuneItemTags.disabled"
                                      [(value)]="targetUse.fortuneItemTags.use" text="이용한 무료 운세 태그"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.fortuneItemCategories.disabled"
                                      [(value)]="targetUse.fortuneItemCategories.use"
                                      text="이용한 무료 운세 장르"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.fortuneItemRecord.disabled"
                                      [(value)]="targetUse.fortuneItemRecord.use"
                                      text="~일 이내 이용한 무료 운세 id"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.premiumItemIds.disabled"
                                      [(value)]="targetUse.premiumItemIds.use" text="이용한 프리미엄 id"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.premiumItemNoIds.disabled"
                                      [(value)]="targetUse.premiumItemNoIds.use" text="이용안한 프리미엄 id"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.premiumItemRecord.disabled"
                                      [(value)]="targetUse.premiumItemRecord.use"
                                      text="~일 이내 이용한 프리미엄 id"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.daysWithinLastPremium.disabled"
                                      [(value)]="targetUse.daysWithinLastPremium.use" text="~일 이내 프리미엄 구입한 유저"></app-checkbox>
                        <app-checkbox class="d-block mb-4" [disabled]="targetUse.daysAfterLastPremium.disabled"
                                      [(value)]="targetUse.daysAfterLastPremium.use" text="마지막 프리미엄 구매 후 ~일 지난 유저"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.dailyScore.disabled"
                                      [(value)]="targetUse.dailyScore.use" text="오늘의 운세 점수"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.birthday.disabled"
                                      [(value)]="targetUse.birthday.use" text="생일"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.giftIds.disabled"
                                      [(value)]="targetUse.giftIds.use" text="gift id"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.notUsedGiftIds.disabled"
                                      [(value)]="targetUse.notUsedGiftIds.use" text="사용하지 않은 gift id"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.existGiftIds.disabled"
                                      [(value)]="targetUse.existGiftIds.use" text="존재하고 있는 gift id"></app-checkbox>
                    </div>
                    <div class="col-3">
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.friendCount.disabled"
                                      [(value)]="targetUse.friendCount.use" text="전체 궁합 상대"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.friendInviteCount.disabled"
                                      [(value)]="targetUse.friendInviteCount.use" text="초대한 궁합 상대"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.friendAddCount.disabled"
                                      [(value)]="targetUse.friendAddCount.use" text="추가한 궁합 상대"></app-checkbox>
                        <app-checkbox class="d-block mb-4" [disabled]="targetUse.friendCelebCount.disabled"
                                      [(value)]="targetUse.friendCelebCount.use" text="추가한 연예인"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.isWelcomeUser.disabled"
                                      [(value)]="targetUse.isWelcomeUser.use" text="웰컴 유저"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.isCheckTime.disabled"
                                      [(value)]="targetUse.isCheckTime.use" text="비활성 여부"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.custom.disabled"
                                      [(value)]="targetUse.custom.use" text="커스텀 변수 사용"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.levels.disabled"
                                      [(value)]="targetUse.levels.use" text="포덕 레벨"></app-checkbox>
                        <app-checkbox class="d-block mb-4" [disabled]="targetUse.jpLevels.disabled"
                                      [(value)]="targetUse.jpLevels.use" text="일본 레벨"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.hasCharged.disabled"
                                      [(value)]="targetUse.hasCharged.use" text="포스 충전 여부"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.daysAfterLastCharge.disabled"
                                      [(value)]="targetUse.daysAfterLastCharge.use" text="마지막 충전 후 ~일"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.purchaseCount.disabled"
                                      [(value)]="targetUse.purchaseCount.use" text="최근 30일 포스 구매 횟수"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.hasPremiumItem.disabled"
                                      [(value)]="targetUse.hasPremiumItem.use" text="프리미엄 운세 보유 여부"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.hasFortuneItem.disabled"
                                      [(value)]="targetUse.hasFortuneItem.use" text="30일 이내 무료 운세 이용 여부"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.hasVoucher.disabled"
                                      [(value)]="targetUse.hasVoucher.use" text="30일 이내 소액아이템 이용 여부"></app-checkbox>
                        <app-checkbox class="d-block mb-2" [disabled]="targetUse.hasPremiumItemIn30Day.disabled"
                                      [(value)]="targetUse.hasPremiumItemIn30Day.use" text="30일 이내 프리미엄 운세 보유 여부"></app-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
