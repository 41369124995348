<div class="row mb-3">
  <label class="col-form-label col-12">
    {{label}}
    <small class="text-danger ml-1" *ngIf="required">*필수</small>
  </label>
  <div class="col-12 d-flex">
    <input type="color" class="form-control" [(ngModel)]="value">
    <input type="text" class="form-control" [placeholder]="placeholder" [(ngModel)]="value">
  </div>
</div>
