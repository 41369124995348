<div class="modal" [class.active]="active">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="csvPromotionsModalLabel">
                    <b>커스텀 테마 생성</b>
                </h5>
                <button type="button" class="close" (click)="close()">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="d-flex align-items-center justify-content-between m-2">
                        <h6>CSV 양식 샘플</h6>
                        <button class="btn btn-primary" type="button" (click)="download()">샘플 다운로드</button>
                    </div>
                    <table class="table table-striped m-table">
                        <thead class="m-datatable__head thead-light">
                        <tr>
                            <th>customTitle</th>
                            <th>title</th>
                            <th>thumbnail_image</th>
                            <th>label_text</th>
                            <th>label_color</th>
                            <th>link</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>제목</td>
                            <td>제목2</td>
                            <td>https://image.png</td>
                            <td>라벨</td>
                            <td>#00000</td>
                            <td>https://forceteller.com</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <app-ctsv-uploader
                            class="app-content-body-max-width"
                            apiUrl="/api/upload/csv"
                            uploaderId="ctsv-uploader"
                            (successCsv)="successCsv($event)">
                    </app-ctsv-uploader>
                </div>
                <div class="my-2 d-flex justify-content-between">
                    <button class="btn btn-primary" [disabled]="!uploadList.length || !f.form.valid || isUploading" form="uploadForm">해당 리스트 업로드</button>
                    <a class="btn btn-outline-info m-btn m-btn--icon  m-btn--icon-only m-btn--pill m-btn--air"
                       href="javascript:;" (click)="addItem()">
                        <i class="la la-plus"></i>
                    </a>
                </div>
                <form id="uploadForm" #f="ngForm" (ngSubmit)="upload()">
                    <table class="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline">
                        <thead style="background: #ebedf2" class="m-datatable__head">
                        <tr>
                            <th style="width: 120px">
                                이름
                            </th>
                            <th>
                                제목
                            </th>
                            <th>
                                썸네일 이미지
                            </th>
                            <th>
                                라벨 이름
                            </th>
                            <th>
                                라벨 색상
                            </th>
                            <th>
                                링크
                            </th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of uploadList; let i = index" class="banner-area-list-row">
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.customTitle" name="customTitle{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['customTitle'+i]?.touched) && f.controls['customTitle'+i]?.invalid">값을 입력해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.title" name="title{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['title'+i]?.touched) && f.controls['title'+i]?.invalid">값을 입력해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.thumbnail_image" name="thumbnail_image{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['thumbnailImage'+i]?.touched) && f.controls['thumbnailImage'+i]?.invalid">값을 입력해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.label_text" name="label_text{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['label_text'+i]?.touched) && f.controls['label_text'+i]?.invalid">값을 입력해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.label_color" name="label_color{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['label_color'+i]?.touched) && f.controls['label_color'+i]?.invalid">값을 입력해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.link" name="link{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['link'+i]?.touched) && f.controls['link'+i]?.invalid">값을 입력해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <a href="javascript:;" class="btn btn-danger m-btn m-btn--icon m-btn--icon-only mx-2"
                                   (click)="deleteItem(i)">
                                    <i class="fa fa-trash cc_pointer"></i>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    </div>
</div>
