<label class="m-checkbox">
    <input type="checkbox" [(ngModel)]="value"
           (ngModelChange)="valueChange.emit(value)"
            [disabled]="disabled"
    >
    <ng-content></ng-content>
    <div *ngIf="text" class="label" [class.disabled]="disabled">
        <img *ngIf="img" [src]="img"/>
        <span>{{text}}</span>
    </div>
    <span class="checkbox"></span>
</label>
