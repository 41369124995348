import { Component, Input, OnInit } from '@angular/core';
@Component({
    selector: "price-tag",
    templateUrl: "price-tag.component.html",
    styleUrls: ['./price-tag.component.scss'],

})
export class PriceTagComponent {
    constructor() { }

    @Input() size: number;
    @Input() type: string;
    @Input() amount: number | string;
    @Input() salePrice: number | string;
    @Input() additional: number | string;

    icon() {
        switch (this.type) {
            case 'price':
                return 'ic_force';

            case 'force':
                return 'ic_force_default_gray';

            case 'bonusForce':
                return 'ic_force_bonus_gray';

            case 'expires':
                return 'ic_force_bonus_red';

            default:
                return;
        }
    }

    isNumber = (val: number | string) => typeof val === 'number';
}
