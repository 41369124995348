import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GiftService } from "app/_services/force/gift.service";
declare let toastr: any;

@Component({
    selector: 'app-gift-upload',
    templateUrl: './gift-upload.component.html',
    styleUrls: ['./gift-upload.component.scss']
})
export class GiftUploadComponent implements OnInit {
    @Input()
    public active = false;
    @Output()
    public activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public onSuccess: EventEmitter<any> = new EventEmitter<any>();

    public uploadList = [];
    public forceChk = false;
    public isUploading = false;

    constructor(private giftService: GiftService) {
    }

    ngOnInit(): void {
    }

    close() {
        this.activeChange.emit(false);
    }

    successCsv({ response }) {
        this.uploadList = this.uploadList.concat(response);
        console.log(this.uploadList);
        this.forceChk = true;
    }

    addItem() {
        this.uploadList = [...this.uploadList, {
            type: 'item', releaseType: 'reserved', title: '', subtitle: ''
        }];
    }

    deleteItem(index: number) {
        this.uploadList = this.uploadList.filter((v, i) => i !== index);
    }


    upload() {
        this.isUploading = true;
        this.giftService.giftUpload(this.uploadList)
            .then(r => {
                toastr.success('업로드가 완료되었습니다.');
                this.onSuccess.emit();
                this.close();
            })
            .catch(e => {
                toastr.error(e.error.message);
            })
            .finally(() => {
                this.isUploading = false;
            })
    }


}
