import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import io from 'socket.io-client';
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";

@Component({
    selector: 'app-connect-user',
    templateUrl: 'connect.component.html',
    styleUrls: ['connect.component.scss']
})
export class ConnectComponent implements OnInit, OnDestroy {

    public ws;
    public user;
    public users = [];
    public current_url = this.router.url;

    constructor(private http: HttpClient,
        private router: Router) {

    }

    async ngOnInit() {
        this.ws = io(`wss://${location.hostname}:3020`);

        const { userInfo } = await this.http.get('admin/my/accounts').toPromise() as any;
        this.user = userInfo;

        this.ws.on('user joined', (data) => {
            this.users = data.users;
        })

        this.ws.on('user leave', (data) => {
            this.users = data.users;
        })

        this.ws.emit('add user', [this.current_url, `${this.user.name}(${this.user.email})`]);
    }

    ngOnDestroy() {
        this.ws.emit('user left', this.current_url);
        this.ws.close();
    }

}
