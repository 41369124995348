<div class="row mb-3">
  <label class="col-form-label col-12">
    {{label}}
    <small class="text-danger ml-1" *ngIf="required">*필수</small>
    <small class="text-danger ml-1" *ngIf="warningText">*{{warningText}}</small>
    <small *ngIf="maxLength" class="max-length mt-1"
           [class.text-danger]="value.length > maxLength">
      {{value.length}}/{{maxLength}}</small>
  </label>
  <div class="col-12">
    <input [type]="type || 'text'" class="form-control"
           [placeholder]="placeholder"
           [(ngModel)]="value" *ngIf="!disabled">
    <input [type]="type || 'text'" class="form-control" [value]="value" *ngIf="disabled" disabled>
  </div>
</div>
