import * as Raven from 'raven-js';
import { ErrorHandler, Injectable } from "@angular/core";
import { environment } from '../environments/environment';


@Injectable()
export class GlobalExceptionErrorHandler implements ErrorHandler {
    constructor() {

    }


    errorListMap: Map<string, Array<string>> = new Map();


    handleError(err: Error): void {
        console.log(err)

        if (err.message.includes('Error: Loading chunk')) {
            window.location.reload();
        }

        if (environment.production && this.registerError(err)) {
            Raven.captureException(err);
        }
    }



    // 같은 앱에서 한번 등록된 녀석한테는? 에러가 등록되지 않아욥
    registerError(err: Error) {
        let isReport: boolean = true;
        if (this.errorListMap.has(err.name)) {
            if (!this.errorListMap.get(err.name).find((em) => { return em == err.message })) {
                this.errorListMap.get(err.name).push(err.message)
            } else {
                isReport = false;
            }
        } else {
            this.errorListMap.set(err.name, [err.message])
        }
        return isReport

    }


}

