import { Injectable } from "@angular/core";
import { BaseAPIService } from "../base.api.service";
import { HttpClient } from "@angular/common/http";
import { AdBottomItemsAttribute, AdBottomItemTargetsAttribute, AdBottomsAttribute } from "../../../types/types";

@Injectable()
export class AdService extends BaseAPIService {
    private ROUTES = {
        list: '/ad',
        create: '/ad',
        update: '/ad',
        get: '/ad/:id',
        delete: '/ad/:id',

        item: '/ad/items/:id',
        items: '/ad/items',
        createItem: '/ad/items',
        updateItem: '/ad/items',
        deleteItems: '/ad/items/delete',

        fortuneItems: '/ad/items/mapping/fortunes',
        fortuneMappedItem: '/ad/items/mapping/:id',
        fortuneMappedItemUpdate: '/ad/items/mapping/:id',
        fortuneMapppedUpload: '/ad/items/upload',

        targets: '/ad/items/:id/targets'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    get(id: number) {
        return this.GET(this.ROUTES.get, { id }).toPromise();
    }

    create(bottom: AdBottomsAttribute) {
        return this.POST(this.ROUTES.create, null, { bottom }).toPromise();
    }

    update(bottom: AdBottomsAttribute) {
        return this.PATCH(this.ROUTES.update, null, { bottom }).toPromise();
    }

    delete(id: number) {
        return this.DELETE(this.ROUTES.delete, { id }).toPromise();
    }

    items(id: number) {
        return this.GET(this.ROUTES.items, { id }).toPromise();
    }

    item(id: number): Promise<AdBottomItemsAttribute> {
        return this.GET(this.ROUTES.item, { id }).toPromise();
    }

    getItemList() {
        return this.GET(this.ROUTES.items).toPromise();
    }

    targets(id: number): Promise<AdBottomItemTargetsAttribute[]> {
        return this.GET(this.ROUTES.targets, { id }).toPromise();
    }

    createItem(item: AdBottomItemsAttribute, targets: AdBottomItemTargetsAttribute[]) {
        return this.POST(this.ROUTES.createItem, null, { item, targets }).toPromise();
    }

    updateItem(item: AdBottomItemsAttribute, targets: AdBottomItemTargetsAttribute[], needTargetsUpdate = true) {
        return this.PATCH(this.ROUTES.updateItem, null, { item, targets, needTargetsUpdate }).toPromise();
    }

    deleteItems(ids: number[]) {
        return this.POST(this.ROUTES.deleteItems, null, { ids }).toPromise();
    }

    getFortuneMappedItems(id: number) {
        return this.GET(this.ROUTES.fortuneMappedItem, { id }).toPromise();
    }

    updateFortuneMappedItems(fortuneItemId: number, adBottomItemIds: number[]) {
        return this.PATCH(this.ROUTES.fortuneMappedItemUpdate, { id: fortuneItemId }, { adBottomItemIds }).toPromise();
    }

    uploadFortuneMappedItems(list: any[]) {
        return this.POST(this.ROUTES.fortuneMapppedUpload, null, { list }).toPromise();
    }

    fortuneItems() {
        return this.GET(this.ROUTES.fortuneItems).toPromise();
    }
}
