<div id="force-history-template">
  <header center-v jc-between>
    <div class="summary" center-v>
      <h4>보유 자산</h4>
      <price-tag size="20" type="force" [amount]="forceData.force"></price-tag>
      <price-tag size="20" type="bonusForce" [amount]="forceData.bonus"></price-tag>
      <button class="btn btn-outline-primary" type="button" data-toggle="modal" data-target="#balance_modal">만료 내역</button>
    </div>

    <select class="form-control" [(ngModel)]="categoryType" (change)="onCategoryChange()">
      <option [ngValue]="null"> 전체</option>
      <option [value]="'deposit'"> 충전</option>
      <option [value]="'withdrawal'"> 소비</option>
      <option [value]="'reward'"> 보상</option>
    </select>
  </header>

    <div *ngIf="transactions.length > 0" class="user-transactions position-relative" data-scrollbar-shown="true" data-scrollable="true">
        <div class="m-timeline-2">
            <div class="m-timeline-2__items m--padding-top-25 m--padding-bottom-30"
                 infinite-scroll [infiniteScrollThrottle]="150" [infiniteScrollDistance]="1" [infiniteScrollDisabled]="infiniteScrollDisabled"
                 (scrolled)="onScrollDown()">
                <div class="m-timeline-2__item min-height-50" *ngFor="let transaction of transactions">
                    <span class="m-timeline-2__item-time font-size-14" [ngClass]="{'valid-info': transaction.status == 'OK'}">
                        {{ transaction.created_at | date:'yyyy-MM-dd HH:mm:ss' }}
                        <small class="small-date" *ngIf="timeZone">{{ transaction.created_at_tz }}</small>
                        <small class="small-id">{{transaction.id}}</small>
                    </span>
                    <div class="m-timeline-2__item-cricle" [ngClass]="[transaction.type, transaction.status]">
                        <i class="fa fa-genderless "></i>
                    </div>
                    <div class="m-timeline-2__item-text m--padding-top-5 min-height-50">
                        <p [ngClass]="{'valid-action': transaction.status == 'OK'}">
                            {{transaction.koreanType}} <small> ({{transaction.device_type || transaction.ForceDeposits[0].device_type}}) </small>
                        </p>
                        <ng-container [ngSwitch]="transaction.type">
                            <!-- 보상 -->
                            <div *ngSwitchCase="'reward'" [ngClass]="{'valid-info': transaction.status == 'OK'}" class="force-info-text">
                                <div class="d-flex align-items-center">
                                    <span class="mr-1">- {{transaction.amount}} 보상 포스가 충전되었습니다.</span>
                                    <small class="mr-1" [ngClass]="{'expired-bonus': transaction.expired}"> {{transaction.expires_at | date:'만료일: yyyy-MM-dd HH:mm'}}</small>
                                    <small *ngIf="transaction.expired" class="balance">(-{{transaction.balance}}F)</small>
                                </div>
                                <p>- 보상 내용 : {{transaction.summary}}</p>
                            </div>
                            <!-- 소비 -->
                            <div *ngSwitchCase="'withdrawal'" [ngClass]="{'valid-info': transaction.status == 'OK'}" class="force-info-text">
                                <p>- {{transaction.amount}} 포스를 소진했습니다.</p>
                                <p>- 소비 아이템 : {{transaction.summary}}</p>
                                <p *ngIf="transaction.status =='CANCEL'">- 해당 소비는 <span class="color-orange"> 취소</span>되었습니다.</p>
                                <ng-container *ngIf="transaction.status == 'OK'">
                                    <button *ngIf="transaction.isAbleCancel" class="btn btn-outline-danger btn-sm mt-2 ml-1" type="button"
                                            (click)="openCancelModal(transaction)">
                                        취소 버튼
                                    </button>
                                    <button *ngIf="!transaction.isAbleCancel" class="btn btn-disabled btn-sm mt-2 ml-1" type="button">
                                        취소 불가
                                    </button>
                                </ng-container>
                                <button *ngIf="transaction.status === 'CANCEL'" class="btn btn-sm btn-outline-success mt-2 ml-1" type="button"
                                        (click)="openRecoverModal(transaction)">
                                    취소 정정 버튼
                                </button>
                            </div>
                            <!-- 충전 -->
                            <div *ngSwitchDefault [ngClass]="{'valid-info': transaction.status == 'OK'}" class="force-info-text">
                                <div *ngIf="transaction.type == 'purchase'">
                                    <ng-container *ngFor="let forceDeposit of transaction.ForceDeposits">
                                        <p *ngIf="forceDeposit.deposit_type == 'F'">- {{forceDeposit.amount}} 포스가 충전되었습니다.</p>
                                        <span *ngIf="forceDeposit.deposit_type == 'B'">- {{forceDeposit.amount}} 보너스포스가 충전되었습니다.</span>
                                        <small [ngClass]="{'expired-bonus': forceDeposit.expired}"> {{forceDeposit.expires_at | date:'만료일: yyyy-MM-dd HH:mm'}}</small>
                                        <small *ngIf="forceDeposit.expired" class="balance">(-{{forceDeposit.balance}}F)</small>
                                    </ng-container>
                                </div>
                                <div *ngIf="transaction.type == 'deposit'">
                                    <p *ngIf="transaction.deposit_type == 'F'">- {{transaction.amount}} 포스가 충전되었습니다.</p>
                                    <span *ngIf="transaction.deposit_type == 'B'">- {{transaction.amount}} 보너스포스가 충전되었습니다.</span>
                                    <small [ngClass]="{'expired-bonus': transaction.expired}"> {{transaction.expires_at | date:'만료일: yyyy-MM-dd HH:mm'}}</small>
                                    <small *ngIf="transaction.expired" class="balance">(-{{transaction.balance}}F)</small>
                                </div>
                                <p>- 구매 포스팩 : {{transaction.summary || transaction.Purchasable?.name}} <span *ngIf="transaction.Purchasable">({{transaction.Purchasable.store}})</span></p>
                                <p *ngIf="transaction.firstPurchase">- 첫 구매 보너스 적용</p>
                                <p>- Order Id: {{transaction.order_id || transaction.transaction_id || 'N/A'}}</p>
                                <p *ngIf="transaction.status =='REFUND'" class="force-info-text">- 해당 충전은 <span class="color-mediumblue"> 환불</span> 되었습니다.
                                </p>
                                <ng-container *ngIf="transaction.status == 'OK'">
                                    <button *ngIf="transaction.isAbleRefund" class="btn btn-sm btn-outline-success mt-2 ml-1" type="button"
                                            (click)="openRefundModal(transaction)">
                                        환불 버튼
                                    </button>
                                    <button *ngIf="!transaction.isAbleRefund " class="btn btn-sm btn-disabled mt-2 ml-1" type="button">
                                        환불 불가
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="transactions.length == 0">
        내역이 없습니다.
    </div>
</div>
<div class="modal fade" id="balance_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    <b>날짜별 만료된 포스</b>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <table class="table m-table text-center">
                            <thead class="thead-light m-datatable__head">
                                <tr>
                                    <th>날짜</th>
                                    <th>만료된 보너스포스</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let balance of expired_groupArr">
                                    <td>{{balance[0]}}</td>
                                    <td>{{((balance[0] | dateTime) < (now | dateTime)) ? '-' + balance[1] +'F 만료' : '-' + balance[1] +'F 예정'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="refund_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <b>정말 환불 하시겠습니까?</b>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <span class="m--font-danger">환불 실행 후 반드시 <b>구글 플레이 콘솔</b>에서 환불 처리하시기 바랍니다.</span>
                <div class="form-group m-form__group row">
                    <label class="col-form-label col-lg-12" for="refund-reason">
                        환불 사유
                    </label>
                    <div class="col-lg-12">
                        <input type="text" class="form-control m-input" id="refund-reason" placeholder="사유를 적어주세요." [(ngModel)]="refundOrCancelReasonMemo">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Close
                </button>
                <button type="button" (click)="refund()" class="btn btn-primary">
                    환불 실행
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="cancel_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <b>정말 취소 하시겠습니까?</b>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group m-form__group row">
                    <label class="col-form-label col-lg-12" for="cancel-reason">
                        취소 사유
                    </label>
                    <div class="col-lg-12">
                        <input type="text" class="form-control m-input" id="cancel-reason" placeholder="사유를 적어주세요." [(ngModel)]="refundOrCancelReasonMemo">
                    </div>
                </div>
                <p class="text-dark font-weight-bold">
                    반환되는 총 포스 : {{targetTransaction?.disableCancelAmount}}
                </p>
                <p class="text-dark font-weight-bold mt-2">사용된 포스</p>
                <p *ngFor="let detail of targetTransaction?.ForceWithdrawalDetails" class="target_detail">
                    <span>금액 : {{detail.amount}}</span>
                    <span>타입 : {{detail.ForceDeposit.deposit_type === 'B' ? '보너스' : '포스'}}</span>
                    <span *ngIf="detail.ForceDeposit.deposit_type === 'B'">만료일 : {{detail.ForceDeposit.expires_at | date:'yyyy-MM-dd HH:MM' }}</span>
                    <span>상태 : {{detail.ForceDeposit.status}}</span>
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Close
                </button>
                <button type="button" (click)="cancel()" class="btn btn-primary">
                    취소 실행
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="recover_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <b>정말 취소 정정 하시겠습니까?</b>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group m-form__group row">
                </div>
                <p class="text-dark font-weight-bold">
                    반환되는 총 포스 : {{targetTransaction?.amount}} {{targetTransaction?.bonus ? '보너스포스' : '포스'}}
                </p>
                <p class="text-dark font-weight-bold mt-2">사용된 포스</p>
                <p *ngFor="let detail of targetTransaction?.ForceWithdrawalDetails" class="target_detail">
                    <span>금액 : {{detail.amount}}</span>
                    <span>타입 : {{detail.ForceDeposit.deposit_type === 'B' ? '보너스' : '포스'}}</span>
                    <span *ngIf="detail.ForceDeposit.deposit_type === 'B'">만료일 : {{detail.ForceDeposit.expires_at | date:'yyyy-MM-dd HH:MM' }}</span>
                    <span>상태 : {{detail.ForceDeposit.status}}</span>
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Close
                </button>
                <button type="button" (click)="recover()" class="btn btn-primary">
                    취소 정정
                </button>
            </div>
        </div>
    </div>
</div>
