
<img src="./assets/icons/{{icon()}}.svg" [style.width]="(size - 2) + 'px'"/>

<div [style.font-size]="size + 'px'" center-v>
  <span [ngClass]="type" [class.overrided]="salePrice?.toString().length || additional">
    {{ amount | number }}
  </span>
  <span class="price" *ngIf="salePrice?.toString().length">
    {{ salePrice | number }}
  </span>
  <span class="additional" *ngIf="additional">
    {{ additional | number }}
  </span>
</div>
