import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PostsAttribute, ReservationsAttribute } from "types/types";
import { ReservationService } from "app/_services/reservation/reservation.service";
import * as moment from "moment";

declare let $: any;
declare let toastr: any;
import * as _ from 'lodash';

@Component({
    selector: "app-reservation-item",
    templateUrl: "reservation.item.component.html",
    styleUrls: ["reservation.item.component.scss"]
})
export class ReservationItemComponent implements OnInit {

    @Input()
    public itemId;
    @Input()
    public isSelectItem: Boolean = false;
    @Input()
    public selectItemList: PostsAttribute[] = [];
    @Input()
    public modalTitle = '아이템 예약';

    public selectedItemId;
    @Input()
    public type: string;
    public status: boolean = false;
    public reservationLogs: (ReservationsAttribute & { item_title?: string })[] = [];
    public reservationTime;
    public sortOrder = [
        'booked',
        'fulfill',
        'cancel',
        'error'
    ];
    constructor(private activeModal: NgbActiveModal,
        private reservationService: ReservationService) {
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.reservationService.get(this.type, this.itemId)
            .then(r => {
                this.reservationLogs = r.sort((a, b) => b.reservation_time - a.reservation_time)
                    .sort((a, b) => this.sortOrder.indexOf(a.status) - this.sortOrder.indexOf(b.status));
                if (this.isSelectItem) {
                    this.reservationLogs = this.reservationLogs.map(r => {
                        const item = _.find(this.selectItemList, { id: r.ref_id });
                        return { ...r, item_title: item ? item.title : '' };
                    });
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    ngAfterViewInit() {
        $('#reservation_datetimepicker').datetimepicker({
            todayHighlight: true,
            autoclose: true,
            pickerPosition: 'bottom-left',
            todayBtn: true,
            stepping: 1,
            format: 'yyyy-mm-dd hh:ii'
        });

        $(document).on('change', '#reservation_datetimepicker', (e) => {
            this.reservationTime = moment(e.target.value).format('YYYY-MM-DD HH:mm');
        });
    }

    close() {
        this.activeModal.close();
    }

    reservation() {
        if (!this.reservationTime) {
            toastr.warning('예약날짜를 확인해주세요.');
            return;
        }
        if (new Date(this.reservationTime).getTime() < new Date().getTime()) {
            toastr.warning('예약날짜가 과거입니다. 수정해주세요.');
            return;
        }
        const info = {
            type: this.type,
            task: (this.status ? 'on' : 'off'),
            ref_id: this.isSelectItem ? this.selectedItemId : this.itemId,
            reservation_time: new Date(this.reservationTime).getTime()
        }
        this.reservationService.group({ info })
            .then(() => this.getData())
            .catch(e => console.log(e));
    }

    cancelReservation(event, id) {
        event.stopImmediatePropagation();
        this.reservationService.cancel({ id: id, type: this.type }, {})
            .then(() => {
                this.getData();
            })
            .catch(e => {
                console.log(e);
            })
    }

    ngOnDestroy() {
        $('#reservation_datetimepicker').datetimepicker('remove');
    }

}
