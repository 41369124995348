import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class FatebookService extends BaseAPIService {

    private ROUTES = {
        updateStatus: '/user/fatebook/:fatebookId/:status',
        list: '/user/fatebook/detail/:user_id',
        getToken: '/user/token/:user_id',
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(user_id: number) {
        return this.GET(this.ROUTES.list, { user_id }).toPromise();
    }

    updateStatus(fatebookId: number, status: string): Promise<any> {
        return this.PATCH(this.inject(this.ROUTES.updateStatus, { fatebookId, status })).toPromise();
    }

    getToken(user_id: number) {
        return this.GET(this.ROUTES.getToken, { user_id }).toPromise();
    }
}
