
<div class="mt-4">
  <h4 class="d-inline-flex align-items-center justify-content-between">
    <div> Item Coupon </div>
  </h4>

  <!-- start ngIf -->
  <div *ngIf="couponHistories; else noCouponHistories" class="position-relative mt-4">
    <!-- start content -->
    <div class="m-timeline-2">
      <div class="m-timeline-2__items m--padding-top-25 m--padding-bottom-30">
        <!-- start ngFor-->
        <div class="m-timeline-2__item" *ngFor="let couponHistory of couponHistories" style="min-height: 50px">
          <span class="m-timeline-2__item-time" style="font-size: 14px;">
            {{ couponHistory.created_at  | date:'yyyy-MM-dd HH:mm:ss':'+0900' }}
            <small class="small-date" *ngIf="timeZone"> {{ couponHistory.created_at_tz }} </small>
          </span>
          <div class="m-timeline-2__item-cricle" [ngClass]="{'purchase-col': couponHistory.status=='MARK','cancel-col':couponHistory.status=='CMARK'}">
            <i class="fa fa-genderless "></i>
          </div>
          <div class="m-timeline-2__item-text m--padding-top-5" style="min-height: 50px">
            <b> {{ couponHistory.Purchase?.Purchasable.name || couponHistory.ForceWithdrawalDetails[0]?.ForceWithdrawal.summary }} </b> <br>
            <span *ngIf="couponHistory.Purchase"> <!-- Coupon_id link was added later thus, we must check for its existence -->
              - Serial: {{ couponHistory.Purchase.Coupon?.serial_number }} <br>
            </span>
            - 페이트북 ID: {{ couponHistory.ForceWithdrawalDetails[0]?.ForceWithdrawal[0]?.Fatebook?.id }}
            <!-- 취소 상태 문구 -->
            <span *ngIf="couponHistory.status=='CMARK'; else mark" class="force-info-text">
              <br> - 해당 쿠폰은 <span style="color:orange"> 취소</span>되었습니다.
            </span>
            <ng-template #mark>
              <!-- 취소 버튼 -->
              <div *ngIf="couponHistory.Purchase?.Coupon; else notCancel" class="m--padding-top-5">
                <button class="btn btn-outline-danger btn-sm" type="button" data-toggle="modal" data-target="#cancelCouponModal" (click)="selectCancelCoupon(couponHistory)">
                  취소 버튼
                </button>
              </div>
              <!-- 취소 불가능 문구 -->
              <ng-template #notCancel>
                <span class="force-info-text">
                  <br> - <span style="color:red"> 해당 쿠폰은 취소 할 수 없습니다 </span>
                </span>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div> <!-- end ngIf -->

  <!-- No Coupon Histories -->
  <ng-template #noCouponHistories>
    <p> 쿠폰 사용 내역이 업습니다. </p>
  </ng-template>

</div>

<!-- Coupon Cancel Modal Start -->
<div class="modal fade" id="cancelCouponModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">
          <b>정말 쿠폰을 취소 하시겠습니까?</b>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button type="button" (click)="cancelCoupon()" class="btn btn-primary">
          취소 실행
        </button>
      </div>
    </div>
  </div>
</div>
