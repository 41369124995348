import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';
import { AppSection, AppSectionList, AppSectionTarget } from 'types/types';

@Injectable()
export class SectionService extends BaseAPIService {

    private ROUTES = {
        list: '/app-section/list',
        order: '/app-section/order',
        create: '/app-section/new',
        get: '/app-section/:id',
        update: '/app-section/:id',
        delete: '/app-section/:id',
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<AppSectionList[]> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    get(id: number): Promise<AppSection> {
        return this.GET(this.ROUTES.get, { id }).toPromise();
    }

    order(list: { id: number; priority: number }[]) {
        return this.POST(this.ROUTES.order, null, { sections: list }).toPromise();
    }

    create(body): Promise<any> {
        return this.POST(this.ROUTES.create, null, body).toPromise();
    }

    update(body): Promise<any> {
        return this.PATCH(this.ROUTES.update, { id: body.id }, body).toPromise();
    }

    delete(id: number): Promise<void> {
        return this.DELETE(this.ROUTES.delete, { id }).toPromise();
    }
}
