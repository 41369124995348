<div class="m-portlet  m-portlet--full-height">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
                    1:1 문의 <span *ngIf="logs"> ( {{logs.length}} ) </span>
                </h3>
            </div>
        </div>
    </div>

    <div class="m-portlet__body">
        <div class="m-widget1 m-widget1--paddingless">
            <div class="m-accordion__item" *ngFor="let log of logs; let i = index" style="border-bottom: 1px solid #ddd">
                <div class="m-accordion__item-head collapsed- collapsed" role="tab" id="m_section_1_content_1_head" data-toggle="collapse" [attr.href]="'#m_section_' + i" aria-expanded="false">
                    <div class="m-widget1__item">
                        <div class="row m-row--no-padding align-items-center">
                            <div class="col">
                                <h3 class="m-widget1__title">{{log.title}}</h3>
                                <div style="color: grey; max-width: 500px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{log.detail}}</div>
                            </div>
                            <div class="col m--align-right">
                                <span class="m-widget1__number" style="font-size: 1.2em">{{log.created_at | date:'yyyy-MM-dd HH:mm':'+0900'}}&nbsp; </span> <i class="fa fa-angle-right"> </i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-accordion__item-body collapse" id="m_section_{{i}}" role="tabpanel" aria-labelledby="m_section_1_content_1_head" data-parent="#m_section_1_content">
                    <div class="m-accordion__item-content">
                        <p><b>문의 이메일:</b> {{log.email}} </p>
                        <p><b>내용:</b> {{log.detail}} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
