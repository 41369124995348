import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable()
export class AuthenticationService {

    constructor(
        private http: HttpClient,
        public router: Router
    ) {
    }


    logout() {
        // remove user from local storage to log user out
        // localStorage.removeItem('currentUser');
        this.http.get('logout').toPromise().then(r => {
            console.log(r)
            alert('성공적으로 로그아웃 되었습니다. ')
            this.router.navigateByUrl(this.router.createUrlTree(['login']))
            // this.socketService.disconnect()
        }).catch(e => {
            console.log(e)
            alert('로그아웃 실패 잠시 후 다시 시도하십시오.')
        })
    }
}
