import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class PromotionService extends BaseAPIService {

    private ROUTES = {
        promotionList: '/premium/promotion/list',
        bulk: '/premium/promotion/item/bulk',
        updatePromotion: 'premium/promotion/item',
        deletePromotion: 'premium/promotion/:id',
        deletePromotions: 'premium/promotion/delete',
        uploadPromotion: 'premium/promotion/upload'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    promotionList(): Promise<any> {
        return this.GET(this.ROUTES.promotionList).toPromise();
    }

    promotionBulk(body: any): Promise<any> {
        return this.http.post(this.ROUTES.bulk, body).toPromise();
    }

    updatePromotion(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.updatePromotion, body).toPromise();
    }

    deletePromotion(content: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.deletePromotion, content)).toPromise();
    }

    deletePromotions(promotionIds: any): Promise<any> {
        return this.http.post(this.ROUTES.deletePromotions, promotionIds).toPromise();
    }

    uploadPromotion(uploadList: any[]): Promise<any> {
        return this.POST(this.ROUTES.uploadPromotion, null, { uploadList }).toPromise();
    }
}
