<div class="modal-header">
    <h5 class="modal-title">
        {{modalTitle}}
    </h5>
    <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group row">
        <label class="col-form-label col-8">예약 시간</label>
        <label class="col-form-label col-4">활성</label>
        <div class="input-group date col-8">
            <input class="form-control" [(ngModel)]="reservationTime" id="reservation_datetimepicker"/>
            <div class="input-group-append">
                <span class="input-group-text"><i class="la la-calendar glyphicon-th"></i></span>
            </div>
        </div>
        <div class="input-group date col-4">
              <span class="m-switch m-switch--outline m-switch--primary">
                <label>
                    <input type="checkbox" [(ngModel)]="status"/>
                <span></span>
                </label>
              </span>
        </div>
    </div>
    <div *ngIf="isSelectItem" class="form-group">
        <label class="col-form-label">예약 아이템</label>
        <ng-select [items]="selectItemList"
                   bindLabel="bindLabel"
                   bindValue="id"
                   placeholder="ID 또는 제목으로 검색하세요"
                   [(ngModel)]="selectedItemId"
                    >
        </ng-select>
    </div>
    <div class="text-right">
        <button type="button" class="btn btn-primary btn-outline-info" (click)="reservation()">아이템 예약</button>
    </div>
    <div class="row">
        <label class="col-form-label col-12">예약 로그</label>
        <div class="col-12">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th *ngIf="isSelectItem">아이템 번호</th>
                        <th *ngIf="isSelectItem">아이템 이름</th>
                        <th class="m--font-success">시간</th>
                        <th>명령</th>
                        <th colspan="2">상태</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let log of reservationLogs" [class.opacity-4]="log.status === 'cancel'">
                        <td *ngIf="isSelectItem">{{log.ref_id}}</td>
                        <td *ngIf="isSelectItem">{{log.item_title}}</td>
                        <td class="m-list-badge__label w-40">{{log.reservation_time | date: 'yyyy-MM-dd HH:mm' :'+0900'}}</td>
                        <td class="w-20">{{log.task == 'on' ? '활성화' : '비활성화' }}</td>
                        <td class="w-20">{{log.status == 'booked' ? '예약됨' : log.status == 'fulfill' ? '실행됨' : log.status == 'cancel' ? '취소됨' : '실행안됨'}}</td>
                        <td class="w-20"><a class="m-list-badge__item cancel-btn" *ngIf="log.status=='booked'"
                                            (click)="cancelReservation($event, log.id)">취소하기</a></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
