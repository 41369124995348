import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class CommunityService extends BaseAPIService {

    private ROUTES = {
        reportedPosts: '/cs/community/report',
        undoPosts: '/cs/community/report/undo',
        reportPosts: '/cs/community/report'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    reportedPosts(): Promise<any> {
        return this.GET(this.ROUTES.reportedPosts).toPromise();
    }

    undoPost(type, typeId): Promise<any> {
        return this.PATCH(this.ROUTES.undoPosts, null, { type, typeId }).toPromise();
    }

    reportPost(type, typeId): Promise<any> {
        return this.POST(this.ROUTES.reportPosts, null, { type, typeId }).toPromise();
    }
}
