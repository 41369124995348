import { Pipe, PipeTransform } from '@angular/core';

/*
 * Enables the key/value pair of an item to be
 * visible within an ngFor
 * Usage:
 *   value of iteratable | keyValue
 * Example:
 * <ul>
 *   <li *ngFor='key of demo | keyValue'>
 *   Key: {{key.key}}, value: {{key.value}}
 *   </li>
 * </ul>
 * https://gist.github.com/bombadillo/b6bfe0386d8483d0c0f1eb81566f2f34 출처
*/
@Pipe({ name: 'dateTime', pure: true })
export class DateTimePipe implements PipeTransform {
    transform(value: any, format = 'mediumDate', timezone?: string, locale?: string): number | null {
        if (value == null || value === '' || value !== value) return null;
        return new Date(value).getTime();
    }
}
