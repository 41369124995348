import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class EtcService extends BaseAPIService {

    private ROUTES = {
        location: '/geoname/location',
        searchCity: '/geoname/city'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    location(cityId): Promise<any> {
        return this.GET(this.ROUTES.location, null, { params: { city_id: cityId } }).toPromise();
    }

    searchCity(cityName): Promise<any> {
        return this.GET(this.ROUTES.searchCity, null, { params: { city: cityName } }).toPromise();
    }



}
