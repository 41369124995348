import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChange } from '@angular/core';
import { AuthService } from 'ng2-ui-auth';
declare let Dropzone: any;
@Component({
    selector: 'app-celeb-batch-uploader',
    templateUrl: 'celeb-batch-uploader.component.html',
})

export class CelebBatchUploaderComponent implements OnInit {

    public dropzone;
    @Input('uploaderId') uploaderId: string;
    @Input('isInstantlyLive') isInstantlyLive: boolean;
    @Output() public successCsv = new EventEmitter();
    @Output() public errorCsv = new EventEmitter();

    constructor(private auth: AuthService) { }

    ngOnInit() {
    }

    preventEvent($event: MouseEvent) {
        $event.preventDefault();
    }

    ngOnChanges(changes: SimpleChange): void {
        if (this.dropzone) {
            this.dropzone.options.headers = {
                'isInstantlyLive': this.isInstantlyLive + '',
                'Authorization': `Bearer ${this.auth.getToken()}`
            }
        }
    }

    ngAfterViewInit() {
        this.dropzone = new Dropzone(`#${this.uploaderId}`, {
            url: '/api/upload/celeb/v2',
            paramName: "file", // The name that will be used to transfer the file
            maxFiles: 1,
            maxFilesize: 5, // MB
            addRemoveLinks: true,
            timeout: 0,
            dictRemoveFile: '파일 삭제',
            accept: function(file, done) {
                done();
            },
            sending: (file, xhr, formData) => {

            },
            success: (result) => {
                setTimeout(() => {
                    this.dropzone.files.forEach(file => {
                        this.dropzone.removeFile(file)
                    })
                }, 1000);
                this.successCsv.emit(result)
            },
            error: (file, errorMessage, xhr) => {
                setTimeout(() => {
                    this.dropzone.files.forEach(file => {
                        this.dropzone.removeFile(file)
                    })
                }, 1000);
                if (xhr) {
                    this.errorCsv.emit(xhr.getResponseHeader("x-error-message"))
                } else {
                    this.errorCsv.emit(errorMessage)
                }
            },
        })
        this.dropzone.options.headers = {
            'isInstantlyLive': this.isInstantlyLive + '',
            'Authorization': `Bearer ${this.auth.getToken()}`
        }
    }

}
