import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';
import { premiumGroup, PremiumItemRecommendationsAttribute } from 'types/types';

@Injectable()
export class PremiumService extends BaseAPIService {
    private ROUTES = {
        list: '/premium/list/v2',
        listActive: '/premium/list/active',
        listSimple: '/premium/list/simple',
        groupList: '/premium/group/list',
        groupOrder: '/premium/group/order',
        groupEdit: '/premium/group',
        groupGet: '/premium/group/:id',
        recommend: '/premium/id/recommendation/list',
        get: '/premium/:id/v3',
        edit: '/premium/id/v3',
        chapterListById: '/chapter/premium/:id/list',
        chapterList: '/chapter/list',
        allTagList: '/premium/tags/all',
        leafletPromotion: '/premium/list/leaflet/promotion',
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    get(id: number) {
        return this.GET(this.ROUTES.get, { id: id }).toPromise();
    }

    create(item, purchasable) {
        return this.POST(this.ROUTES.edit, null, { item, purchasable }).toPromise();
    }

    update(item, chapters, recommendations, purchasable) {
        return this.PATCH(this.ROUTES.edit, null, {
            item,
            chapters,
            recommendations,
            purchasable,
        }).toPromise();
    }

    delete(groupId: number) {
        return this.http
            .delete(this.inject(this.ROUTES.groupGet, { id: groupId }))
            .toPromise();
    }

    chapterListById(premiumItemId: number) {
        return this.GET(this.ROUTES.chapterListById, {
            id: premiumItemId,
        }).toPromise();
    }

    chapterList() {
        return this.GET(this.ROUTES.chapterList).toPromise();
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    listActive(): Promise<any> {
        return this.GET(this.ROUTES.listActive).toPromise();
    }

    listSimple(): Promise<any> {
        return this.GET(this.ROUTES.listSimple).toPromise();
    }

    groupList(
        list?: boolean,
        excludeTypes: string[] = [],
    ): Promise<premiumGroup[]> {
        const opt =
            list !== undefined
                ? { params: { list: list ? '1' : '0', excludeTypes } }
                : {};
        return this.GET(this.ROUTES.groupList, null, opt).toPromise();
    }

    groupOrder(list: any[]): Promise<void> {
        const groupIds = list.map((l) => l.id);
        return this.POST(this.ROUTES.groupOrder, null, { groupIds }).toPromise();
    }

    groupGet(id: string): Promise<premiumGroup> {
        return this.GET(this.ROUTES.groupGet, { id }).toPromise();
    }

    createGroup(
        group: premiumGroup,
        keywords: string[] | undefined,
        etc: { [key: string]: any },
    ): Promise<premiumGroup> {
        return this.POST(this.ROUTES.groupEdit, null, {
            group,
            keywords,
            etc,
        }).toPromise();
    }

    updateGroup(
        group: premiumGroup,
        keywords: string[] | undefined,
        etc: { [key: string]: any },
    ): Promise<premiumGroup> {
        return this.PATCH(this.ROUTES.groupEdit, null, {
            group,
            keywords,
            etc,
        }).toPromise();
    }

    recommend(id: number): Promise<PremiumItemRecommendationsAttribute[]> {
        return this.GET(this.ROUTES.recommend, null, {
            params: { premiumItemId: id },
        }).toPromise();
    }

    allTagList() {
        return this.GET(this.ROUTES.allTagList).toPromise();
    }

    leafletPromotion() {
        return this.GET(this.ROUTES.leafletPromotion).toPromise();
    }
}
