import { Component, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import * as momentTz from 'moment-timezone';

declare let moment: any;
declare let mApp: any;

@Component({
    selector: "bokchae-history-list",
    templateUrl: "bokchae-history-list.component.html",
    styleUrls: ['./bokchae-history-list.component.scss'],

})
export class BokchaeHistoryListComponent implements OnChanges {
    @Input() timeZone;

    public userId;
    public bokchaeHistories: any = [];
    public selectedCoupon: any;

    constructor(
        private routeParam: ActivatedRoute,
        private http: HttpClient
    ) { }

    ngOnChanges() {
        this.routeParam.params.subscribe(data => {
            this.userId = data.id;
            this.getData(this.userId);
        });
    }

    getData(userId) {
        this.http
            .get(`/bokchae/list?userId=${userId}`)
            .toPromise()
            .then(res => {
                this.bokchaeHistories = res;
                this.bokchaeHistories.forEach(bokchaeHistory => {
                    if (this.timeZone) bokchaeHistory.created_at_tz = momentTz.tz(bokchaeHistory.created_at, this.timeZone).format('YYYY-MM-DD HH:mm:ss');
                    return;
                })
            })
            .catch(e => {
                console.log(e);
            });
    }

    convertTime(time?: string) {
        return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }

    blockApp() {
        mApp.block(`#content-body`, {
            overlayColor: '#000000',
            type: 'loader',
            state: 'primary',
            message: '요청하신 작업을 처리 중입니다...',
            css: {
                padding: 0,
                margin: 0,
                width: '30%',
                top: '40%',
                left: '35%',
                textAlign: 'center',
                color: '#000',
                border: '3px solid #aaa',
                backgroundColor: '#fff',
                cursor: 'wait'
            }
        });
    }

}
