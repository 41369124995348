<div class="modal" [class.active]="active">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="csvPromotionsModalLabel">
                    <b>포스트 생성</b>
                </h5>
                <button type="button" class="close" (click)="close()">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="d-flex align-items-center justify-content-between m-2">
                        <h6>CSV 양식 샘플</h6>
                        <button class="btn btn-primary" type="button" (click)="download()">샘플 다운로드</button>
                    </div>
                    <table class="table table-striped m-table">
                        <thead class="m-datatable__head thead-light">
                        <tr>
                            <th></th>
                            <th>type</th>
                            <th>subtitle</th>
                            <th>title</th>
                            <th>firstValue</th>
                            <th>secondValue</th>
                            <th>releaseType</th>
                            <th>releaseStart</th>
                            <th>releaseEnd</th>
                            <th>fixedPosition</th>
                            <th>presetId</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span class="m-badge m-badge--secondary m-badge--wide">풀이미지</span></td>
                            <td>item</td>
                            <td>5번의 기회!</td>
                            <td>슬롯머신 한번 돌려볼까요?</td>
                            <td>http://image.png</td>
                            <td>http://link.com</td>
                            <td>reserved</td>
                            <td>2025-01-01 00:00:00</td>
                            <td>2025-12-31 59:59:59</td>
                            <td>1</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td><span class="m-badge m-badge--secondary m-badge--wide">기프트</span></td>
                            <td>gift</td>
                            <td>공자 선생님이 봐주시는</td>
                            <td>특별한 애정운이에요</td>
                            <td>10</td>
                            <td>2025-01-01 00:00:00</td>
                            <td>everyday</td>
                            <td>00:00:00</td>
                            <td>15:00:00</td>
                            <td>2</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td><span class="m-badge m-badge--secondary m-badge--wide">썸네일</span></td>
                            <td>thumbnail</td>
                            <td>이번 주 신규 출시된</td>
                            <td>따끈따끈한 신규 운세예요</td>
                            <td>p203|i302|s1023|i220</td>
                            <td>http://image.png</td>
                            <td>everyday</td>
                            <td>00:00:00</td>
                            <td>15:00:00</td>
                            <td>3</td>
                            <td>10</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <app-ctsv-uploader
                            class="app-content-body-max-width"
                            apiUrl="/api/upload/csv"
                            uploaderId="ctsv-uploader"
                            (successCsv)="successCsv($event)">
                    </app-ctsv-uploader>
                </div>
                <div class="my-2 d-flex justify-content-between">
                    <button class="btn btn-primary" [disabled]="!uploadList.length || !f.form.valid || isUploading" form="uploadForm">해당 리스트 업로드</button>
                    <a class="btn btn-outline-info m-btn m-btn--icon  m-btn--icon-only m-btn--pill m-btn--air"
                       href="javascript:;" (click)="addItem()">
                        <i class="la la-plus"></i>
                    </a>
                </div>
                <form id="uploadForm" #f="ngForm" (ngSubmit)="upload()">
                    <table class="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline">
                        <thead style="background: #ebedf2" class="m-datatable__head">
                        <tr>
                            <th style="width: 120px">
                                타입
                            </th>
                            <th>
                                부제목
                            </th>
                            <th>
                                제목
                            </th>
                            <th>
                                값1
                            </th>
                            <th>
                                값2
                            </th>
                            <th style="width: 100px">
                                활성타입
                            </th>
                            <th>
                                시작시간
                            </th>
                            <th>
                                종료시간
                            </th>
                            <th style="width: 70px">
                                고정슬롯
                            </th>
                            <th style="width: 70px">
                                <small>타겟<br/>세그먼트id</small>
                            </th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of uploadList; let i = index" class="banner-area-list-row">
                            <td class="form-group has-danger">
                                <select class="form-control" [(ngModel)]="item.type" name="type{{i}}" required>
                                    <option value="item">풀이미지</option>
                                    <option value="gift">기프트</option>
                                    <option value="thumbnail">썸네일</option>
                                </select>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['type'+i]?.touched) && f.controls['type'+i]?.invalid">값을 입력해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.subtitle" name="subtitle{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['subtitle'+i]?.touched) && f.controls['subtitle'+i]?.invalid">값을 입력해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.title" name="title{{i}}" required>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['title'+i]?.touched) && f.controls['title'+i]?.invalid">값을 입력해주세요</div>
                            </td>
                            <ng-container *ngIf="item.type === 'item'">
                                <td class="form-group has-danger"><!-- 값1 -->
                                    <input type="text" class="form-control" [(ngModel)]="item.firstValue" name="firstValue{{i}}" placeholder="이미지" required>
                                    <div class="form-control-feedback" *ngIf="(forceChk || f.controls['firstValue'+i]?.touched) && f.controls['firstValue'+i]?.invalid">잘못된 값입니다</div>
                                </td>
                                <td class="form-group has-danger"><!-- 값2 -->
                                    <input type="text" class="form-control" [(ngModel)]="item.secondValue" name="secondValue{{i}}" placeholder="이미지URL"  required>
                                    <div class="form-control-feedback" *ngIf="(forceChk || f.controls['secondValue'+i]?.touched) && f.controls['secondValue'+i]?.invalid">잘못된 값입니다</div>
                                </td>
                            </ng-container>
                            <ng-container *ngIf="item.type === 'gift'">
                                <td class="form-group has-danger"><!-- 값1 -->
                                    <input type="text" class="form-control" [(ngModel)]="item.firstValue" name="firstValue{{i}}" placeholder="할인권id" required>
                                    <div class="form-control-feedback" *ngIf="(forceChk || f.controls['firstValue'+i]?.touched) && f.controls['firstValue'+i]?.invalid">잘못된 날짜 값입니다</div>
                                </td>
                                <td class="form-group has-danger"><!-- 값2 -->
                                    <input type="text" class="form-control" [(ngModel)]="item.secondValue" [pattern]="dateTimeRegex"
                                           name="secondValue{{i}}" placeholder="유효기간" required>
                                    <div class="form-control-feedback" *ngIf="(forceChk || f.controls['secondValue'+i]?.touched) && f.controls['secondValue'+i]?.invalid">잘못된 날짜 값입니다</div>
                                </td>
                            </ng-container>
                            <ng-container *ngIf="item.type === 'thumbnail'">
                                <td class="form-group has-danger"><!-- 값1 -->
                                    <input type="text" class="form-control" [(ngModel)]="item.firstValue" name="firstValue{{i}}" placeholder="id값(,로 분리)"
                                           pattern="[p|i|s]\d+(\|[p|i|s]\d+){0,}" required>
                                    <div class="form-control-feedback" *ngIf="(forceChk || f.controls['firstValue'+i]?.touched) && f.controls['firstValue'+i]?.invalid">값을 확인해주세요</div>
                                </td>
                                <td class="form-group"><!-- 값2 -->
                                    <input type="text" class="form-control" [(ngModel)]="item.secondValue" name="secondValue{{i}}" placeholder="헤더이미지">
                                </td>
                            </ng-container>
                            <td class="form-group has-danger">
                                <select class="form-control" [(ngModel)]="item.releaseType" name="releaseType{{i}}" required>
                                    <option value="reserved">예약</option>
                                    <option value="everyday">매일</option>
                                </select>
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['releaseType'+i]?.touched) && f.controls['releaseType'+i]?.invalid">값을 입력해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.releaseStart" name="releaseStart{{i}}"
                                       required [pattern]="item.releaseType === 'everyday' ? timeRegex : dateTimeRegex">
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['releaseStart'+i]?.touched) && f.controls['releaseStart'+i]?.invalid">잘못된 시간 값입니다</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.releaseEnd" name="releaseEnd{{i}}"
                                       required [pattern]="item.releaseType === 'everyday' ? timeRegex : dateTimeRegex">
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['releaseEnd'+i]?.touched) && f.controls['releaseEnd'+i]?.invalid">잘못된 시간 값입니다</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.fixedPosition" name="fixedPosition{{i}}" pattern="[1-5]{1}">
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['fixedPosition'+i]?.touched) && f.controls['fixedPosition'+i]?.invalid">잘못된 숫자 값입니다</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.presetId" name="presetId{{i}}">
                                <div class="form-control-feedback" *ngIf="(forceChk || f.controls['presetId'+i]?.touched) && f.controls['presetId'+i]?.invalid">잘못된 숫자 값입니다</div>
                            </td>
                            <td class="form-group has-danger">
                                <a href="javascript:;" class="btn btn-danger m-btn m-btn--icon m-btn--icon-only mx-2"
                                   (click)="deleteItem(i)">
                                    <i class="fa fa-trash cc_pointer"></i>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    </div>
</div>
