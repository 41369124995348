import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output,
    QueryList, SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import flatpickr from "flatpickr";
import * as moment from "moment";
import * as momentTz from 'moment-timezone';
import { environment } from "environments/environment";

declare var toastr: any;

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, AfterViewInit, OnChanges {

    @Input('value')
    set setValue(value) {
        if (value !== undefined) {
            this.value = value;
        }
    }
    public value: string | number = '';
    @Input('secondValue')
    set setSecondValue(value) {
        if (value !== undefined) {
            this.secondValue = value;
        }
    }
    public secondValue: string | number = '';
    @Output()
    public valueChange: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    public secondValueChange: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public type: 'date' | 'time' | 'both' = 'both';
    @Input()
    public isUtc: boolean = false;
    @Input()
    public range: boolean = false;
    @Output()
    public dataChange: EventEmitter<any> = new EventEmitter<any>();
    @Input()
    public isMilliSecond: boolean = false;
    @Input()
    public firstLabel: string;
    @Input()
    public secondLabel: string;
    @Input()
    public disabled: boolean = false;
    @Input()
    public readonly: boolean = false;

    public flatpickrs = [];
    public timeFormat = '';
    public initialized: boolean = false;

    @ViewChildren('date') dateInputs: QueryList<ElementRef>;

    constructor() {
    }

    ngAfterViewInit() {
        this.initDatepicker();
    }

    initDatepicker() {
        if (this.flatpickrs.length > 0 || !this.dateInputs) {
            return;
        }
        this.dateInputs.forEach((d, i) => {
            const valueType = i == 0 ? 'value' : 'secondValue';
            if (this.type == 'time') {
                this.initFlatpicker(d.nativeElement, valueType);
            } else {
                this.initFlatpicker(d.nativeElement, valueType);
            }
        });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.initialized) {
            return;
        }
        if (this.value !== '') {
            this.initValue(this.type, 'value');
        }
        if (this.secondValue !== '') {
            this.initValue(this.type, 'secondValue');
        }
    }

    initValue(valueType, dataType) {
        if (valueType === 'time') {
            this.initTimeValue(dataType);
        } else {
            this.initDateValue(dataType);
        }
        setTimeout(() => this[`${dataType}Change`].emit(this[dataType]), 0);
        this.initialized = true;
    }

    initTimeValue(type) {
        let today = moment().startOf('day').valueOf();
        let time = this[type];
        time = !isNaN(time) ? moment(time + today) : moment(today);
        this[type] = !time || !time._isValid ? '' : time.format('HH:mm:ss');
    }

    initDateValue(type) {
        let time = this[type];
        let timeFormat = this.type == 'both' ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
        if (this.isUtc) {
            time = momentTz.tz(time, environment.timezone || "Asia/Seoul");
        } else if (this.isMilliSecond) {
            time = time ? moment(time) : null;
        } else {
            time = moment(time, timeFormat);
        }
        this[type] = !time ? '' : (!time._isValid ? '' : time.format(timeFormat));
    }

    initFlatpicker(element, valueType) {
        let option = {};
        if (this.type == 'date') {
            option = {
                allowInput: !this.readonly,
                defaultHour: 0,
                dateFormat: 'Y-m-d',
                onChange: (selectedDates, dateStr, instance) => {
                    this[valueType] = dateStr;
                    this[`${valueType}Change`].emit(dateStr);
                }
            }
        } else if (this.type == 'time') {
            option = {
                allowInput: !this.readonly,
                noCalendar: true,
                enableTime: true,
                enableSeconds: true,
                dateFormat: 'H:i:S',
                onChange: (selectedDates, dateStr, instance) => {
                    this[valueType] = dateStr;
                    this[`${valueType}Change`].emit(dateStr);
                }
            }
        } else if (this.type == 'both') {
            option = {
                allowInput: !this.readonly,
                time_24hr: true,
                enableTime: true,
                enableSeconds: true,
                defaultHour: 0,
                dateFormat: 'Y-m-d H:i:S',
                onChange: (selectedDates, dateStr, instance) => {
                    this[valueType] = dateStr;
                    this[`${valueType}Change`].emit(dateStr);
                }
            }
        }
        let flat = flatpickr(element, option);
        this.flatpickrs.push(flat);
    }

    ngOnDestroy() {
        this.flatpickrs.forEach(f => {
            f.destroy();
        })
    }

    validate(): boolean {
        let first = this.dateInputs.first;
        let second = this.dateInputs.last;
        if (!first || !first.nativeElement.offsetParent) {
            return false;
        }
        if (this.range) {
            if (this.validateDate(this.value)) {
                first.nativeElement.focus();
                return true;
            }
            if (this.validateDate(this.secondValue)) {
                second.nativeElement.focus();
                return true;
            }
            if (this.value > this.secondValue) {
                toastr.warning(`시작일시와 끝나는일시를 확인해주세요.`);
                second.nativeElement.focus();
                return true;
            }
        } else {
            if (this.validateDate(this.value)) {
                first.nativeElement.focus();
                return true;
            }
        }
        return false;
    }

    validateDate(value) {
        if (!value) {
            toastr.warning(`일시를 확인해주세요.`);
            return true;
        }
        return false;
    }
}
