import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'form-textarea',
    templateUrl: 'textarea.component.html',
    styleUrls: ['textarea.component.scss']
})
export class TextareaComponent {

    @Input() label = '';
    @Input() placeholder = '';
    @Input() maxLength: number | undefined;
    @Input() required = false;

    @Output() valueChange = new EventEmitter<string>();

    _value;

    constructor() {
    }

    @Input()
    get value(): string {
        return this._value;
    }

    set value(val: string) {
        this._value = val;
        this.valueChange.emit(this._value);
    }

}
