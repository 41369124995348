import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class CelebService extends BaseAPIService {

    private ROUTES = {
        list: '/celeb/list',
        listWithTag: '/celeb/list/tag',
        listWithProfile: '/celeb/list/profile',
        getCeleb: '/celeb/:id',
        editCeleb: '/celeb/id',
        getTheme: '/celeb/theme/:id',
        editTheme: '/celeb/theme/id',
        themes: '/celeb/theme/list',
        celebrityFriendSync: '/celeb/friend/count'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(order?: 'asc' | 'desc'): Promise<any> {
        return this.GET(this.ROUTES.list, null, { params: { order: order || 'asc' } }).toPromise();
    }

    listWithTag(): Promise<any> {
        return this.GET(this.ROUTES.listWithTag).toPromise();
    }

    listWithProfile(order?: 'asc' | 'desc'): Promise<any> {
        return this.GET(this.ROUTES.listWithProfile, null, { params: { order: order || 'asc' } }).toPromise();
    }

    themeGet(context: any): Promise<any> {
        return this.GET(this.ROUTES.getTheme, context).toPromise();
    }

    themeCreate(body: any): Promise<any> {
        return this.http.post(this.ROUTES.editTheme, body).toPromise();
    }

    themeUpdate(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.editTheme, body).toPromise();
    }

    themeList(): Promise<any> {
        return this.GET(this.ROUTES.themes).toPromise();
    }

    getCeleb(context: any): Promise<any> {
        return this.GET(this.ROUTES.getCeleb, context).toPromise();
    }

    create(body: any): Promise<any> {
        return this.http.post(this.ROUTES.editCeleb, body).toPromise();
    }

    update(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.editCeleb, body).toPromise();
    }

    delete(context: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.getCeleb, context)).toPromise();
    }

    celebrityFriendSync(): Promise<any> {
        return this.http.get(this.ROUTES.celebrityFriendSync).toPromise();
    }
}
