<div class="modal-header">
    <h5 class="modal-title">
        포스트 활성 일시 끌올 예약
    </h5>
    <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group row">
        <label class="col-form-label col-12">예약 시간</label>
        <div class="input-group date col-12">
            <input class="form-control"
                   id="reservation_datetimepicker"
                   value="{{(startDate ) | date:'yyyy-MM-dd HH:mm:ss':'+0900'}} ~ {{(endDate ) | date:'yyyy-MM-dd HH:mm:ss':'+0900'}}"/>
            <div class="input-group-append">
                <span class="input-group-text"><i class="la la-calendar glyphicon-th"></i></span>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-form-label col-12">기간</label>
        <div class="input-group date col-4">
            <input type="number" class="form-control" [(ngModel)]="intervalMinute"><span class="ml-2 pt-2">분</span>
        </div>
    </div>
    <div class="text-right">
        <button type="button" class="btn btn-primary btn-outline-info" (click)="reservation()">아이템 예약</button>
    </div>
    <div class="row">
        <label class="col-form-label col-12">예약 로그</label>
        <div class="col-12">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="m--font-success">시간</th>
                        <th colspan="2">상태</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let log of reservationLogs" [class.opacity-4]="log.status === 'cancel'">
                        <td class="m-list-badge__label w-40">{{log.reservation_time | date: 'yyyy-MM-dd HH:mm' :'+0900'}}</td>
                        <td class="w-20">{{log.status == 'booked' ? '예약됨' : log.status == 'fulfill' ? '실행됨' : log.status == 'cancel' ? '취소됨' : '실행안됨'}}</td>
                        <td class="w-20"><a class="m-list-badge__item cancel-btn" *ngIf="log.status=='booked'"
                                            (click)="cancelReservation($event, log.id)">취소하기</a></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
