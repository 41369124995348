import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { groupItem, premiumGroup, ReservationsAttribute } from "types/types";
import { ReservationService } from "app/_services/reservation/reservation.service";
import * as _ from "lodash";
import * as moment from "moment";

declare let $: any;
declare let toastr: any;

@Component({
    selector: "app-reservation",
    templateUrl: "reservation.component.html",
    styleUrls: ["reservation.component.scss"]
})
export class ReservationComponent implements OnInit {

    @Input()
    public groupId;

    @Input()
    public reservationItems: groupItem[] = [];

    @Input()
    public searchItems: groupItem[] = [];

    @Input()
    public type: string;

    public reservationLogs: ReservationsAttribute[] = [];
    public reservationLogItems = [];
    public logMap: any;

    public reservationTime;

    public sortOrder = [
        'booked',
        'fulfill',
        'cancel',
        'error'
    ];
    constructor(private activeModal: NgbActiveModal,
        private reservationService: ReservationService) {
    }

    ngOnInit() {
        this.logMap = _.keyBy(this.reservationItems.concat(this.searchItems), l => `${l.type}${l.id}`);
        this.getData();
    }

    getData() {
        this.reservationService.get(this.type, this.groupId)
            .then(r => {
                this.reservationLogs = r.sort((a, b) => b.reservation_time - a.reservation_time)
                    .sort((a, b) => this.sortOrder.indexOf(a.status) - this.sortOrder.indexOf(b.status));
            })
            .catch(e => {
                console.log(e);
            })
    }

    ngAfterViewInit() {
        $('#reservation_datetimepicker').datetimepicker({
            todayHighlight: true,
            autoclose: true,
            pickerPosition: 'bottom-left',
            todayBtn: true,
            stepping: 1,
            format: 'yyyy-mm-dd hh:ii'
        });

        $(document).on('change', '#reservation_datetimepicker', (e) => {
            this.reservationTime = moment(e.target.value).format('YYYY-MM-DD HH:mm');
        });
    }

    close() {
        this.activeModal.close();
    }

    reservation() {
        if (new Date(this.reservationTime).getTime() < new Date().getTime()) {
            toastr.warning('예약날짜가 과거입니다. 수정해주세요.');
            return;
        }
        let rItems = this.reservationItems;
        const info = {
            type: 'p_group',
            task: 'update',
            ref_id: this.groupId,
            reservation_time: new Date(this.reservationTime).getTime(),
            param: rItems.map((r, i) => ({ id: r.id, type: r.type, priority: i }))
        }
        this.reservationService.group({ info })
            .then(() => this.getData())
            .catch(e => console.log(e));
    }

    showDetail(log) {
        this.reservationLogItems = JSON.parse(log.param) || [];
        this.reservationLogItems.forEach(l => {
            const key = `${l.type}${l.id}`;
            if (this.logMap[key]) {
                l.title = this.logMap[key].title;
                l.status = this.logMap[key].status;
            }
        });
    }

    selectChange(event) {
        if (event) {
            this.reservationItems.unshift(event);
            _.remove(this.searchItems, i => (i.id == event.id && i.type == event.type));
            this.searchItems = [...this.searchItems];
        }
    }

    drop(event: CdkDragDrop<premiumGroup[]>) {
        moveItemInArray(this.reservationItems, event.previousIndex, event.currentIndex);
    }

    deleteItem(i) {
        let reservationItem = this.reservationItems.splice(i, 1)[0];
        reservationItem.bindLabel = reservationItem.status === 'S3' || reservationItem.status === 'D2'
            ? `[${reservationItem.type === 'premium' ? '프리미엄' : '그룹'} ${reservationItem.id}] - ${reservationItem.title}`
            : `[${reservationItem.type === 'premium' ? '프리미엄' : '그룹'} ${reservationItem.id}] - [비활성] ${reservationItem.title}`;
        this.searchItems = [...this.searchItems, reservationItem];
    }

    deleteAllItem() {
        const saveReservationItems = this.reservationItems
        this.reservationItems = []
        saveReservationItems.forEach((item, index) => {
            item.bindLabel = item.status === 'S3' || item.status === 'D2'
                ? `[${item.type === 'premium' ? '프리미엄' : '그룹'} ${item.id}] - ${item.title}`
                : `[${item.type === 'premium' ? '프리미엄' : '그룹'} ${item.id}] - [비활성] ${item.title}`;
            this.searchItems = [...this.searchItems, item]
        })
    }

    cancelReservation(event, id) {
        event.stopImmediatePropagation();
        this.reservationService.cancel({ id: id, type: this.type }, {})
            .then(() => {
                this.getData();
            })
            .catch(e => {
                console.log(e);
            })
    }

    ngOnDestroy() {
        $('#reservation_datetimepicker').datetimepicker('remove');
    }

}
