import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'ng2-ui-auth';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { premiumGroup } from "../../../../types/types";
import { DragulaService } from "ng2-dragula";

declare let Dropzone: any;
declare let swal: any;

@Component({
    selector: 'app-multi-drag-image-uploader',
    templateUrl: 'multi-drag-image-uploader.component.html',
    styleUrls: ['multi-drag-image-uploader.component.scss']
})

export class MultiDragImageUploaderComponent implements OnInit {

    public dropzone;
    @Input('innerImageSrcs') innerImageSrcs: string[] = [];
    @Input('uploaderId') uploaderId: string;
    @Output() innerImageSrcsChange: EventEmitter<any> = new EventEmitter<any>();
    public srcModal = false;
    public inputSrc = '';

    public failImage = 'assets/custom/sahwa-upload-fail.png';

    constructor(private auth: AuthService,
        private dragulaService: DragulaService) {
        this.dragulaService.drop('srcItem').subscribe(r => {
            this.innerImageSrcsChange.emit(this.innerImageSrcs);
        })
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.dropzone = new Dropzone(`#${this.uploaderId}`, {
            url: '/api/upload/multiV2',
            paramName: "file", // The name that will be used to transfer the file
            maxFiles: 100,
            maxFilesize: 100, // MB
            addRemoveLinks: true,
            dictRemoveFile: '파일 삭제',
            acceptedFiles: "image/*",
            headers: { 'Authorization': `Bearer ${this.auth.getToken()}` },
            accept: function(file, done) {
                done();
            },
            sending: function(file, xhr, formData) {
                formData.append("extra", true);
            },
            success: (result) => {
                const fileInfo = {
                    fileName: result.name,
                    image_url: JSON.parse(result.xhr.response).files.fileURL
                };
                this.innerImageSrcs.push(fileInfo.image_url);
                this.innerImageSrcsChange.emit(this.innerImageSrcs);
                this.dropzone.removeFile(result);
            },
            complete: (result) => {
                this.dropzone.removeFile(result);
            },
            error: (err) => {
                console.log(err)
                setTimeout(() => {
                    this.dropzone.files.forEach(file => {
                        this.dropzone.removeFile(file)
                    })
                }, 1000);
                this.innerImageSrcs.push(this.failImage);
                this.innerImageSrcsChange.emit(this.innerImageSrcs);
            },
        })
    }

    deleteImage(event, index: number) {
        event.preventDefault();
        event.stopPropagation();
        swal({
            title: '정말 삭제하시겠습니까?',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: '취소',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.innerImageSrcs.splice(index, 1);
                this.innerImageSrcsChange.emit(this.innerImageSrcs);
            }
        });

    }

    ngOnDestroy() {
        if (this.dropzone) {
            this.dropzone.destroy();
        }
    }

    addSrc() {
        this.innerImageSrcs.push(this.inputSrc);
        this.innerImageSrcsChange.emit(this.innerImageSrcs);
    }

    openSrcModal(type: boolean) {
        event.stopPropagation();
        this.srcModal = type;
        if (type) {
            document.querySelector('body').classList.add('overflow-hidden');
        } else {
            document.querySelector('body').classList.remove('overflow-hidden');
        }
    }

    onDrop() {
        console.log('on drop~~')
    }
}
