import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { IndexComponent } from './index/index.component';
import { LayoutModule } from './index/layouts/layout.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from "./_services/script-loader.service";
import { IndexRoutingModule } from "./index/index-routing.module";
import { AuthModule } from "./auth/auth.module";
import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { AppState } from './redux/root.reducer';
import { createAppStore } from './redux/app.store';
import { GlobalExceptionErrorHandler } from './global-exception.handler';
import { Ng2UiAuthModule } from 'ng2-ui-auth';
import { UIComponentsModule } from './ui-components/ui-components.module';
import { ServiceModule } from "./_services/service.module";

import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}


@NgModule({
    declarations: [
        IndexComponent,
        AppComponent,
    ],
    imports: [
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        IndexRoutingModule,
        AuthModule,
        NgReduxModule,
        UIComponentsModule,
        Ng2UiAuthModule.forRoot({
            providers: {
                google: {
                    scope: ['email', 'profile'],
                    url: 'auth/google/callback',
                    // additionalUrlParams: { hd: 'un7qi3.co' },
                    clientId: '368878522246-dbjsiq68mcmgf5rno5dlm5gsq6jg5cjt.apps.googleusercontent.com'
                }
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ServiceModule
    ],
    providers: [
        ScriptLoaderService,
        { provide: ErrorHandler, useClass: GlobalExceptionErrorHandler },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(ngRedux: NgRedux<AppState>) {
        ngRedux.provideStore(createAppStore());
    }

}
