

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-page-index',
    template: `<router-outlet></router-outlet>`
})

export class PageIndexComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}