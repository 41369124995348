<div class="row mb-3">
  <label class="col-form-label col-12">
    {{label}}
    <small class="text-danger ml-1" *ngIf="required">*필수</small>
    <small *ngIf="maxLength" class="max-length mt-1"
           [class.text-danger]="value.length > maxLength">
      {{value.length}}/{{maxLength}}</small>
  </label>
  <div class="col-12">
    <textarea [placeholder]="placeholder" [(ngModel)]="value"
              class="form-control" width="100%" height="100%" data-provide="markdown" rows="20"></textarea>
  </div>
</div>
