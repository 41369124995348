import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AdBottomItemsAttribute, AdBottomItemTargetsAttribute, FortuneItemsAttribute } from "../../../../../types/types";
import { AdService } from "../../../../_services/fortune-item/ad.service";
import { ResultSheetService } from "../../../../_services/fortune-item/result-sheet.service";

import * as _ from "lodash";

declare let toastr: any;

@Component({
    selector: 'app-fortune-ad-item-edit-modal',
    templateUrl: './fortune-ad-item-edit-modal.component.html',
    styleUrls: ['./fortune-ad-item-edit-modal.component.scss']
})
export class FortuneAdItemEditModalComponent implements OnInit {

    @Input()
    set itemId(id: number) {
        this.isNew = false;
        this._itemId = id;
        this.getData();
    }
    _itemId: number;
    @Input()
    set bottomId(id: number) {
        this.item.adBottomId = id;
    }
    @Input()
    targets: adBottomItemTargetSelect[] = [];
    @Input()
    categories = [];
    @Input()
    fortuneItems: FortuneItemsAttribute[] = [];
    @Output()
    submit: EventEmitter<any> = new EventEmitter<any>();

    item: AdBottomItemsAttribute = { title: '', type: 'TEXT', status: 'S1', info: { text: '', image: '', link: '', imageLink: '' } };
    isNew: boolean = true;
    keys: string[] = [];
    fortuneKeys = {};
    targetsStringified: string;

    constructor(
        private activeModal: NgbActiveModal,
        private adService: AdService,
        private resultSheetService: ResultSheetService
    ) { }

    ngOnInit(): void {
    }

    close() {
        this.activeModal.close();
    }

    async getData() {
        let { adBottomItemTargets: targets, ...item } = await this.adService.item(this._itemId);
        this.item = item;
        const groupedTargets = _.uniqBy(targets, t => t.type + t.itemId + t.categoryId);
        _.forEach(_.groupBy(targets.filter(t => t.type === 'KEY'), 'itemId'), (value, groupItemId) => {
            groupedTargets.forEach(t => {
                if (t.itemId === +groupItemId) {
                    t.keys = value.map(v => v.key);
                }
            })
        });
        this.targets = groupedTargets;
        this.targetsStringified = JSON.stringify(this.targets);
    }

    async changeTargetType(target: AdBottomItemTargetsAttribute) {
        if (target.type === 'KEY' && !target.keys) {
            target.keys = [];
        }
    }

    async getFortuneResultKeys(id) {
        if (!this.fortuneKeys[id]) {
            this.fortuneKeys[id] = _.cloneDeep(this.keys);
            return await this.resultSheetService.getItemSheet({ id });
        } else {
            return _.cloneDeep(this.fortuneKeys[id]);
        }
    }

    addTarget() {
        this.targets.push({ type: 'DEFAULT' });
    }

    deleteTarget(index) {
        this.targets.splice(index, 1);
    }

    async save() {
        console.log(this.item)
        let list = [];
        let keyList = [];
        const adBottomItemId = this.item.id;
        this.targets.forEach(t => {
            const { id, keys, ...rest } = t;
            if (t.type !== 'KEY') {
                list.push({ ...rest, adBottomItemId })
            } else if (t.keys) {
                keyList = keyList.concat(t.keys.map(key => ({ ...rest, key, adBottomItemId })));
            }
        })

        if (!this.item.startedAt || !this.item.endedAt) {
            this.item.startedAt = null;
            this.item.endedAt = null;
        }

        if (this.isNew) {
            await this.adService.createItem(this.item, list.concat(keyList));
        } else {
            const newTargetsStringified = JSON.stringify(this.targets);
            const needTargetsUpdate = this.targetsStringified !== newTargetsStringified;
            await this.adService.updateItem(this.item, list.concat(keyList), needTargetsUpdate);
        }
        toastr.success('저장이 완료되었습니다.');
        this.submit.emit();
        this.close();
    }

}

interface adBottomItemTargetSelect extends AdBottomItemTargetsAttribute {
    keys?: string[]
}
