<div class="modal-header">
    <h5 class="modal-title">
        {{item?.id ? item.id + '. 하단 광고 아이템 수정' : '하단 광고 아이템 생성' }}
    </h5>
    <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="mb-4">
        <h5 class="mb-4">기본 정보</h5>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label>제목</label>
                    <input type="text" class="form-control" [(ngModel)]="item.title"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>타입</label>
                    <select class="form-control" [(ngModel)]="item.type">
                        <option value="TEXT">텍스트</option>
                        <option value="IMAGE">이미지</option>
                        <option value="TEXTIMAGE">텍스트이미지</option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <div class="custom-radio btn-group-toggle" data-toggle="buttons">
                    <label class="d-block mb-2">활성화</label>
                    <label class="btn btn-secondary m-btn--custom mr-2" (click)="item.status = 'S3'"
                           [ngClass]="{'active' : item.status == 'S3'}">
                        <input type="radio" name="type" autocomplete="off"/> 활성
                    </label>
                    <label class="btn btn-secondary m-btn--custom mr-2" (click)="item.status = 'S2'"
                           [ngClass]="{'active' : item.status == 'S2'}">
                        <input type="radio" name="type" autocomplete="off"/> admin 활성
                    </label>
                    <label class="btn btn-secondary m-btn--custom" (click)="item.status = 'S1'"
                           [ngClass]="{'active' : item.status == 'S1'}">
                        <input type="radio" name="type" autocomplete="off"/> 비활성
                    </label>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="item.type !== 'IMAGE'">
            <label>문구</label>
            <input type="text" class="form-control" [(ngModel)]="item.info.text">
        </div>
        <div class="form-group">
            <label>링크</label>
            <input type="text" class="form-control" [(ngModel)]="item.info.link">
        </div>
        <div class="form-group" *ngIf="item.type !== 'TEXT'">
            <label>이미지</label>
            <app-image-uploader
                    uploaderId="adBottomItemImage"
                    [(innerImageSrc)]="item.info.image">
            </app-image-uploader>
            <div class="form-group" *ngIf="item.type === 'TEXTIMAGE' || item.type === 'TARGETING'">
                <label>이미지 링크</label>
                <input type="text" class="form-control" [(ngModel)]="item.info.imageLink">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <app-datepicker firstLabel="활성시작"
                                secondLabel="활성종료"
                                [(value)]="item.startedAt"
                                [(secondValue)]="item.endedAt"
                                [isUtc]="true"
                                [range]="true"
                                type="both"
                ></app-datepicker>
            </div>
        </div>
    </div>
    <div>
        <h5 class="mb-2">타겟</h5>
        <div class="my-2 d-flex justify-content-between">
            <!--            <button class="btn btn-primary" [disabled]="false">해당 리스트 업로드</button>-->
            <span></span>
            <a class="btn btn-outline-info m-btn m-btn--icon  m-btn--icon-only m-btn--pill m-btn--air"
               href="javascript:;" (click)="addTarget()">
                <i class="la la-plus"></i>
            </a>
        </div>
        <div class="target-list">
            <table class="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline">
                <thead style="background: #ebedf2" class="m-datatable__head">
                <tr>
                    <th style="width: 47%;">
                        타입
                    </th>
                    <th style="width: 47%;">
                        값
                    </th>
                    <th style="width: 5%;">
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr class="banner-area-list-row" *ngFor="let target of targets; let index = index;">
                    <td class="form-group">
                        <select class="form-control" [(ngModel)]="target.type"
                                (ngModelChange)="changeTargetType(target)">
                            <option value="KEY">무료운세 키</option>
                            <option value="ITEM">무료운세</option>
                            <option value="CATEGORY">카테고리</option>
                            <option value="DEFAULT">전체설정</option>
                        </select>
                    </td>
                    <td class="form-group">
                        <div *ngIf="target.type !== 'DEFAULT'" style="display: flex;gap: 10px;flex-flow: column;">
                            <ng-container *ngIf="['ITEM', 'KEY'].includes(target.type)"
                                          [ngTemplateOutlet]="fortuneItemSelect"
                                          [ngTemplateOutletContext]="{target}"
                            ></ng-container>
                            <app-tag *ngIf="target.type === 'KEY'"
                                     [(value)]="target.keys"
                                     placeholder="아이템 키를 입력 후 엔터를 눌러주세요."
                            ></app-tag>
                            <select class="form-control" *ngIf="target.type === 'CATEGORY'" [(ngModel)]="target.categoryId">
                                <option value="">카테고리선택</option>
                                <option [value]="category.id" *ngFor="let category of categories">{{category.id}}
                                    -{{category.name}}</option>
                            </select>
                        </div>
                    </td>
                    <td class="form-group">
                        <a href="javascript:;" class="btn btn-danger m-btn m-btn--icon m-btn--icon-only mx-2"
                           (click)="deleteTarget(index)">
                            <i class="fa fa-trash cc_pointer"></i>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
            <ng-template #fortuneItemSelect let-target="target">
                <select class="form-control" [(ngModel)]="target.itemId">
                    <option value="">아이템선택</option>
                    <option [value]="item.id" *ngFor="let item of fortuneItems">{{item.id}}-{{item.title}}</option>
                </select>
            </ng-template>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="close()">
        취소
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        저장
    </button>
</div>
