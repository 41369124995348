
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { User } from "../_models";

@Injectable()
export class UserService {
    constructor(private http: HttpClient) {
    }

    verify() {
        return this.http.get('/api/verify', { headers: this.jwt() }).pipe(map((response: Response) => response.json()));
    }

    forgotPassword(email: string) {
        return this.http.post('/api/forgot-password', JSON.stringify({ email }), { headers: this.jwt() }).pipe(map((response: Response) => response.json()));
    }

    getAll() {
        return this.http.get('/api/users', { headers: this.jwt() }).pipe(map((response: Response) => response.json()));
    }

    getById(id: number) {
        return this.http.get('/api/users/' + id, { headers: this.jwt() }).pipe(map((response: Response) => response.json()));
    }

    create(user: User) {
        return this.http.post('/api/users', user, { headers: this.jwt() }).pipe(map((response: Response) => response.json()));
    }

    update(user: User) {
        return this.http.put('/api/users/' + user.id, user, { headers: this.jwt() }).pipe(map((response: Response) => response.json()));
    }

    delete(id: number) {
        return this.http.delete('/api/users/' + id, { headers: this.jwt() }).pipe(map((response: Response) => response.json()));
    }

    // private helper methods

    private jwt() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            return new HttpHeaders({ 'Authorization': 'Bearer ' + currentUser.token });
        }
    }
}
