import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'ng2-ui-auth';
import { premiumGroup } from "../../../../types/types";

declare let Dropzone: any;
declare let swal: any;

@Component({
    selector: 'app-multi-image-uploader',
    templateUrl: 'multi-image-uploader.component.html',
    styleUrls: ['multi-image-uploader.component.scss']
})

export class MultiImageUploaderComponent implements OnInit {

    public dropzone;
    @Input('uploaderId') uploaderId: string;
    @Output() public successImageUrl = new EventEmitter();
    @Input('innerImageSrc') innerImageSrc: string = '';
    public srcModal = false;
    public inputSrc = '';

    public failImage = 'assets/custom/sahwa-upload-fail.png';

    constructor(private auth: AuthService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.dropzone = new Dropzone(`#${this.uploaderId}`, {
            url: '/api/upload/multiV2',
            paramName: "file", // The name that will be used to transfer the file
            maxFiles: 100,
            maxFilesize: 100, // MB
            addRemoveLinks: true,
            dictRemoveFile: '파일 삭제',
            acceptedFiles: "image/*",
            headers: { 'Authorization': `Bearer ${this.auth.getToken()}` },
            accept: function(file, done) {
                done();
            },
            sending: function(file, xhr, formData) {
                formData.append("extra", true);
            },
            success: (result) => {
                const fileInfo = {
                    fileName: result.name,
                    image_url: JSON.parse(result.xhr.response).files.fileURL
                };
                this.successImageUrl && this.successImageUrl.emit(fileInfo);
                this.dropzone.removeFile(result);
            },
            complete: (result) => {
                this.dropzone.removeFile(result);
            },
            error: (err) => {
                console.log(err)
                setTimeout(() => {
                    this.dropzone.files.forEach(file => {
                        this.dropzone.removeFile(file)
                    })
                }, 1000);
                this.successImageUrl.emit(this.failImage);
            },
        })
    }

    ngOnDestroy() {
        if (this.dropzone) {
            this.dropzone.destroy();
        }
    }

    addSrc() {
        this.successImageUrl.emit(
            {
                fileName: this.inputSrc,
                image_url: this.inputSrc
            }
        );
        this.dropzone.removeFile(this.inputSrc);
    }

    openSrcModal(type: boolean) {
        event.stopPropagation();
        this.srcModal = type;
        if (type) {
            document.querySelector('body').classList.add('overflow-hidden');
        } else {
            document.querySelector('body').classList.remove('overflow-hidden');
        }
    }
}
