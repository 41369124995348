<div class="row mb-3">
  <label class="col-form-label col-12">
    {{label}}
    <small class="text-danger ml-1" *ngIf="required">*필수</small>
  </label>
  <div class="col-12">
    <ng-select [(ngModel)]="value"
               [placeholder]="placeholder"
               [items]="items"
               [bindLabel]="bindLabel"
               [bindValue]="bindValue"
               [clearable]="false"
               [searchable]="false"
               (change)="change.emit(value)"
               [disabled]="disabled"
    >
    </ng-select>
  </div>
</div>
