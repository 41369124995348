import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class FasService extends BaseAPIService {

    private ROUTES = {
        list: '/clients/list',
        edit: '/clients/id'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    get(id): Promise<any> {
        return this.GET(this.ROUTES.edit, null, { params: { id } }).toPromise();
    }

    create(item): Promise<any> {
        return this.POST(this.ROUTES.edit, null, { item }).toPromise();
    }

    update(item): Promise<any> {
        return this.PATCH(this.ROUTES.edit, null, { item }).toPromise();
    }


}
