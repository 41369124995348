import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    public cssMinify(text) {
        return text.replace(/\s{1,}/g, ' ')
            .replace(/\{\s{1,}/g, "{")
            .replace(/\}\s{1,}/g, "}")
            .replace(/\;\s{1,}/g, ";")
            .replace(/\/\*\s{1,}/g, "/*")
            .replace(/\*\/\s{1,}/g, "*/");
    }

    public cssBeautify(text) {
        let shift = ['\n'];
        let step = '  ';

        for (let ix = 0; ix < 100; ix++) {
            shift.push(shift[ix] + step);
        }
        let ar = text.replace(/\s{1,}/g, ' ')
            .replace(/\{/g, "{~::~")
            .replace(/\}/g, "~::~}~::~")
            .replace(/\;/g, ";~::~")
            .replace(/\/\*/g, "~::~/*")
            .replace(/\*\//g, "*/~::~")
            .replace(/~::~\s{0,}~::~/g, "~::~")
            .split('~::~'),
            len = ar.length,
            deep = 0,
            str = '';

        for (let ix = 0; ix < len; ix++) {

            if (/\{/.exec(ar[ix])) {
                str += shift[deep++] + ar[ix];
            } else if (/\}/.exec(ar[ix])) {
                str += shift[--deep] + ar[ix];
            } else if (/\*\\/.exec(ar[ix])) {
                str += shift[deep] + ar[ix];
            } else {
                str += shift[deep] + ar[ix];
            }
        }
        return str.replace(/^\n{1,}/, '');
    }

    public static convertByte(bytes: number, decimals: number) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals;
        const sizes = ['Bytes', 'KB', 'MB']
        const selectSize = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, selectSize)).toFixed(dm)) + ' ' + sizes[selectSize]
    }

    public randomKeyGenerator(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*_+';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    public jsonFormatter(json: string) {
        const TOKEN_BRACE_OPEN = 2;
        const TOKEN_BRACE_CLOSE = 3;
        const TOKEN_BRACKET_OPEN = 4;
        const TOKEN_BRACKET_CLOSE = 5;
        const TOKEN_COMMA = 6;
        const TOKEN_COLON = 7;

        const tokens = this.tokenizeJSONString(json || 'null', { filterWS: true, filterUnknown: true });
        const numTokens = tokens.length;
        const output = [];
        const indentStack = [];
        const indentIncr = '    ';
        let indent = '';
        let index = 0;
        let prevTkn = null;

        while (index < numTokens) {
            var tkn = tokens[index];
            var nextTkn = tokens[index + 1];
            switch (tkn.type) {
                case TOKEN_BRACE_CLOSE:
                case TOKEN_BRACKET_CLOSE:
                    if (indentStack.length > 0) {
                        indent = indentStack.pop();
                    }

                    if (prevTkn && prevTkn.type != TOKEN_BRACE_OPEN && prevTkn.type != TOKEN_BRACKET_OPEN) {
                        output.push(indent);
                    }

                    output.push(tkn.value);

                    if (nextTkn && nextTkn.type != TOKEN_COMMA) {
                        output.push('\n');
                    }
                    break;
                case TOKEN_BRACE_OPEN:
                case TOKEN_BRACKET_OPEN:
                    var openIndent = indent;
                    if (prevTkn && prevTkn.type === TOKEN_COLON) {
                        openIndent = '';
                    }

                    output.push(openIndent + tkn.value);

                    if (nextTkn && nextTkn.type != TOKEN_BRACE_CLOSE && nextTkn.type != TOKEN_BRACKET_CLOSE) {
                        output.push('\n');
                    }

                    indentStack.push(indent);
                    indent += indentIncr;
                    break;
                case TOKEN_COLON:
                    output.push(tkn.value + ' ');
                    break;
                case TOKEN_COMMA:
                    output.push(tkn.value + '\n');
                    break;
                default:
                    var tknIndent = indent;

                    if (prevTkn && prevTkn.type === TOKEN_COLON) {
                        tknIndent = '';
                    }
                    output.push(tknIndent + tkn.value);
                    if (!nextTkn || (nextTkn.type != TOKEN_COLON && nextTkn.type != TOKEN_COMMA)) {
                        output.push('\n');
                    }
                    break;
            }

            ++index;

            prevTkn = tkn;
        }

        return output.join('');
    }

    private tokenizeJSONString(json: string, options: any) {
        const jsonTokenRegExp = /\s+|\{|\}|\[|\]|,|true|false|null|:|(?:"(?:\\\\|\\"|[^"])*")|(?:-?(?:0|[1-9][0-9]*)(?:\.[0-9]+)?(?:[Ee][+-]?[0-9]+)?)/g;

        const TOKEN_UNKNOWN = 0;
        const TOKEN_WHITESPACE = 1;
        const TOKEN_BRACE_OPEN = 2;
        const TOKEN_BRACE_CLOSE = 3;
        const TOKEN_BRACKET_OPEN = 4;
        const TOKEN_BRACKET_CLOSE = 5;
        const TOKEN_COMMA = 6;
        const TOKEN_COLON = 7;
        const TOKEN_TRUE = 8;
        const TOKEN_FALSE = 9;
        const TOKEN_NULL = 10;
        const TOKEN_STRING = 11;
        const TOKEN_NUMBER = 12;

        const charToTokenType = {
            ' ': TOKEN_WHITESPACE,
            '\t': TOKEN_WHITESPACE,
            '\r': TOKEN_WHITESPACE,
            '\n': TOKEN_WHITESPACE,
            '{': TOKEN_BRACE_OPEN,
            '}': TOKEN_BRACE_CLOSE,
            '[': TOKEN_BRACKET_OPEN,
            ']': TOKEN_BRACKET_CLOSE,
            ',': TOKEN_COMMA,
            ':': TOKEN_COLON,
            't': TOKEN_TRUE,
            'f': TOKEN_FALSE,
            'n': TOKEN_NULL,
            '"': TOKEN_STRING,
            '-': TOKEN_NUMBER,
            '0': TOKEN_NUMBER,
            '1': TOKEN_NUMBER,
            '2': TOKEN_NUMBER,
            '3': TOKEN_NUMBER,
            '4': TOKEN_NUMBER,
            '5': TOKEN_NUMBER,
            '6': TOKEN_NUMBER,
            '7': TOKEN_NUMBER,
            '8': TOKEN_NUMBER,
            '9': TOKEN_NUMBER
        };

        const tokens = [];
        const filterWS = options && options.filterWS;
        const filterUnknown = options && options.filterUnknown;
        let result = jsonTokenRegExp.exec(json);

        while (result) {
            const type = charToTokenType[result[0].charAt(0)] || TOKEN_UNKNOWN;

            if ((!filterWS || type != TOKEN_WHITESPACE) && (!filterUnknown || type != TOKEN_UNKNOWN)) {
                tokens.push({
                    type: type,
                    value: result[0]
                });
            }

            result = jsonTokenRegExp.exec(json);
        }

        return tokens;
    }
}
