import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class FreeForceTemplatesService extends BaseAPIService {

    private ROUTES = {
        list: '/freeforce/template',
        save: '/freeforce/template',
        get: '/freeforce/template/:id',
        update: '/freeforce/template/:id',
        delete: '/freeforce/template/:id'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    get(id: any): Promise<any> {
        return this.GET(this.ROUTES.get, { id }).toPromise();
    }

    save(template): Promise<any> {
        return this.POST(this.ROUTES.save, null, { template }).toPromise();
    }

    update(id: any, template: any): Promise<any> {
        return this.PATCH(this.ROUTES.update, { id }, { template }).toPromise();
    }

    delete(id: any): Promise<any> {
        return this.DELETE(this.ROUTES.delete, { id }).toPromise();
    }
}
