import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class DailyFortuneService extends BaseAPIService {

    private ROUTES = {
        monthlyFortunes: '/dailyfortune/monthly/:userId/:yyyymm',
        deleteMonthlyFortune: '/dailyfortune/monthly/:itemId',
        deleteMonthlyFortuneAll: '/dailyfortune/monthly/all/:userId',
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    monthlyFortunes(userId: number, yyyymm: string | number): Promise<any> {
        return this.GET(this.ROUTES.monthlyFortunes, { userId, yyyymm }).toPromise();
    }

    deleteMonthlyFortune(itemId: number): Promise<any> {
        return this.DELETE(this.ROUTES.deleteMonthlyFortune, { itemId }).toPromise();
    }

    deleteMonthlyFortuneAll(userId: number): Promise<any> {
        return this.DELETE(this.ROUTES.deleteMonthlyFortuneAll, { userId }).toPromise();
    }
}
