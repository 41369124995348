import { Component, OnChanges, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import * as momentTz from 'moment-timezone';

@Component({
    selector: "gift-list",
    templateUrl: "gift-list.component.html",
    styleUrls: ['./gift-list.component.scss']

})
export class GiftListComponent implements OnChanges {
    @Input() timeZone;

    public userId;
    public notifications: any[] = [];
    public userGifts: any[] = [];

    constructor(
        private routeParam: ActivatedRoute,
        private http: HttpClient
    ) { }

    ngOnChanges() {
        this.routeParam.params.subscribe(data => {
            this.userId = data.id;
            this.getData(this.userId);
        });
    }
    getData(userId) {
        this.http
            .get(`gift/user/${userId}`)
            .toPromise()
            .then((res: any) => {
                this.userGifts = res;
                this.userGifts.forEach(userGift => {
                    if (this.timeZone) {
                        userGift.created_at_tz = momentTz.tz(userGift.created_at, this.timeZone).format('YYYY-MM-DD HH:mm:ss');
                        if (userGift.expires_at) userGift.expires_at_tz = momentTz.tz(userGift.expires_at, this.timeZone).format('YYYY-MM-DD HH:mm:ss');
                    }
                })
            })
            .catch(e => {
                console.log(e);
            });
    }

}
