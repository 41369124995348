import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { TargetAttribute } from '../../../../types/types';
import * as _ from "lodash";
import { VersionService } from "../../../_services/live/version.service";
import { CityService } from "../../../_services/live/city.service";

declare var $: any;

@Component({
    selector: 'app-target',
    templateUrl: './target.component.html',
    styleUrls: ['./target.component.scss']
})
export class TargetComponent implements OnInit, OnChanges {

    @Input()
    useTargetList: string[] = [];
    @Input()
    defaultTargetList: string[] = [];
    @Input('target')
    public __target = null;
    @Output() public modalOpen = new EventEmitter();

    public customTarget = [];

    public target = null;


    public _target: TargetAttribute = {
        version: { start: '0.0.0', end: '255.255.255' },
        userIds: [],
        signup: {},
        daysAfterSignup: null,
        daysPassAfterSignup: null,
        dayOfWeeks: [],
        genders: [],
        birth: {},
        hmUnsure: false,
        animals: [],
        originalAnimals: [],
        zodiacs: [],
        total: {},
        force: {},
        bonus: {},
        lastPurchaseDate: {},
        fatebookCount: {},
        lastLoginDate: {},
        fortuneItemIds: [],
        fortuneItemNoIds: [],
        fortuneItemTags: [],
        fortuneItemCategories: [],
        fortuneItemRecord: {
            day: null,
            ids: []
        },
        premiumItemIds: [],
        premiumItemNoIds: [],
        notSeenPremiumItemIds: [],
        premiumItemRecord: {
            day: null,
            ids: []
        },
        friendCount: {},
        friendAddCount: {},
        friendInviteCount: {},
        friendCelebCount: {},
        canSlotMachine: false,
        chulcheck: false,
        push: false,
        anonymous: false,
        chargeRecord: {},
        withdrawalRecord: {},
        periodWithdrawalRecord: {},
        birthday: false,
        dailyScore: {},
        giftIds: [],
        notUsedGiftIds: [],
        existGiftIds: [],
        hasNotUsedGift: false,
        purchaseDate: {},
        notPurchaseDate: {},
        purchaseCount: {},
        isWelcomeUser: false,
        isLuckyLuckUser: false,
        isFirstPackageUser: false,
        isCheckTime: false,
        levels: [],
        jpLevels: [],
        timezone: {},
        hasCharged: false,
        daysAfterLastCharge: {},
        hasPremiumItem: false,
        hasFortuneItem: false,
        hasVoucher: false,
        hasPremiumItemIn30Day: false,
        daysAfterLastPremium: null,
        daysWithinLastPremium: null,
        isFirstSignUp: false,
        countries: [],
        periodGroupCode: {}
    };
    public targetUse: targetUseAttribute = {};
    public _targetUse: targetUseAttribute = {
        custom: {},
        version: {},
        userIds: {},
        signup: {},
        daysAfterSignup: {},
        daysPassAfterSignup: {},
        dayOfWeeks: {},
        genders: {},
        birth: {},
        hmUnsure: {},
        animals: {},
        originalAnimals: {},
        zodiacs: {},
        total: {},
        force: {},
        bonus: {},
        lastPurchaseDate: {},
        fatebookCount: {},
        lastLoginDate: {},
        fortuneItemIds: {},
        fortuneItemNoIds: {},
        fortuneItemTags: {},
        fortuneItemCategories: {},
        fortuneItemRecord: {},
        premiumItemIds: {},
        premiumItemNoIds: {},
        notSeenPremiumItemIds: {},
        premiumItemRecord: {},
        friendCount: {},
        friendAddCount: {},
        friendInviteCount: {},
        friendCelebCount: {},
        canSlotMachine: {},
        chulcheck: {},
        push: {},
        anonymous: {},
        chargeRecord: {},
        withdrawalRecord: {},
        periodWithdrawalRecord: {},
        birthday: {},
        dailyScore: {},
        giftIds: {},
        notUsedGiftIds: {},
        existGiftIds: {},
        hasNotUsedGift: {},
        purchaseDate: {},
        notPurchaseDate: {},
        purchaseCount: {},
        isWelcomeUser: {},
        isLuckyLuckUser: {},
        isFirstPackageUser: {},
        isCheckTime: {},
        levels: {},
        jpLevels: {},
        timezone: {},
        hasCharged: {},
        daysAfterLastCharge: {},
        hasPremiumItem: {},
        hasFortuneItem: {},
        hasVoucher: {},
        hasPremiumItemIn30Day: {},
        daysAfterLastPremium: {},
        daysWithinLastPremium: {},
        isFirstSignUp: {},
        countries: {},
        periodGroupCode: {},
    };

    public targetChoice: targetUseAttribute = {}
    public _targetChoice: targetUseAttribute = {
        daysAfterLastChargeInputOneValue: {}
    }

    public versionList = [];
    public zodiacs = [{ num: 0, name: '양자리' },
    { num: 1, name: '황소자리' },
    { num: 2, name: '쌍둥이자리' },
    { num: 3, name: '게자리' },
    { num: 4, name: '사자자리' },
    { num: 5, name: '처녀자리' },
    { num: 6, name: '천칭자리' },
    { num: 7, name: '전갈자리' },
    { num: 8, name: '사수자리' },
    { num: 9, name: '염소자리' },
    { num: 10, name: '물병자리' },
    { num: 11, name: '물고기자리' }
    ];

    public countries = [];

    public animalName = [
        '쥐', '소', '호랑이', '토끼', '용', '뱀', '말', '양', '원숭이', '닭', '개', '돼지'
    ];
    public animalColor = [
        '푸른', '푸른', '붉은', '붉은', '황금', '황금', '하얀', '하얀', '검은', '검은'
    ];
    public animals = [];

    public originalAnimals = [];

    public dayOfWeekName = [
        '일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'
    ];

    public levelNames = [
        'Lv1-포덕 입문자', 'Lv2-포덕 수련자', 'Lv3-포덕 도사', 'Lv4-포덕 신선', 'Lv5-갓 포덕'
    ];

    public jpLevelNames = [
        'Lv1-Regular', 'Lv2-Silver', 'Lv3-Gold', 'Lv4-Platinum'
    ];

    public levels = [];
    public jpLevels = [];

    public dayOfWeeks = [];

    public form;

    private initialized = false;

    constructor(
        private versionService: VersionService,
        private cityService: CityService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            this.initialize();
        }
        this.targetSet(changes.__target.currentValue);
    }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        if (this.initialized) {
            return;
        }
        this.getVersionList();
        this.getCountries();
        this.makeAnimal();
        this.makeOriginalAnimal();
        this.makeDay();
        this.makeLevel();
        this.initialized = true;
    }

    makeTargetData() {
        const target = _.pickBy(this.target, (val, key) => this.targetUse[key]?.use
            && (((!_.isEmpty(val)) || typeof val === 'boolean') || typeof val === 'number'));
        if (this.targetUse.custom.use && this.customTarget.length) {
            target.custom = _.reduce({ ...this.customTarget }
                , (obj, a) => ({ ...obj, [a[0]]: a[2] === 'textarea' ? JSON.parse(a[1]) : a[1] }), {});
        }
        return target;
    }

    openTargetUse() {
        $('#target-modal').modal();
        this.modalOpen.emit();
    }

    closeTargetUse() {
        $('#target-modal').modal('hide');
    }

    selectAnimal(name: string) {
        this.target.animals.push(...this.animals.filter(a => a.name.includes(name) && !this.target.animals.includes(a)));
    }

    selectOriginalAnimal(name: string) {
        this.target.originalAnimals.push(...this.originalAnimals.filter(a => a.name.includes(name) && !this.target.originalAnimals.includes(a)))
    }

    selectDay(name: string) {
        this.target.dayOfWeeks.push(...this.dayOfWeeks.filter(d => d.name.includes(name) && !this.target.dayOfWeeks.includes(d)));
    }

    selectLevel(name: string) {
        this.target.levels.push(...this.levels.filter(l => l.name.includes(name) && !this.target.levels.includes(l)));
    }

    selectJpLevel(name: string) {
        this.target.jpLevels.push(...this.jpLevels.filter(l => l.name.includes(name) && !this.target.jpLevels.includes(l)));
    }

    targetCount(): string {
        let count = _.size(_.pickBy(this.targetUse, t => t.use));
        return count > 0 ? `- ${count}개` : '';
    }

    setGender(gender: string) {
        this.target.genders = gender ? [gender] : ['M', 'F'];
    }

    getDate(event, target) {
        if (event.type == 'value') {
            this[target] = event.value;
        } else {
            target[event.type] = event.value;
        }
    }

    getGender() {
        return this.target.genders.length == 2 ? null : this.target.genders[0];
    }

    targetSet(target?) {
        this.target = _.cloneDeep(this._target);
        this.targetUse = _.cloneDeep(this._targetUse);
        this.targetChoice = _.cloneDeep(this._targetChoice);
        if (target.custom) {
            this.targetUse.custom.use = true;
            this.customTarget = _.toPairs(target.custom).map(c => {
                let value = typeof c[1] === 'object' ? JSON.stringify(c[1]) : c[1];
                let type = typeof c[1] === 'object' ? 'textarea' : 'text';
                return [c[0], value, type];
            });
        }
        this.targetUse = _.mapValues(this._targetUse, (val, key) => {
            const useTarget = !!this.useTargetList.length && !(this.useTargetList.length && this.useTargetList.includes(key));
            const hasDefault = (this.defaultTargetList.length && this.defaultTargetList.includes(key));
            const show = !!(hasDefault || target && (target[key] || typeof target[key] === 'boolean'));
            return {
                use: show,
                disabled: useTarget
            }
        });
        if (!target) {
            return;
        }
        const newTarget = _.pickBy(target, t => t || typeof t === 'boolean');
        this.target = { ...this.target, ...newTarget };
        if (this.targetUse.zodiacs) {
            this.target.zodiacs = this.zodiacs
                .filter(z => !isNaN(_.find(this.target.zodiacs, f => f == z.num)));
        }
        if (this.target.animals) {
            this.target.animals = this.animals
                .filter(a => !isNaN(_.find(this.target.animals, f => f == a.num)));
        }
        if (this.target.originalAnimals) {
            this.target.originalAnimals = this.originalAnimals
                .filter(a => !isNaN(_.find(this.target.originalAnimals, f => f == a.num)));
        }
        if (this.target.dayOfWeeks) {
            this.target.dayOfWeeks = this.dayOfWeeks
                .filter(d => !isNaN(_.find(this.target.dayOfWeeks, f => f == d.num)));
        }
        if (this.target.levels) {
            this.target.levels = this.levels
                .filter(l => !isNaN(_.find(this.target.levels, f => f == l.num)));
        }
        if (this.target.jpLevels) {
            this.target.jpLevels = this.jpLevels
                .filter(l => !isNaN(_.find(this.target.jpLevels, f => f == l.num)));

        }
        if (this.targetUse.birth && target.birth) {
            this.target.birth.start = target.birth.start.substring(0, target.birth.start.indexOf(' '));
            this.target.birth.end = target.birth.end.substring(0, target.birth.end.indexOf(' '));
        }
        if (target.daysAfterLastCharge && target.daysAfterLastCharge.start == null) {
            this.targetChoice.daysAfterLastChargeInputOneValue.use = true;
        }
    }

    getVersionList() {
        if (this.versionList.length > 0) {
            return;
        }
        this.versionService.listV2()
            .then(r => {
                this.versionList = _.map(r, v => {
                    return {
                        ver: v.ver
                    }
                });
            })
            .catch(e => {
                console.log(e)
            });
    }

    getCountries() {
        if (this.countries.length > 0) {
            return;
        }
        this.cityService.getCountries()
            .then(r => {
                this.countries = _.map(r, v => {
                    return v.country
                });
            })
            .catch(e => {
                console.error(e)
            });
    }

    makeAnimal() {
        this.animals = Array.from({ length: 60 }, (v, k) => {
            let color = this.animalColor[k % 10];
            let name = this.animalName[k % 12];
            return { num: k, name: `${color} ${name}` };
        })
    }

    makeOriginalAnimal() {
        this.originalAnimals = Array.from({ length: 60 }, (v, k) => {
            let color = this.animalColor[k % 10];
            let name = this.animalName[k % 12];
            return { num: k, name: `${color} ${name}` };
        })
    }

    makeDay() {
        this.dayOfWeeks = Array.from({ length: 7 }, (v, k) => {
            return { num: k + 1, name: this.dayOfWeekName[k] }
        })
    }

    makeLevel() {
        this.levels = Array.from({ length: 5 }, (v, k) => {
            return { num: k + 1, name: this.levelNames[k] }
        })

        this.jpLevels = Array.from({ length: 4 }, (v, k) => {
            return { num: k + 1, name: this.jpLevelNames[k] }
        })
    }

    makeCustomValueInput() {
        this.customTarget.push(['', '', 'text'])
    }

    clearDaysAfterLastCharge() {
        this.target.daysAfterLastCharge.start = null;
        this._target.daysAfterLastCharge.start = null;
    }

}

interface targetUseAttribute {
    [target: string]: {
        use?: boolean,
        disabled?: boolean
    }
}
