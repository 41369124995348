import { NgModule } from '@angular/core';
import { IndexComponent } from './index.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';
import { CanDeactivateGuard } from '../auth/_guards/deactivate.guard';

const routes: Routes = [
    {
        'path': '',
        'component': IndexComponent,
        'canActivate': [AuthGuard],
        'children': [
            {
                'path': 'dashboard',
                'loadChildren': () => import('./pages/default/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                'path': 'fortune-item',
                'loadChildren': () => import('./pages/default/fortune-item/fortune-item.module').then(m => m.FortuneItemModule)
            },
            {
                'path': 'tab',
                'loadChildren': () => import('./pages/default/tab/tab.module').then(m => m.TabModule)
            },
            {
                'path': 'my',
                'loadChildren': () => import('./pages/default/my/my.module').then(m => m.MyModule)
            },
            {
                'path': 'cs',
                'loadChildren': () => import('./pages/default/cs/cs.module').then(m => m.CSModule)
            },
            {
                'path': 'etc',
                'loadChildren': () => import('./pages/default/etc/etc.module').then(m => m.ETCModule)
            },
            {
                'path': 'live',
                'loadChildren': () => import('./pages/default/live/live.module').then(m => m.LiveModule)
            },
            {
                'path': 'statistics',
                'loadChildren': () => import('./pages/default/statistics/statistics.module').then(m => m.StatisticsModule)
            },
            {
                'path': 'celeb',
                'loadChildren': () => import('./pages/default/celeb/celeb.module').then(m => m.CelebModule)
            },
            {
                'path': 'premium-item',
                'loadChildren': () => import('./pages/default/premium-item/premium-item.module').then(m => m.PremiumItemModule)
            },
            {
                'path': 'force',
                'loadChildren': () => import('./pages/default/force/force.module').then(m => m.ForceModule)
            },
            {
                'path': 'tools',
                'loadChildren': () => import('./pages/default/tools/tools.module').then(m => m.ToolsModule)
            },
            {
                'path': 'external',
                'loadChildren': () => import('./pages/default/external/external.module').then(m => m.ExternalModule)
            },
            {
                'path': 'today',
                'loadChildren': () => import('./pages/default/today/today.module').then(m => m.TodayModule)
            },
            {
                'path': 'ai',
                'loadChildren': () => import('./pages/default/ai/ai.module').then(m => m.AIModule)
            },
            {
                'path': 'section',
                'loadChildren': () => import('./pages/default/section/section.module').then(m => m.SectionModule)
            }
        ],
    },
    {
        'path': '**',
        'redirectTo': 'dashboard',
        'pathMatch': 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    providers: [CanDeactivateGuard],
    exports: [RouterModule],
})
export class IndexRoutingModule { }
