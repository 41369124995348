import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Helpers } from "./helpers";
import { Title } from "@angular/platform-browser";
import { environment } from "../environments/environment";
import 'brace';
import 'brace/mode/scala.js';
import 'brace/mode/json.js';
import 'brace/mode/sh.js';
import 'brace/mode/css.js';
import 'brace/mode/html.js';
import 'brace/theme/tomorrow_night_bright.js';

import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    title = 'app';
    globalBodyClass = 'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--fixed m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';

    constructor(
        private titleService: Title,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private translate: TranslateService
    ) {
        if (environment.production) {
            console.log = function(): void { };
            console.debug = function(): void { };
            console.warn = function(): void { };
            console.info = function(): void { };
        }
    }

    public language = environment.language;

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    getTitle(state, parent) {
        let data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push(parent.snapshot.data.title);
        }

        if (state && parent) {
            data.push(...this.getTitle(state, state.firstChild(parent)));
        }

        return data;
    }

    ngOnInit() {
        this.translate.setDefaultLang(this.language || "ko");

        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                Helpers.setLoading(true);
                Helpers.bodyClass(this.globalBodyClass);
            }
            if (route instanceof NavigationCancel) {
                Helpers.setLoading(false);
            }
            if (route instanceof NavigationEnd) {
                let type = this.language;
                let currentTitle = this.getTitle(this._router.routerState, this._router.routerState.root);
                this.titleService.setTitle(type + "-" + currentTitle.toString());
                Helpers.setLoading(false);
            }
        });
    }
}
