import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GiftService } from "app/_services/force/gift.service";
import * as _ from "lodash";

@Component({
    selector: 'app-gift-views-select',
    templateUrl: './gift-views-select.component.html',
    styleUrls: ['./gift-views-select.component.scss']
})
export class GiftViewsSelectComponent implements OnInit {

    @Input('giftIds')
    set _giftIds(value) {
        this.giftIds = value;
    }
    @Output()
    public giftIdsChange: EventEmitter<any> = new EventEmitter<any>();
    private giftIds = [];
    public searchGiftViewList = [];
    public giftViewList = [];

    @Input()
    multiSelect: boolean = false;

    public inputValue: string = '';
    public searchMultiGiftViewList = [];

    constructor(private giftService: GiftService) {
    }

    ngOnInit() {
        this.getData();
    }

    async getData() {
        const giftList = await this.giftService.list();
        this.searchGiftViewList = giftList.map(gift => this.createGiftData(gift.id, gift.title, gift.type));
        this.setGiftData();
    }

    setGiftData() {
        if (!this.giftViewList.length && (this.giftIds !== undefined && this.giftIds.length > 0)) {
            this.giftViewList = this.searchGiftViewList
                .filter(gift => this.giftIds.includes(gift.gift_id))
                .map(gift => this.createGiftData(gift.gift_id, gift.title, gift.gift.type));
            this.giftViewList = this.giftIds.map((id) => this.giftViewList.find(item => item.gift_id === id))
        }
    }

    private createGiftData(id: number, title: string, type: string) {
        return {
            gift_id: id,
            title: title,
            gift: { type: type },
            bindLabel: `[${this.getGiftTypeName(type)} ${id}] - ${title}`
        };
    }

    getGiftTypeName(type: string) {
        if (type == 'force') {
            return '적립권';
        } else if (type == 'pitem') {
            return '소장권';
        } else if (type == 'citem') {
            return '이용권';
        } else if (type == 'discount') {
            return '할인권';
        } else {
            return '타입없음';
        }
    }

    addGift(event: any) {
        if (event) {
            event['isAdd'] = true;
            this.giftViewList.push(event);
            _.remove(this.searchGiftViewList, i => i.gift_id == event.gift_id);
            this.searchGiftViewList = [...this.searchGiftViewList]
            this.giftIdsChange.emit(this.giftViewList.map(gift => gift.gift_id));
        }
    }

    drop() {
        this.giftIdsChange.emit(this.giftViewList.map(gift => gift.gift_id));
    }

    deleteGift(i: number) {
        const gift = this.giftViewList.slice(i, i + 1)[0];
        this.searchGiftViewList = [...this.searchGiftViewList, gift];
        this.giftViewList.splice(i, 1)[0];
        this.giftIdsChange.emit(this.giftViewList.map(gift => gift.gift_id));
    }

    findMultiItems(input, array) {
        const inputIds = input.split(',').map(id => id.trim());

        const result = inputIds
            .map(id => array.find(item => item.gift_id.toString() === id))
            .filter(item => item !== undefined);

        this.searchMultiGiftViewList = result;
    }

    addMultiSelectItem() {
        this.findMultiItems(this.inputValue, this.searchGiftViewList)
        if (this.searchMultiGiftViewList) {
            this.searchMultiGiftViewList.map((item) => {
                return item['isAdd'] = true;
            })
            this.giftViewList = [...this.searchMultiGiftViewList, ...this.giftViewList];
            this.giftIdsChange.emit(this.giftViewList.map(gift => gift.gift_id));
        }

    }

    getMultiSelectData() {
        this.findMultiItems(this.inputValue, this.searchGiftViewList)
    }
}
