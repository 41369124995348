<div [id]="dropId" class="form-group m-form__group ">
  <div [id]="dropId">

    <div *ngIf="!dragMode"
      class="m-dropzone dropzone m-dropzone--success uploader-image d-flex justify-content-center position-relative"
      action="api/upload/multiV2" [class.disabled]="disabled"
      [id]="uploaderId"
    >
      <div class="m-dropzone__msg dz-message needsclick" center-vh fx-col>
        <ng-container *ngIf="disabled; else defaultTemplate">
          <ng-content select="#disabled-template"></ng-content>
        </ng-container>
        <ng-template #defaultTemplate>
          <p class="mb-2 mt-2 file_font">{{imageFileSize}}</p>
          <h3 *ngIf="!innerImageSrc && !small" class="m-dropzone__msg-title">클릭하거나 파일을 드래그하세요.</h3>
          <span *ngIf="!innerImageSrc && !small" class="m-dropzone__msg-desc">이미지 파일만 가능</span>
          <span *ngIf="!innerImageSrc && small && index" class="m-dropzone__msg-desc">{{index}}</span>
          <div class="add-src" (click)="openSvgModal(true)">
            <i class="la la-pencil"></i>
          </div>
        </ng-template>
        <img class="uploaded-image" *ngIf="innerImageSrc" [src]="innerImageSrc">
      </div>
      <button *ngIf="innerImageSrc" type="button" class="close" (click)="deleteImage()">&times;</button>
    </div>

    <div [id]="dropId" *ngIf="dragMode" class="drag-mode">
      <div [id]="dropId" *ngIf="!innerImageSrc && small && index" class="m-dropzone__msg-desc" style="font-size: .85rem">
        <div class="text-center">{{index}}</div>
        <div style="font-size: 11px;">이미지를 넣으려면 드래그 모드를 해제하세요.</div>
      </div>
      <img class="uploaded-image" *ngIf="innerImageSrc" [src]="innerImageSrc" [id]="dropId">
      <button *ngIf="innerImageSrc" type="button" class="close">&times;</button>
    </div>
  </div>
</div>

<div id="src-modal" class="svg-modal modal normal-modal" [class.active]="srcModal">
  <div class="modal-dialog" style="max-width: 650px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          이미지 주소 등록
        </h5>
        <button class="close" (click)="openSvgModal(false)">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex">
        <input type="text" class="form-control mr-3" [(ngModel)]="inputSrc">
        <button class="btn btn-danger btn-sm px-4" (click)="addSrc()">등록</button>
      </div>
    </div>
  </div>
</div>
