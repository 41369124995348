import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

    @Input()
    public value: boolean = false;
    @Input()
    public img: boolean = false;
    @Input()
    public text: string;
    @Input()
    public disabled: boolean = false;
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }
}
