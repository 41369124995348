
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

declare let $: any;

@Component({
    selector: 'app-guardian-image-selector-v2',
    templateUrl: 'guardian-image-selector-v2.component.html'
})

export class GuardianImageSelectorV2Component implements OnInit {

    public modalType: string = 'default';
    public guardianImage;
    public guardianListData;
    public convertedGuardianListData;

    @Input('currentGuardianId') currentGuardianId;
    @Output('onGuardianSelect') onGuardianSelect = new EventEmitter();

    constructor(
        private http: HttpClient
    ) {
    }

    async ngOnInit() {
        await this.getData();
    }

    ngAfterViewInit() {
    }

    getData() {
        return new Promise((resolve, reject) => {
            this.http
                .get('guardian/list')
                .toPromise()
                .then(r => {
                    this.guardianListData = r as any;
                    this.convertedGuardianListData = this.guardianListData.map(g => {
                        return {
                            id: g.id,
                            name: g.name,
                            image_url: g.avatar_img_url,
                            selected: false
                        }
                    })
                    resolve(true);
                })
                .catch(e => {
                    console.log(e)
                    reject(e);
                })
        })
    }

    openModal() {
        $('#image-selector-modal-guardian').modal();
        if (!this.currentGuardianId) return;

        let currentGuardian = _.find(this.convertedGuardianListData, (guardian: any) => { return guardian.id == this.currentGuardianId });
        this.toggleActiveGuardian(currentGuardian);
    }

    selectGuardian(guardian) {
        this.toggleActiveGuardian(guardian);
        this.onGuardianSelect.emit(guardian);
        //용신 선택 후 모달 close 이벤트 추가
        $('#image-selector-modal-guardian').modal('hide');
    }

    toggleActiveGuardian(selected_guardian) {
        let previous_index = _.findIndex(this.convertedGuardianListData, function(guardian: any) { return guardian.selected == true });
        if (previous_index >= 0)
            this.convertedGuardianListData[previous_index].selected = false;

        let new_index = _.findIndex(this.convertedGuardianListData, function(guardian: any) { return guardian.id == selected_guardian.id });
        if (new_index >= 0)
            this.convertedGuardianListData[new_index].selected = true;
    }

    guardianName(id) {
        return this.guardianListData.filter((guardian) => guardian.id == this.currentGuardianId)[0].name;
    }

}
