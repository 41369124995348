<div class="form-group m-form__group ">
    <div class="">
        <div class="m-dropzone dropzone m-dropzone--primary" [id]="uploaderId">
            <div class="m-dropzone__msg dz-message needsclick">
                <h3 class="m-dropzone__msg-title">
                    클릭하거나 여기에 파일을 드래그하세요.
                </h3>
                <span class="m-dropzone__msg-desc">
                    CSV, TSV 파일만 가능
                </span>
            </div>
        </div>

    </div>
</div>
