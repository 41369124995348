import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class GuardianService extends BaseAPIService {

    private ROUTES = {
        select: '/guardian/:id',
        edit: '/guardian/id',
        delete: '/guardian/:id/image',
        deleteV2: '/guardian/v2/:id',
        list: '/guardian/list',
        image: 'guardian/id/image'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    select(context: any): Promise<any> {
        return this.GET(this.ROUTES.select, context).toPromise();
    }

    update(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.edit, body).toPromise();
    }

    delete(context: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.delete, context)).toPromise();
    }

    deleteV2(context: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.deleteV2, context)).toPromise();
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    image(id: number): Promise<any> {
        return this.GET(this.ROUTES.image, null, { params: { guardian_id: id } }).toPromise();
    }
}
