
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';


import { Router } from '@angular/router';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
    // private headers: HttpHeaders = new HttpHeaders(
    //     {
    //         'Content-Type': 'application/json',
    //         'X-Requested-With': 'XMLHttpRequest',
    //         'Cache-Control': 'no-cache',
    //         'Pragma': 'no-cache',
    //     }
    // )

    constructor(
        private router: Router
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // httpHeader 객체는 그 자체에 변경이 불가하다. 그래서 set의 리턴값으로 받아와야 한다.
        let headers = req.headers
            .set('Content-Type', 'application/json')
            .set('X-Requested-With', 'XMLHttpRequest')
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache');
        const apiReq = req.clone({ url: `api${/^\//.test(req.url) ? '' : '/'}${req.url}`, headers: headers });
        return next
            .handle(apiReq).pipe(
                catchError(err => {
                    // 에러가 났는데, 리다이렉트 되어 돌아오는 경우가 있다.
                    console.log(err)
                    if (err && err.url && (<string>(err.url)).includes(`${window.location.protocol}//${window.location.hostname}/login`)) {

                        let loginUrl = this.router.createUrlTree(['login'], {
                            queryParams: err
                        });
                        this.router.navigateByUrl(loginUrl);
                        return
                    }
                    if (err.status == 302) {
                        console.log('302 리다이렉트')
                        let redirectUrl = err.error.redirectUrl;
                        window.location.assign(redirectUrl);
                    }

                    // 리다이렉트에대한 것만 처리를 하고,
                    // 글로발한 에러 핸들링은 하지 않는다.
                    // 각각 에러를 처리했기 때문이다. 따라서 xhr 의 경우 해당 인터셉터에서 에러를 내고, 각 요청에서 에러핸들링을 한다.

                    return observableThrowError(err);
                }))
    }
}
