import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class TagService extends BaseAPIService {

    private ROUTES = {
        fortuneTags: '/items/tags/all',
        fortuneTagsByCategoryId: '/items/tags/:categoryId',
        fortuneItemsByTagName: '/items/tags/:categoryId/:tagName',
        premiumTagsByCategoryId: '/premium/tags/:categoryId',
        premiumItemsByTagName: '/premium/tags/:categoryId/:tagName'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    fortuneTags(): Promise<any> {
        return this.GET(this.ROUTES.fortuneTags).toPromise();
    }

    fortuneTagsByCategoryId(categoryId: any): Promise<any> {
        return this.GET(this.ROUTES.fortuneTagsByCategoryId, { categoryId }).toPromise();
    }

    fortuneItemsByTagName(categoryId: any, tagName: string): Promise<any> {
        return this.GET(this.ROUTES.fortuneItemsByTagName, { categoryId, tagName: encodeURIComponent(tagName) }).toPromise();
    }

    premiumTagsByCategoryId(categoryId: any): Promise<any> {
        return this.GET(this.ROUTES.premiumTagsByCategoryId, { categoryId }).toPromise();
    }

    premiumItemsByTagName(categoryId: any, tagName: string): Promise<any> {
        return this.GET(this.ROUTES.premiumItemsByTagName, { categoryId, tagName: encodeURIComponent(tagName) }).toPromise();
    }

}
