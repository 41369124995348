import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class ChapterService extends BaseAPIService {
    private ROUTES = {
        create: '/chapter/id',
        update: '/chapter/id',
        get: '/chapter/:id',
        delete: '/chapter/:id',
        getComment: '/chapter/attribs/comment',
        createComment: '/chapter/attribs/comment',
        createPremiumChapter: '/chapter/premiumchapter/:id',
        getScript: '/chapter/id/script',
        logicTest: '/chapter/:id/scripts/test',
        logicTest2: '/chapter/:id/scripts/testV2',
        logicStat: '/chapter/:id/scripts/stat',
        scriptSave: '/chapter/:id/scripts/save',
        copy: '/chapter/copy/:id',
        chapterList: '/chapter/list/v2',
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    create(item): Promise<any> {
        return this.http.post(this.ROUTES.create, { item }).toPromise();
    }

    update(item): Promise<any> {
        return this.http.patch(this.ROUTES.update, { item }).toPromise();
    }

    get(id): Promise<any> {
        return this.GET(this.inject(this.ROUTES.get, { id })).toPromise();
    }

    delete(id): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.delete, { id })).toPromise();
    }

    getComment(params): Promise<any> {
        return this.GET(this.ROUTES.getComment, null, { params }).toPromise();
    }

    createComment(data): Promise<any> {
        return this.http.post(this.ROUTES.createComment, { data }).toPromise();
    }

    createPremiumChapter(id): Promise<any> {
        return this.GET(
            this.inject(this.ROUTES.createPremiumChapter, { id }),
        ).toPromise();
    }

    getScript(params): Promise<any> {
        return this.GET(this.ROUTES.getScript, null, { params }).toPromise();
    }

    logicTest(id, info): Promise<any> {
        return this.http
            .post(this.inject(this.ROUTES.logicTest, { id }), { info })
            .toPromise();
    }

    logicTestV2(id, info, itemType: string): Promise<any> {
        return this.http
            .post(
                this.inject(this.ROUTES.logicTest2, { id }),
                { info },
                { params: { itemType } },
            )
            .toPromise();
    }

    logicStat(id, info): Promise<any> {
        return this.http
            .post(this.inject(this.ROUTES.logicStat, { id }), { info })
            .toPromise();
    }

    scriptSave(id, info): Promise<any> {
        return this.http
            .post(this.inject(this.ROUTES.scriptSave, { id }), { info })
            .toPromise();
    }

    copy(chapterId): Promise<any> {
        return this.POST(
            this.inject(this.ROUTES.copy, { id: chapterId }),
        ).toPromise();
    }

    chapterList(): Promise<any> {
        return this.GET(this.ROUTES.chapterList).toPromise();
    }
}
