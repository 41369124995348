<div class="modal-header">
  <h4 class="modal-title">섹션 예약</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div dragula="reservation_sections_list" [(dragulaModel)]="sections" class="section-container">
    <div *ngFor="let section of sections" class="section">
      <div class="title">
        <span>{{section.id}}</span>
        <p *ngIf="!section.edit">{{section.title}} <i class="la la-pencil" (click)="section.edit = true"></i></p>
        <div *ngIf="section.edit">
          <input type="text" [(ngModel)]="section.title" (keyup.enter)="section.edit = false" />
          <i class="la la-level-down" (click)="section.edit = false"></i>
        </div>
      </div>
      <div class="status">
        <span class="m-switch m-switch--outline m-switch--primary">
          <label>
            <input type="checkbox" [ngModel]="section.status === 'S3'" (ngModelChange)="section.status = ($event ? 'S3' : 'S2')"/>
            <span></span>
          </label>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex mt-5 w-100 align-items-center">
    <div class="reservation-time">
      <h6>예약 시간</h6>
      <div class="m-form__group form-group row ">
        <div class="input-group date col-lg-12">
          <input type="text" class="form-control m-input" id="section-list-reserve" />
          <div class="input-group-append">
            <span class="input-group-text">
                <i class="la la-calendar glyphicon-th"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="m-form__group ml-3">
      <button (click)="addReserve()" class="btn btn-primary m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill">
        예약하기
      </button>
    </div>
  </div>
  <div class="d-flex mt-3 w-100 flex-column">
    <h6>예약 로그</h6>
    <div class="m-scrollable">
      <div class="row">
        <div class="col-sm-6">
          <div class="m-section__content">
            <div class="m-demo">
              <div class="m-demo__preview bg-light">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <td class="w-50 m--font-success font-weight-bold">시간</td>
                      <td colspan="2" class="w-50 font-weight-bold">상태</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr [ngClass]="{'not-booked-row' : reservation.status != 'booked' }"
                        *ngFor="let reservation of reserveList"
                        (click)="detailReserve(reservation)">
                      <td class="w-50 m--font-success">{{reservation.reservation_time | date:'yyyy-MM-dd HH:mm'}}</td>
                      <td class="w-25">{{reservation.status === 'booked' ? '예약됨' : (reservation.status === 'fulfill' ? '실행됨' : '취소됨')}}</td>
                      <td class="w-25">
                        <span *ngIf="reservation.status === 'booked'" (click)="cancelReserve($event, reservation)" class="cancel-btn cursor-pointer">
                          취소하기
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="m-section__content">
            <div class="m-demo">
              <div class="m-demo__preview bg-white">
                <div *ngIf="clickedDetail" class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <td class="w-10 m--font-success font-weight-bold">ID</td>
                      <td class="w-55 m--font-success font-weight-bold">테마 제목</td>
                      <td class="w-25 font-weight-bold">상태</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let reservation of detailReserveList">
                      <td class="w-10 m--font-success">{{reservation.id}}</td>
                      <td class="w-55 m--font-success text-ellipsis">{{reservation.title}}</td>
                      <td class="w-25">{{reservation.status == 'S3' ? '활성화' : '비활성화'}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <ng-container *ngIf="!clickedDetail">
                  <h5 class="empty">{{'예약 로그 클릭 시\n상세 정보 출력'}}</h5>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
