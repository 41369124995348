import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class ExternalService extends BaseAPIService {

    private ROUTES = {
        getBannerArea: '/banner/external/adItem/list',
        getBannerItems: '/banner/external/adArea/id',
        createBannerArea: '/banner/external/adArea',
        updateBannerArea: '/banner/external/adArea',
        createBannerItem: '/banner/external/adArea',
        updateBannerItem: '/banner/external/adArea',
        deleteBannerItem: '/banner/external/adItem/:id',
        deleteBannerItems: '/banner/external/adItem/delete',
        uploadBannerItems: '/banner/external/adItem/upload'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    deleteBannerItems(ids: number[]): Promise<any> {
        return this.POST(this.ROUTES.deleteBannerItems, null, { ids }).toPromise();
    }

    uploadBannerItems(uploadList, areaId, partner): Promise<any> {
        return this.POST(this.ROUTES.uploadBannerItems, null, { uploadList, areaId, partner }).toPromise();
    }


}
