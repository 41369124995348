import { combineReducers, Reducer } from "redux";
import { FortuneItemState, fortuneItemReducer } from "./fortume-item/fortune-item.reducer";
import { FortuneItemCategoryState, fortuneItemCategoriesReducer } from "./fortune-item-category/fortune-item-category.reducer";
import { formDirtyState, dirtyChekcReducer } from "./form-dirty/form-dirty.reducer";



export interface AppState {
    fortuneItemState: FortuneItemState,
    fortuneItemCategoriesState: FortuneItemCategoryState,
    dirtyCheckState: formDirtyState
}



const rootReducer: Reducer<AppState> = combineReducers<AppState>({
    fortuneItemState: fortuneItemReducer,
    fortuneItemCategoriesState: fortuneItemCategoriesReducer,
    dirtyCheckState: dirtyChekcReducer
})

export default rootReducer;
