import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from "../../../../environments/environment";
import * as momentTz from 'moment-timezone';
import { UserService } from "../../../_services/cs/user.service";

declare let moment: any;
declare let toastr: any;

@Component({
    selector: "user-profile",
    templateUrl: "user-profile.component.html",
    styleUrls: ['user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

    @Input() userInfo: any;

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private router: Router,
    ) { }

    ngOnInit() {

    }

    resetProfileTime() {
        this.http
            .patch(`user/profile/updatedat`, {
                userId: this.userInfo.id
            })
            .toPromise()
            .then(((r) => {
                this.userInfo.UserProfile.modified_at = moment().subtract(7, "days");
                toastr.success('프로필 수정 시간을 7일 전으로 돌려놓았습니다.');
            }))
            .catch(err => {
                toastr.error('실패하였습니다.');
                console.error(err);
            })
    }

    changeCountry(event) {
        let value = event;
        this.http.patch('user/country', {
            id: this.userInfo.id,
            transfer: value
        })
            .toPromise()
            .then((() => {
                value ? toastr.success('이제 해당 유저는 설정에서 국가를 변경할 수 있습니다.') : toastr.warning('이제 해당 유저는 설정에서 국가를 변경할 없습니다.');
            }))
            .catch(err => {
                toastr.error('실패하였습니다.');
                console.error(err);
            })
    }

    changeAdmin() {
        this.http.patch('user/id', {
            user: {
                id: this.userInfo.id,
                admin: !this.userInfo.admin
            }
        })
            .toPromise()
            .then(() => {
                toastr.success(`${this.userInfo.id} 계정 admin ${this.userInfo.admin ? '해제' : '설정'}됨`);
                this.userInfo.admin = this.userInfo.admin ? 0 : 1;
            })
            .catch(e => {
                console.log(e);
                toastr.error('실패');
            })
    }

    openFas(type) {
        const up = this.userInfo.UserProfile;
        if (type === 'sazoo') {
            const ymdh = momentTz(up.birth_in_millis).tz(up.City.timezone_id).format("YYYYMMDDHHmm"); // 199005301200
            const en = environment.language === 'en' ? 'app-en.' : '';
            const url = `https://${en}forceteller.com/admin/client/show_v2?ymdh=${ymdh}&calendar=${up.calendar}&gender=${up.gender}&locationId=${up.location_id}&name=${encodeURIComponent(this.userInfo.name)}`;
            window.open(url, '_blank');
        } else if (type === 'astro') {
            const url = `https://astro-fas.forceteller.com/?geonameId=${up.location_id}&tm=${up.birth_in_millis}&name=${encodeURIComponent(this.userInfo.name)}&hmUnsure=${up.hm_unsure ? 1 : 0}`
            window.open(url, '_blank');
        }
    }

    resetOnborarding() {
        const email = this.userInfo.email;
        const userId = this.userInfo.id
        this.userService.resetOnboarding({ body: { email: email, userId: userId } })
            .then(() => {
                toastr.success('초기화에 성공하였습니다.');
            })
            .catch(() => {
                toastr.error('초기화에 실패하였습니다.');
            });
    }
}
