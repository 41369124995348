<div class="input-group" *ngIf="!range">
    <input #date dateType="value" type="text" class="date form-control"
           [value]="isUtc && type != 'time' ? (value | date: 'yyyy-MM-dd HH:mm:ss' :'+0900') : value"
           [disabled]="disabled"
           autocomplete="off">
    <div class="input-group-append">
        <span class="input-group-text">
            <i class="la la-calendar glyphicon-th"></i>
         </span>
    </div>
</div> <!-- 단일 데이트 -->
<div class="row" *ngIf="range">  <!-- 레인지 데이트 -->
    <div class="col-6">
        <label *ngIf="firstLabel" class="col-form-label">
            {{firstLabel}}
        </label>
        <div class="input-group">
            <input #date dateType="value" type="text" class="date form-control"
                   [disabled]="disabled"
                   [value]="isUtc && type != 'time' ? (value | date: 'yyyy-MM-dd HH:mm:ss' :'+0900') : value" autocomplete="off">
            <div class="input-group-append">
        <span class="input-group-text">
            <i class="la la-calendar glyphicon-th"></i>
         </span>
            </div>
        </div>
    </div>
    <div class="col-6">
        <label *ngIf="secondLabel" class="col-form-label">
            {{secondLabel}}
        </label>
        <div class="input-group">
            <input #date dateType="secondValue" type="text" class="date form-control"
                   [disabled]="disabled"
                   [value]="isUtc && type != 'time' ? (secondValue | date: 'yyyy-MM-dd HH:mm:ss' :'+0900') : secondValue"
                   autocomplete="off">
            <div class="input-group-append">
        <span class="input-group-text">
            <i class="la la-calendar glyphicon-th"></i>
         </span>
            </div>
        </div>
    </div>
</div>
