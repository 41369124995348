import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class TempService extends BaseAPIService {

    private ROUTES = {
        uploadMission: '/level/mission/upload',
        getMission: '/level/mission'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    uploadMission(yyyymm: number, uploadList: any[]): Promise<any> {
        return this.POST(this.ROUTES.uploadMission, null, { yyyymm, uploadList }).toPromise();
    }

    getMission(yyyymm: number): Promise<any> {
        return this.GET(this.ROUTES.getMission, null, { params: { yyyymm } }).toPromise();
    }

}