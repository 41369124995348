import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';
import { ReservationsAttribute } from 'types/types';

@Injectable()
export class ReservationService extends BaseAPIService {

    private ROUTES = {
        banner: '/reservation/banner',
        cancel: '/reservation/cancel',
        push: '/reservation/push',
        group: '/reservation/group',
        get: '/reservation/item/all/nostatus',
        postInterval: '/reservation/post/interval',
        setSection: '/reservation/section',
        getSection: '/reservation/section/:type',
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    banner(body: any, options): Promise<any> {
        return this.http.post(this.ROUTES.banner, body, options).toPromise();
    }

    cancel(body: any, options = {}): Promise<any> {
        return this.http.patch(this.ROUTES.cancel, body, options).toPromise();
    }

    push(body: any, options): Promise<any> {
        return this.http.post(this.ROUTES.push, body, options).toPromise();
    }

    group(body: any): Promise<any> {
        return this.POST(this.ROUTES.group, null, body).toPromise();
    }

    get(type: string, ref_id?: number): Promise<any> {
        return this.GET(this.ROUTES.get, null, { params: { type, ...(ref_id && { ref_id }) } }).toPromise();
    }

    postInterval(reservationTimes: number[], postId: number) {
        return this.POST(this.ROUTES.postInterval, null, { reservationTimes, postId }).toPromise();
    }

    getSection(type: 'section' | 'section_items', params: Record<string, any>) {
        return this.GET(this.ROUTES.getSection, { type }, { params }).toPromise();
    }

    setSection(reserve: ReservationsAttribute) {
        return this.POST(this.ROUTES.setSection, null, { ...reserve }).toPromise();
    }

}
