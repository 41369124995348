import { AfterViewInit, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelpService } from "app/_services/cs/help.service";

@Component({
    selector: "user-cs",
    templateUrl: "user-cs.component.html",
})
export class UserCsComponent implements AfterViewInit {

    @Input() userInfo: any;
    public logs;

    constructor(
        private helpService: HelpService,
        private routeParam: ActivatedRoute,
    ) {
    }

    ngAfterViewInit() {
        this.routeParam.params.subscribe(data => {
            let id = data.id;
            this.getData(id);
        });
    }

    getData(id) {
        this.helpService.listByUser({ id: id })
            .then(res => {
                this.logs = res;
            })
            .catch(err => {
                console.log(err);
            })
    }

}
