import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: "app-default",
    templateUrl: "./default.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class DefaultComponent implements OnInit {


    constructor() {
    }
    ngOnInit() {
    }
}