import { Component, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ForceDepositsAttribute } from "types/types";
import * as momentTz from 'moment-timezone';

declare let $: any;
declare let mApp: any;
declare let toastr: any;
declare let swal: any;

@Component({
    selector: "coupon-history-list",
    templateUrl: "coupon-history-list.component.html",
    styleUrls: ['./coupon-history-list.component.scss'],

})
export class CouponHistoryListComponent implements OnChanges {
    @Input() timeZone;

    public userId;
    public couponHistories: ForceDepositsAttribute[] = [];
    public selectedCoupon: any;

    constructor(
        private routeParam: ActivatedRoute,
        private http: HttpClient
    ) { }

    ngOnChanges() {
        this.routeParam.params.subscribe(data => {
            this.userId = data.id;
            this.getData(this.userId);
        });
    }

    getData(userId) {
        this.http
            .get(`user/coupon/list?userId=${userId}`)
            .toPromise()
            .then((res: any) => {
                this.couponHistories = res;
                this.couponHistories.forEach(couponHistory => {
                    if (this.timeZone) couponHistory.created_at_tz = momentTz.tz(couponHistory.created_at, this.timeZone).format('YYYY-MM-DD HH:mm:ss');
                    return;
                })
            })
            .catch(e => {
                console.log(e);
            });
    }

    selectCancelCoupon(couponHistory) {
        this.selectedCoupon = couponHistory;
    }

    cancelCoupon() {
        if (!this.selectedCoupon) return;

        if (!this.selectedCoupon.Purchase.Coupon)
            swal('이런!', '취소 할 수 없는 쿠폰입니다. 짐 or 케빈에게 직접 요청 해주세요 =)', 'warning')

        $(`#cancelCouponModal`).modal('hide');
        this.blockApp();

        var info = {
            force_deposit_id: this.selectedCoupon.id,
            fatebook_id: this.selectedCoupon.ForceWithdrawalDetails[0].ForceWithdrawal.Fatebook.id,
            purchase_id: this.selectedCoupon.Purchase.id,
            withdrawal_id: this.selectedCoupon.ForceWithdrawalDetails[0].ForceWithdrawal.id,
            coupon_id: this.selectedCoupon.Purchase.Coupon.id
        }

        this.http
            .patch(`user/coupon/cancel`, { info: info })
            .toPromise()
            .then(res => {
                toastr.success('취소 되었습니다.', '성공', 1000);
                mApp.unblock(`#content-body`);
                this.getData(this.userId);
            })
            .catch(e => {
                toastr.error('오류가 발생 되었습니다.', '오류', 1000);
                mApp.unblock(`#content-body`);
                console.log(e);
            })
    }

    blockApp() {
        mApp.block(`#content-body`, {
            overlayColor: '#000000',
            type: 'loader',
            state: 'primary',
            message: '요청하신 작업을 처리 중입니다...',
            css: {
                padding: 0,
                margin: 0,
                width: '30%',
                top: '40%',
                left: '35%',
                textAlign: 'center',
                color: '#000',
                border: '3px solid #aaa',
                backgroundColor: '#fff',
                cursor: 'wait'
            }
        });
    }

}
