<header id="m_header" class="m-grid__item    m-header" m-minimize-offset="200" m-minimize-mobile-offset="200" appunwraptag="">
	<div class="m-container m-container--fluid m-container--full-height">
		<div class="m-stack m-stack--ver m-stack--desktop">
			<div [ngStyle]="{background : style.header_nav_color}" class="m-stack__item m-brand">
				<div class="m-stack m-stack--ver m-stack--general">

					<div class="m-stack__item m-stack__item--middle m-brand__tools " [ngClass]="{'custom--textalign': windowWidth > 1024 , 'custom--textalign-trigger' :togglePostion  }" style="position: relative;">
						<a href="javascript:;" (click)="toogleHamburger()" id="m_aside_left_minimize_toggle" class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block">
							<span></span>
						</a>
						<a href="javascript:;" (click)="toogleHamburger()" id="m_aside_left_offcanvas_toggle" class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
							<span></span>
						</a>
					</div>
					<div class="m-stack__item m-stack__item--middle" style="text-align: center">
						<a routerLink="/index" class="m-brand__logo-wrapper">
							<h6 class="m--font-light mt-3">
								<p [ngStyle]="{color : style.text_color}"> {{env}} </p>
							</h6>
						</a>
					</div>
					<div class="m-stack__item m-stack__item--middle m-brand__logo" style="text-align: right">
						<a routerLink="/index" class="m-brand__logo-wrapper">
							<h3 class="m--font-light">
								<img alt="" src="./assets/icons/ic_studio_50.png" />
							</h3>
						</a>
					</div>
				</div>
			</div>
			<div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
				<button class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-dark" id="m_aside_header_menu_mobile_close_btn">
					<i class="la la-close"></i>
				</button>
				<div id="m_header_menu" class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark">
				</div>
			</div>
		</div>
	</div>
</header>
