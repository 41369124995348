import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';
import { ThemeView } from "../../../types/types";

@Injectable()
export class ThemeService extends BaseAPIService {

    private ROUTES = {
        create: 'theme/new',
        update: 'theme/:id',
        delete: 'theme/:id',
        get: 'theme/:id',
        list: '/theme/list',
        sync: '/theme/sync/:type',
        getReservation: 'reservation/theme/:id/list',
        createReservation: 'reservation/theme',
        updateReservation: 'reservation/cancel',
        customThemeView: 'theme/themeView/custom',
        getCustomThemeView: 'theme/themeView/custom/:id',
        deleteCustomThemeView: 'theme/view/:id',
        deleteCustomThemeViews: 'theme/view/delete',
        copyCustomThemeView: 'theme/copy',
        copyCustomThemeViews: 'theme/view/copy',
        getCustomThemeViewList: 'theme/themeView/list/custom',
        themeViewList: 'theme/themeView/list',
        themeViewSimpleList: 'theme/themeView/list/simple',
        getThemeView: 'theme/themeView/:id',
        updateThemeView: 'theme/themeView',
        deleteThemeView: 'theme/themeView/:id',
        getThemeViewByRelId: 'theme/themeViewRelId/:id',
        uploadCustomThemeView: 'theme/custom/upload'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    create(body): Promise<any> {
        return this.http.post(this.ROUTES.create, body).toPromise();
    }

    update(id, body): Promise<any> {
        return this.http.patch(this.inject(this.ROUTES.update, { id }), body).toPromise();
    }

    delete(id): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.delete, { id })).toPromise();
    }

    get(id): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.get, { id })).toPromise();
    }

    list(): Promise<any> {
        return this.http.get(this.ROUTES.list).toPromise();
    }

    sync(type, options): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.sync, { type }), options).toPromise();
    }

    getReservation(id): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.getReservation, { id })).toPromise();
    }

    createReservation(body): Promise<any> {
        return this.http.post(this.ROUTES.createReservation, body).toPromise();
    }

    updateReservation(body, options): Promise<any> {
        return this.http.patch(this.ROUTES.updateReservation, body, options).toPromise();
    }

    createCustomThemeView(body): Promise<any> {
        return this.http.post(this.ROUTES.customThemeView, body).toPromise();
    }

    updateCustomThemeView(body): Promise<any> {
        return this.http.patch(this.ROUTES.customThemeView, body).toPromise();
    }

    getCustomThemeView(id): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.getCustomThemeView, { id })).toPromise();
    }

    deleteCustomThemeView(id): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.deleteCustomThemeView, { id })).toPromise();
    }
    deleteCustomThemeViews(ids): Promise<any> {
        return this.http.post(this.ROUTES.deleteCustomThemeViews, ids).toPromise();
    }

    copyCustomThemeView(ids): Promise<any> {
        return this.http.post(this.ROUTES.copyCustomThemeView, ids).toPromise();
    }

    copyCustomThemeViews(ids): Promise<any> {
        return this.http.post(this.ROUTES.copyCustomThemeViews, ids).toPromise();
    }

    getCustomThemeViewList(): Promise<any> {
        return this.http.get(this.ROUTES.getCustomThemeViewList).toPromise();
    }

    themeViewList(): Promise<any> {
        return this.http.get(this.ROUTES.themeViewList).toPromise();
    }

    themeViewSimpleList(): Promise<ThemeView[]> {
        return this.http.get<ThemeView[]>(this.ROUTES.themeViewSimpleList).toPromise();
    }

    updateThemeView(themeView: ThemeView): Promise<any> {
        return this.http.patch(this.ROUTES.updateThemeView, { themeView }).toPromise();
    }

    getThemeView(id): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.getThemeView, { id })).toPromise();
    }

    deleteThemeView(id): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.deleteThemeView, { id })).toPromise();
    }

    getThemeViewByRelId(id): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.getThemeViewByRelId, { id })).toPromise();
    }

    uploadCustomThemeView(item): Promise<any> {
        return this.POST(this.ROUTES.uploadCustomThemeView, null, { item }).toPromise();
    }

}
