import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class ResultSheetService extends BaseAPIService {

    private ROUTES = {
        itemSheet: '/items/:id/sheets',
        updateItemSheet: '/items/:id/sheet'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    getItemSheet(context: any): Promise<any> {
        return this.GET(this.inject(this.ROUTES.itemSheet, context)).toPromise();
    }

    deleteItemSheet(context: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.itemSheet, context)).toPromise();
    }

    updateItemSheet(context: any, body: any): Promise<any> {
        return this.http.patch(this.inject(this.ROUTES.updateItemSheet, context), body).toPromise();
    }
}
