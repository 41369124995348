import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'form-image',
    templateUrl: 'image.component.html'
})
export class ImageComponent {

    @Input() label = '';
    @Input() uploaderId = '';
    @Input() required = false;
    @Input() imageClass = "";

    @Output()
    valueChange = new EventEmitter<string>();

    _value;

    constructor() {
    }

    @Input()
    get value(): string {
        if (this._value) {
            return this._value;
        }
        return "";
    }


    set value(val: string) {
        this._value = val;
        this.valueChange.emit(this._value);
    }

    successImageUrl(src: string) {
        this.value = src;
    }
}
