import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class VersionService extends BaseAPIService {

    private ROUTES = {
        versionList: '/versions/list/ver',
        versionListV2: '/versions/list/ver/v2'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.versionList).toPromise();
    }

    listV2(): Promise<any> {
        return this.GET(this.ROUTES.versionListV2).toPromise();
    }

}
