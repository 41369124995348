import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from "../../_services/cs/notification.service";
import * as momentTz from 'moment-timezone';

@Component({
    selector: "notification-list",
    templateUrl: "notification-list.component.html",
    styleUrls: ['./notification-list.component.scss']

})
export class NotificationListComponent implements OnChanges {
    @Input() timeZone;

    public userId;
    public notifications: any[] = [];

    constructor(
        private routeParam: ActivatedRoute,
        private notificationService: NotificationService
    ) { }

    ngOnChanges() {
        this.routeParam.params.subscribe(data => {
            this.userId = data.id;
            this.getData(this.userId);
        });
    }

    getData(userId) {
        this.notificationService.getList({ userId: userId })
            .then((res: any) => {
                this.notifications = res;
                this.notifications.forEach(notification => {
                    if (this.timeZone) notification.created_at_tz = momentTz.tz(notification.created_at, this.timeZone).format('YYYY-MM-DD HH:mm:ss');
                    return;
                })
            })
            .catch(e => {
                console.log(e);
            });
    }

}
