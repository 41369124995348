import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'form-switch',
    templateUrl: 'switch.component.html',
    styleUrls: []
})
export class SwitchComponent {

    @Input() label = '';
    @Input() column = false;
    @Input() small = false;
    @Input() type: 'fortune' | 'premium' | 'common' = 'common';
    @Input()
    set value(val: string) {
        if (this.type === 'fortune') {
            this.isActive = val === 'T2';
        } else if (this.type === 'premium') {
            this.isActive = val === 'D2';
        } else {
            this.isActive = val === 'S3';
        }

    }

    @Output()
    valueChange = new EventEmitter<string>();

    constructor() {
    }

    isActive: boolean = false;

    changeActive(val: boolean) {
        if (this.type === 'fortune') {
            this.valueChange.emit(val ? 'T2' : 'T3');
        } else if (this.type === 'premium') {
            this.valueChange.emit(val ? 'D2' : 'D1');
        } else {
            this.valueChange.emit(val ? 'S3' : 'S2');
        }
    }

}
