<div class="m-portlet  " m-portlet="true" id="force-injector" *ngIf="userInfo?.active">
  <div class="m-portlet__head">
      <div class="m-portlet__head-caption">
        <div class="m-portlet__head-title">
          <h3 class="m-portlet__head-text">
            포스 충전기
            <small>수동으로 포스를 충전시켜봅시다!</small>
          </h3>
        </div>
      </div>
  </div>

  <div class="m-portlet__body">
    <div center-v class="charger-wrapper">
      <select class="custom-select form-control" [value]="''" (change)="selectForcePack($event)">
          <option [value]="''">포스팩을 선택하세요</option>
          <option [value]="p.id" *ngFor="let p of purchasableList">{{p.CouponHistories.length > 0 ? '[' + p.CouponHistories[0].id + '] '+ p.name: p.name}}</option>
      </select>

      <input type="text" class="form-control  m-input" id="sub-title" placeholder="주문 번호 (optional)" [(ngModel)]="orderId">

      <div center-v *ngIf="selectedPurchasable && selectedPurchasable.store !='coupon'" class="first-time-bonus">
        <span class="m-switch">
          <label>
            <input [(ngModel)]="isFirstTimeBonus" name="" type="checkbox">
            <span></span>
          </label>
        </span>
        <label nowrap>첫 구매 보너스</label>
      </div>

      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="!selectedDevice || !selectedPurchasable"
        [class.btn-disabled]="!selectedDevice || !selectedPurchasable"
        (click)="giveForce()"
      >포스 제공</button>
  </div>
</div>
