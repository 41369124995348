import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PostsAttribute, ReservationsAttribute } from "types/types";
import { ReservationService } from "app/_services/reservation/reservation.service";
import * as moment from "moment";

declare let $: any;
declare let toastr: any;
declare let swal: any;
import * as _ from 'lodash';

@Component({
    selector: "app-reservation-item",
    templateUrl: "reservation.post.interval.component.html",
    styleUrls: ["reservation.post.interval.component.scss"]
})
export class ReservationPostIntervalComponent implements OnInit {

    @Input()
    public postId;
    @Input()
    public startDate;
    @Input()
    public endDate;

    public reservationLogs = [];
    public intervalMinute;
    public sortOrder = [
        'booked',
        'fulfill',
        'cancel',
        'error'
    ];

    constructor(private activeModal: NgbActiveModal,
        private reservationService: ReservationService) {
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.reservationService.get('post_interval', this.postId)
            .then(r => {
                this.reservationLogs = r.sort((a, b) => b.reservation_time - a.reservation_time)
                    .sort((a, b) => this.sortOrder.indexOf(a.status) - this.sortOrder.indexOf(b.status));
            })
            .catch(e => {
                console.log(e);
            })
    }

    ngAfterViewInit() {
        $('#reservation_datetimepicker').daterangepicker({
            timePicker: true,
            buttonClasses: 'm-btn btn',
            applyClass: 'btn-primary',
            cancelClass: 'btn-secondary',
            locale: {
                format: 'YYYY-MM-DD HH:mm:ss'
            },
            startDate: this.startDate,
            endDate: this.endDate,
        }, (start, end, label) => {
            this.startDate = start;
            this.endDate = end;
        });
    }

    close() {
        this.activeModal.close();
    }

    async reservation() {
        const intervalMinute = this.intervalMinute * 60000;
        let reservationTimes = [];
        const startDate = new Date(this.startDate).getTime();
        const endDate = new Date(this.endDate).getTime();
        for (let i = startDate + intervalMinute; i < endDate; i += intervalMinute) {
            reservationTimes.push(i)
        }
        const { value } = await swal({
            title: `${reservationTimes.length}개의 예약을 실행합니다.`,
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: '취소',
            reverseButtons: true
        });
        if (!value) {
            return;
        }
        this.reservationService.postInterval(reservationTimes, this.postId)
            .then(() => {
                toastr.success('예약을 완료하였습니다.');
                this.getData();
            }).catch(e => {
                console.log(e);
                toastr.warning('예약에 문제가 있습니다.');
            });
    }

    cancelReservation(event, id) {
        event.stopImmediatePropagation();
        this.reservationService.cancel({ id: id, type: 'post_interval' })
            .then(() => {
                this.getData();
            })
            .catch(e => {
                console.log(e);
            })
    }

    ngOnDestroy() {
        $('#reservation_datetimepicker').datetimepicker('remove');
    }

}
