import { Action } from "redux";
import { FortuneItemsAttribute } from "../../../types/types";
import { Dirty_FortuneItemCategories, SetDirtyFortuneItemCategoiesAction } from "./fortune-item-category.action";
// import { createSelector } from 'reselect';

export interface FortuneItemCategoryState {
    isDirty: boolean;
}


const initialState: FortuneItemCategoryState = {
    isDirty: false

}

export const fortuneItemCategoriesReducer = function(state: FortuneItemCategoryState = initialState, action: Action): FortuneItemCategoryState {
    switch (action.type) {
        case Dirty_FortuneItemCategories:
            return {
                isDirty: (<SetDirtyFortuneItemCategoiesAction>action).isDirty,
            }
        default:
            return state
    }
}
