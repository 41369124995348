<div class="m-portlet  m-portlet--full-height">
    <div class="m-portlet__body">

      <div class="user-profile">
        <h2>
          {{userInfo?.name}}
          <small *ngIf="!userInfo?.active">탈퇴</small>
          <small *ngIf="userInfo?.admin" class="m--font-danger">어드민</small>
        </h2>
        <div center-v>
          <span class="gender" [style.color]="userInfo?.UserProfile?.gender == 'M' ? '#83b1d7' : '#df8d8d'">
            {{userInfo?.UserProfile?.gender == 'M' ? '남자' : '여자'}}
          </span>
          <p class="birthday">
            {{userInfo?.UserProfile?.hm_unsure ? '시간 모름' : '시간 설정'}}
          </p>
        </div>
      </div>

      <div class="user-info">
        <div>
          <h5>이메일</h5>
          <p>{{userInfo?.email}}</p>
        </div>
        <div>
          <h5>가입일자</h5>
          <p>{{userInfo?.created_at | date:'yyyy. MM. dd.':'+0900'}}</p>
        </div>
        <div class="devices">
          <h5>사용 기기</h5>
          <div>
            <a *ngFor="let device of userInfo?.Devices"
               class="device-item"
              href="/cs/user-search?type=device&keyword={{device.id}}"
               target="_blank"
            >
              {{device.id}} | {{device.name}} | {{device.os}} {{device.version}} | v{{device.appVersion + ' ' + (device.updated_at | date:'yyyy. MM. dd. HH:mm:ss':'+0900')}}
            </a>
          </div>
        </div>
      </div>

      <hr/>

      <div class="user-function">
        <div center-v>
          <h5>프로필 수정 시간</h5>
          <p>{{(userInfo?.UserProfile?.modified_at | date:'yyyy. MM. dd. HH:mm:ss':'+0900') || '수정 이력 없음'}}</p>
          <button *ngIf='userInfo?.UserProfile?.modified_at' class="btn-reset btn-outline-primary" type="submit" (click)="resetProfileTime()">7일 전으로</button>
        </div>
        <div center-v>
          <h5>국가 변경 가능 여부</h5>
          <input type="checkbox" class="m-checkbox" [ngModel]="userInfo?.enable_transfer" (ngModelChange)="changeCountry($event)" />
        </div>
        <div center-v class="cursor-pointer" (click)="changeAdmin()">
          <i [ngClass]="{'m--font-success': userInfo?.admin}" class="fa fa-cog"></i>
          <span class="ml-1">어드민 설정</span>
        </div>
        <div center-v class="cursor-pointer" (click)="openFas('sazoo')">
          <i class="m-nav__link-icon fa fa-address-card" style="font-size:13px"></i>
          <span class="ml-1">FAS</span>
        </div>
        <div center-v class="cursor-pointer" (click)="openFas('astro')">
          <i class="m-nav__link-icon fa fa-address-card" style="font-size:13px"></i>
          <span class="ml-1">ASTRO FAS</span>
        </div>
        <div center-v class="cursor-pointer" (click)="resetOnborarding()">
          <i class="m-nav__link-icon fa fa-address-card" style="font-size:13px"></i>
          <span class="ml-1">온보딩 초기화</span>
        </div>
      </div>
    </div>
</div>
