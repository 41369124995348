import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class ConsumableService extends BaseAPIService {

    private ROUTES = {
        consumableList: '/consumable/list',
        promotionList: '/consumable/promotion/list',
        promotionBulk: '/consumable/promotion',
        updatePromotion: '/consumable/promotion',
        deletePromotion: '/consumable/promotion/:id',
        uploadPromotionList: '/consumable/promotion/upload'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    promotionList(): Promise<any> {
        return this.GET(this.ROUTES.promotionList).toPromise();
    }

    consumableList(): Promise<any> {
        return this.GET(this.ROUTES.consumableList).toPromise();
    }

    promotionBulk(body): Promise<any> {
        return this.http.post(this.ROUTES.promotionBulk, body).toPromise();
    }

    updatePromotion(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.updatePromotion, body).toPromise();
    }

    deletePromotion(content: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.deletePromotion, content)).toPromise();
    }

    uploadPromotionList(uploadList: any[]): Promise<any> {
        return this.http.post(this.ROUTES.uploadPromotionList, { uploadList }).toPromise()
    }
}
