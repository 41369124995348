import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserProfileComponent } from './user/profile/user-profile.component';
import { ForceRechargeComponent } from './force/force-recharge/force-recharge.component';
import { UserCsComponent } from './user/cs/user-cs.component';
import { ForceHistoryListComponent } from './force/force-history-list/force-history-list.component';
import { PriceTagComponent } from './force/price-tag/price-tag.component';
import { CouponHistoryListComponent } from './coupon/coupon-history-list.component';
import { BokchaeHistoryListComponent } from './bokchae/bokchae-history-list.component';
import { ThemePackageComponent } from "./theme/theme-package.component";
import { NgDragDropModule } from "ng-drag-drop";
import { DragulaModule } from "ng2-dragula";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NotificationListComponent } from "./notification/notification-list.component";
import { GiftListComponent } from "./gift/gift-list.component";
import { PipesModule } from "../pipes/pipes.module";
import { ConnectComponent } from './user/connect/connect.component';
import { DatepickerComponent } from './common/datepicker/datepicker.component';
import { TagComponent } from "./common/tag/tag.component";
import { CheckboxComponent } from "./common/checkbox/checkbox.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { ItemComponent } from "./premium/item.component";
import { ReservationComponent } from "./modal/reservation/group-item/reservation.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { InputComponent } from './form/input/input.component';
import { SwitchComponent } from "./form/checkbox/switch.component";
import { TextareaComponent } from "./form/textarea/textarea.component";
import { SelectComponent } from "./form/select/select.component";
import { RecommendComponent } from "./modal/recommend/recommend.component";
import { ColorComponent } from "./form/color/color.component";
import { ReservationGroupComponent } from "./modal/reservation/group/reservation.group.component";
import { ReservationItemComponent } from "./modal/reservation/item/reservation.item.component";
import { TagItemListComponent } from "./common/tag-item-list.component.html/tag-item-list.component";
import { ReservationPostIntervalComponent } from "./modal/reservation/post-interval/reservation.post.interval.component";
import { ImageComponent } from "./form/image/image.component";
import { ImageUploadComponent } from "./image/image-upload.component";
import { ImageUploaderComponent } from "./uploader/image/image-uploader.component";
import { MultiImageUploaderComponent } from "./uploader/multi-image/multi-image-uploader.component";
import { MultiDragImageUploaderComponent } from "./uploader/multi-drag-image/multi-drag-image-uploader.component";
import { CTSVUploaderComponent } from "./uploader/csv-tsv/ctsv-uploader.component";
import { ThemeViewsSelectComponent } from "./theme/theme-views-select/theme-views-select.component";
import { CelebBatchUploaderComponent } from "./uploader/celeb-batch/celeb-batch-uploader.component";
import { TargetComponent } from "./common/target/target.component";
import { TodayUploadComponent } from './today/today-upload/today-upload.component';
import { RouterModule } from "@angular/router";
import { FortuneAdItemComponent } from './fortune-item/ad/ad-item/fortune-ad-item.component';
import { FortuneAdItemEditModalComponent } from './fortune-item/ad/ad-item-edit-modal/fortune-ad-item-edit-modal.component';
import { GiftViewsSelectComponent } from './gift/gift-views-select/gift-views-select.component';
import { GiftUploadComponent } from './gift/gift-upload/gift-upload.component';
import { ThemeCustomUploadComponent } from "app/ui-components/theme/theme-custom-upload/theme-custom-upload.component";
import {
    AdItemMappingUploadComponent
} from 'app/ui-components/fortune-item/ad/ad-item-mapping-upload/app-item-mapping-upload.component';
import { ForcePackPromotionUploadComponent } from 'app/ui-components/force/force-pack-promotion-upload/force-pack-promotion-upload.component';
import { NewTagComponent } from 'app/ui-components/common/new-tag/new-tag.component';
import { ReservationDialogComponent } from './section/reservation-dialog/reservation-dialog.component';
import { ReservationListDialogComponent } from './section/reservation-list-dialog/reservation-list-dialog.component';

const components = [
    UserProfileComponent,
    ForceRechargeComponent,
    ForceHistoryListComponent,
    CouponHistoryListComponent,
    UserCsComponent,
    BokchaeHistoryListComponent,
    ThemePackageComponent,
    NotificationListComponent,
    GiftListComponent,
    PriceTagComponent,
    ConnectComponent,
    DatepickerComponent,
    TagComponent,
    CheckboxComponent,
    ItemComponent,
    ReservationComponent,
    ReservationGroupComponent,
    ReservationItemComponent,
    ReservationPostIntervalComponent,
    InputComponent,
    SwitchComponent,
    TextareaComponent,
    SelectComponent,
    RecommendComponent,
    ColorComponent,
    CheckboxComponent,
    TagItemListComponent,
    ImageComponent,
    ImageUploadComponent,
    ImageUploaderComponent,
    MultiImageUploaderComponent,
    MultiDragImageUploaderComponent,
    CTSVUploaderComponent,
    ThemeViewsSelectComponent,
    CelebBatchUploaderComponent,
    TargetComponent,
    TodayUploadComponent,
    ThemeCustomUploadComponent,
    AdItemMappingUploadComponent,
    ForcePackPromotionUploadComponent,
    NewTagComponent
];

const modules = [
    InfiniteScrollModule,
    PipesModule,
    NgSelectModule,
    NgbModule,
    DragDropModule,
    RouterModule
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgDragDropModule,
        DragulaModule.forRoot(),
        ReactiveFormsModule,
        ...modules
    ],
    declarations: [...components, FortuneAdItemComponent, FortuneAdItemEditModalComponent, GiftViewsSelectComponent, GiftUploadComponent, ReservationDialogComponent, ReservationListDialogComponent],
    exports: [...components, ...modules, FortuneAdItemComponent, GiftViewsSelectComponent, GiftUploadComponent],
    providers: [],
    entryComponents: [ReservationComponent, ReservationGroupComponent, ReservationItemComponent,
        RecommendComponent, ReservationPostIntervalComponent]
})

export class UIComponentsModule { }
