import { AfterViewInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppSection, ReservationsAttribute } from 'types/types';
import * as _ from 'lodash';
import * as moment from 'moment/moment';
import { ReservationService } from 'app/_services/reservation/reservation.service';

declare let $: any;
declare let toastr: any;

@Component({
    selector: 'app-reservation-list-dialog',
    templateUrl: './reservation-list-dialog.component.html',
    styleUrls: ['./reservation-list-dialog.component.scss']
})
export class ReservationListDialogComponent implements AfterViewInit {

    @Input() sections: SectionDTO[];
    reserveTime: string | null = null;
    reserveList: ReservationsAttribute[] = [];
    detailReserveList = [];
    clickedDetail = false;
    pageType = '';

    constructor(
        public activeModal: NgbActiveModal,
        private reservationService: ReservationService,
    ) {
    }

    ngAfterViewInit() {
        $('#section-list-reserve').datetimepicker({
            todayHighlight: true,
            autoclose: true,
            pickerPosition: 'bottom-left',
            todayBtn: true,
            stepping: 1,
            format: 'yyyy-mm-dd hh:ii'
        });

        $(document).on('change', '#section-list-reserve', (e) => {
            this.reserveTime = _.isEmpty(e.target.value) ? null : moment(e.target.value).utc().format();
        });
        this.pageType = (this.sections[0].page_type ?? '').toLowerCase();
        this.getReservation();
    }

    async getReservation() {
        this.reserveList = await this.reservationService.getSection('section', { pageType: this.pageType });
        this.reserveList.sort((a, b) => {
            const statusOrder = {
                'booked': -1,
                'fulfill': 1,
            };

            return (statusOrder[a.status] || Infinity) - (statusOrder[b.status] || Infinity);
        })
    }

    detailReserve(reservation: ReservationsAttribute) {
        this.clickedDetail = true;
        this.detailReserveList = JSON.parse(reservation.param);
    }

    addReserve() {
        if (moment(this.reserveTime).format('x') < moment().format('x') || this.reserveTime == null) {
            toastr.warning('예약시간이 현재시간보다 전에 있습니다. 확인해주세요.');
            return false;
        }

        const param = this.sections.map((section, index) => {
            return {
                id: section.id,
                status: section.status,
                title: section.title,
                priority: index,
            }
        });
        this.reservationService.setSection({
            type: 'section',
            task: this.pageType,
            reservation_time: +moment(this.reserveTime).format('x'),
            param: param,
            ref_id: 0
        }).then(() => {
            this.getReservation();
            toastr.success('예약 되었습니다.');
        }).catch(e => {
            console.log(e);
            toastr.success('에러 발생!! 예약실패!!');
        })
    }

    cancelReserve(event, reservation) {
        event.stopPropagation();

        this.reservationService.cancel(reservation)
            .then(() => {
                this.getReservation();
                toastr.success('예약 취소 되었습니다. ');
            })
            .catch(e => {
                console.log(e);
                toastr.success('에러 발생!!!!');
            });
    }

}

interface SectionDTO extends AppSection {
    edit: boolean;
}
