import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from "../_services/user.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AuthService } from 'ng2-ui-auth';

@Injectable()
export class AuthGuard implements CanActivate {

    private serverAuthUrl = `webcheck?`;
    constructor(
        private auth: AuthService,
        private _router: Router,
        private _userService: UserService,
        private http: HttpClient) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | Observable<boolean> | boolean {
        // let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (!this.auth.isAuthenticated()) {
            this._router.navigate(['/login', { url: state.url }]);
            return false;
        }

        let checkAdmin = () => {
            return this.http.get(this.serverAuthUrl, { observe: 'response' })
        };
        return checkAdmin()
            .toPromise()
            .then((result) => {
                if (result.status === 406) {
                    // this.http.get(`auth/facebook`, { params: `location=${this.router.url}` }).toPromise().then(r => {
                    //     let _memo = r.json();
                    //     window.location.href = _memo.url
                    //     return
                    // })
                    this._router.navigateByUrl(this._router.createUrlTree(['login']))
                    return false
                    // window.location.href = `${window.location.protocol}//${window.location.host}/auth/facebook?location=${this.router.url}`
                };
                if (result.status !== 200) {
                    // this.ngRedux.dispatch(setLoginStateAction(false))
                    this._router.navigateByUrl(this._router.createUrlTree(['login']))
                    return false
                    // throw new Error(result.status + '');
                }
                // if (result.body.update) {
                //     setTimeout(() => {
                //         window.location.href = `${window.location.protocol}//${window.location.host}?#${this.router.url}`
                //     }, 5000);
                //     return
                // }
                // this.ngRedux.dispatch(setLoginStateAction(true))
                return true
            })
            .catch((err) => {
                console.log("요청 처리 중 실패");
                if (err.status == 302) {
                    let redirectUrl = err.error.redirectUrl;
                    window.location.assign(redirectUrl);
                } else {
                    let loginUrl = this._router.createUrlTree(['login'], {
                        queryParams: err
                    });
                    this._router.navigateByUrl(loginUrl);
                }
                return false
            })


        // return this._userService.verify().map(
        //     data => {
        //         if (data !== null) {
        //             // logged in so return true
        //             return true;
        //         }
        //         // error when verify so redirect to login page with the return url
        //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //         return false;
        //     },
        //     error => {
        //         // error when verify so redirect to login page with the return url
        //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //         return false;
        //     });
    }
}
