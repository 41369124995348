import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class ChapterResultSheetService extends BaseAPIService {

    private ROUTES = {
        getChapter: '/chapter/:id',
        chapterSheets: '/chapter/:id/sheets',
        updateChapterSheet: '/chapter/:id/sheet'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    getChapter(context: any): Promise<any> {
        return this.GET(this.inject(this.ROUTES.getChapter, context)).toPromise();
    }

    getChapterResultSheets(context: any): Promise<any> {
        return this.GET(this.inject(this.ROUTES.chapterSheets, context)).toPromise();
    }

    deleteChapterResultSheets(context: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.chapterSheets, context)).toPromise();
    }

    updateChapterResultSheets(context: any, body: any): Promise<any> {
        return this.http.patch(this.inject(this.ROUTES.updateChapterSheet, context), body).toPromise();
    }

}
