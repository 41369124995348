<button type="submit" class="btn btn-outline-info" (click)="openModal()">
  <span *ngIf="currentGuardianId; else noGuardianId">
    {{guardianName(currentGuardianId)}}
  </span>
  <ng-template #noGuardianId>
    용신 선택
  </ng-template>
</button>

<div id="image-selector-modal-guardian" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
  <div class="modal-dialog  modal-dialog-centered guardian" role="document">
      <div class="modal-content">
          <div class="modal-header" style="align-items:center">
              <div class="modal-title" id="exampleModalLabel">
                  <b>용신을 선택하세요</b>
              </div>
          </div>
          <div class="modal-body">
              <div class="m-scrollable" data-scrollbar-shown="true" data-scrollable="true" data-max-height="500">
                  <div (click)="selectGuardian(guardian)" [ngClass]="{'selected': guardian.selected}" class="image-selector-select-col" style="margin : 5px 5px 15px 5px;  display: flex ;flex-direction: column; align-items: center"
                      *ngFor="let guardian of convertedGuardianListData">
                      <span style="text-align: center; width: 100px;">
                          {{guardian.id >= 0 ? guardian.id +' :' :'' }} {{guardian.name}}
                      </span>
                      <div class="custom-background-image" style="width: 96px;height: 96px; margin-top: 8px;" [ngClass]="{'zoom': zoom}" [ngStyle]="{ 'background-image': 'url('+guardian.image_url +')'}"></div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>