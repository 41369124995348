import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class TempService extends BaseAPIService {

    private ROUTES = {
        uploadMission: '/level/mission/upload',
        uploadEvent: '/package',
        validEvent: '/package/valid',
        getMission: '/level/mission',
        getEvent: '/package',
        getPremiums: '/package/premiums'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    uploadMission(yyyymm: number, uploadList: any[]): Promise<any> {
        return this.POST(this.ROUTES.uploadMission, null, { yyyymm, uploadList }).toPromise();
    }

    uploadEvent(
        eventTime: string,
        packageType: string,
        startTime: number,
        endTime: number,
        packages: any[],
        premiums: any[],
        topImage: string,
        bottomImage: string,
        packageImages: any
    ): Promise<any> {
        return this.POST(this.ROUTES.uploadEvent, null,
            { eventTime, packageType, startTime, endTime, packages, premiums, topImage, bottomImage, packageImages }
        ).toPromise();
    }

    getMission(yyyymm: number): Promise<any> {
        return this.GET(this.ROUTES.getMission, null, { params: { yyyymm } }).toPromise();
    }

    getEvent(eventTime: number, type: string): Promise<any> {
        return this.GET(this.ROUTES.getEvent, null, { params: { eventTime, type } }).toPromise();
    }

    getPremiums(eventInfoId: number): Promise<any> {
        return this.GET(this.ROUTES.getPremiums, null, { params: { eventInfoId } }).toPromise();
    }

    validEvent(
        eventTime: string,
        packageType: string
    ): Promise<any> {
        return this.GET(this.ROUTES.validEvent, null, { params: { eventTime, packageType } }).toPromise();
    }
}
