import { Component, OnInit } from '@angular/core';
import { GuardiansAttribute } from 'types/types';
import { HttpClient } from '@angular/common/http';
import { ImageDatas, ImageSelectorComponent } from '../image-selector.component';

declare let $: any;
declare let toastr: any;

@Component({
    selector: 'app-guardian-image-selector',
    templateUrl: 'guardian-image-selector.component.html',
    styleUrls: ["./guardian-image-selector.component.scss"]
})
export class GuardianImageSelectorComponent extends ImageSelectorComponent implements OnInit {


    public modalType: string = 'default';
    public newGuardian: GuardiansAttribute = {
        class: '갑자',
        schools: 'fire'
    };

    constructor(
        private http: HttpClient
    ) {
        super();
    }

    actHeaderBtnEvent(type: string) {
        this.modalType = type;
    }

    selectData($event, data) {
        let previousSelected = this.imageDatas.find(data => {
            return data.selected
        })
        if (previousSelected) {
            previousSelected.selected = false
        }
        data.selected = true;
        this.isExistSelectedData = true;
        this.applySelectedData();
    }

    successImageUrl($event) {
        this.newGuardian.avatar_img_url = $event;
    }


    applySelectedData() {
        if (this.modalType != 'default') {
            if (this.modalType == 'create') {
                this.http
                    .post('guardian/id', this.newGuardian)
                    .toPromise()
                    .then((r: GuardiansAttribute) => {
                        let newImageData: ImageDatas = {
                            id: r.id,
                            name: r.name,
                            image_url: r.avatar_img_url,
                            selected: true
                        }
                        this.imageDatas.push(newImageData);
                        this.selectedData = newImageData;
                        this.applyEvent.emit(this.selectedData);
                        toastr.success('성공적으로 용신이 생성되었습니다.')
                        $('#image-selector-modal-' + this.subject).modal('hide')
                        this.newGuardian = {
                            class: '갑자',
                            schools: 'fire'
                        };
                        this.modalType = 'default';
                    })
                    .catch(e => {
                        console.log(e)
                        toastr.error('용신 생성에 실패하였습니다.')
                    })
            }
        } else {
            if (this.isExistSelectedData) {
                this.selectedData = this.imageDatas.find(data => {
                    return data.selected
                })
                this.applyEvent.emit(this.selectedData);
                $('#image-selector-modal-' + this.subject).modal('hide')
            }
        }
    }
}

