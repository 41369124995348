import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';
import { SequencesAttribute } from 'types/types';

@Injectable()
export class SequenceService extends BaseAPIService {

    private ROUTES = {
        create: '/sequence/id',
        update: '/sequence/id',
        get: '/sequence/:id',
        delete: '/sequence/:id',
        orderScene: '/sequence/scene/list/order',
        saveScene: 'sequence/scene/id',
        updateScene: 'sequence/scene/id',
        deleteScene: '/sequence/scene/:id',
        list: 'sequence/list/v2'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    create(sequence): Promise<any> {
        return this.http.post(this.ROUTES.create, sequence).toPromise();
    }

    update(sequence): Promise<any> {
        return this.http.patch(this.ROUTES.update, sequence).toPromise();
    }

    get(id): Promise<any> {
        return this.GET(this.inject(this.ROUTES.get, { id })).toPromise();
    }

    delete(id): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.delete, { id })).toPromise();
    }

    orderScene(orderValue): Promise<any> {
        return this.http.patch(this.ROUTES.orderScene, { orderValue }).toPromise();
    }

    saveScene(sequence): Promise<any> {
        return this.http.post(this.ROUTES.saveScene, sequence).toPromise();
    }

    updateScene(sequence): Promise<any> {
        return this.http.patch(this.ROUTES.updateScene, sequence).toPromise();
    }

    deleteScene(id): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.deleteScene, { id })).toPromise();
    }

    list(): Promise<SequencesAttribute[]> {
        return this.GET(this.ROUTES.list).toPromise();
    }
}
