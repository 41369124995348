import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver, ElementRef, NgZone,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { AlertService } from './_services/alert.service';
import { AlertComponent } from './_directives/alert.component';
import { Helpers } from '../helpers';
import { AuthService } from 'ng2-ui-auth';
import { HttpClient } from '@angular/common/http';

declare let toastr: any;
declare var window: any;

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './auth.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class AuthComponent implements OnInit, AfterViewInit {
    model: any = {};
    loading = false;

    @ViewChild('alertSignin', { read: ViewContainerRef }) alertSignin: ViewContainerRef;
    @ViewChild('alertSignup', { read: ViewContainerRef }) alertSignup: ViewContainerRef;
    @ViewChild('alertForgotPass', { read: ViewContainerRef }) alertForgotPass: ViewContainerRef;
    @ViewChild('btnLogin') loginButtonDiv: ElementRef<HTMLDivElement>;

    constructor(
        private _router: Router,
        private _script: ScriptLoaderService,
        private routeParam: ActivatedRoute,
        private _alertService: AlertService,
        private auth: AuthService,
        private zone: NgZone,
        private httpClient: HttpClient,
        private cfr: ComponentFactoryResolver) {
    }

    ngOnInit() {
        this.model.remember = true;
        this._script.loadScripts('body', [
            'assets/vendors/base/vendors.bundle.js',
            'assets/demo/default/base/scripts.bundle.js'], true).then(() => {
                Helpers.setLoading(false);
                //LoginCustom.init();
            });

        const config = {
            client_id: '368878522246-dbjsiq68mcmgf5rno5dlm5gsq6jg5cjt.apps.googleusercontent.com',
            cancel_on_tap_outside: false,
            auto_select: false,
            disable_exponential_cooldowntime: true,
            context: 'use',
        };
        window.google.accounts.id.initialize({
            ...config, callback: (authorizationData) => {
                this.zone.run(() => {
                    /*
                    if (authorizationData.credential) {
                        this.auth.setToken(authorizationData.credential);
                        this._router.navigate([this.routeParam.snapshot.paramMap.get('url') || '/']);
                    }
                     */
                    this.httpClient.post('/auth/google-auth/callback', { idToken: authorizationData.credential }).subscribe((res: any) => {
                        if (res.access_token) {
                            this.auth.setToken(res.access_token);

                            this._router.navigate([this.routeParam.snapshot.paramMap.get('url') || '/']);
                        }

                    });
                });
            }
        })
    }

    ngAfterViewInit() {
        window.google.accounts.id.prompt((notification: any) => {
            if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            }
        });

        window.google.accounts.id.renderButton(
            this.loginButtonDiv.nativeElement,
            { theme: 'outline', size: 'large', text: 'continue_with', shape: 'pill' }
        );

    }

    ngAfterContentInit() {
        this.routeParam.queryParams.subscribe(param => {
            if (param['error']) {
                console.log(param['error'])
                this.showAlert('alertSignin');
                if (param['error'] == 'no-user') {
                    this._alertService.error('접근 권한이 없는 사용자입니다. 관리자에게 문의하세요.');
                } else if (param['error'] == 'duplicate-user') {
                    this._alertService.error('중복되는 아이디입니다. 관리자에게 문의하세요.');
                } else if (param['error'] == 'token-expired') {
                    this._alertService.error('로그인 기간이 만료되었습니다. 다시 시도하세요.');
                } else if (param['error'] == 'token-invalid') {
                    this._alertService.error('유효하지 않은 사용자입니다. 다시 시도하세요.');
                } else if (param['error'] == 'invalid-google-domain-login') {
                    this._alertService.error('유효하지 않은 구글 이메일입니다. 다시 시도 하세요..');
                } else if (param['error'] == 'logout-token') {
                    this._alertService.error('로그아웃 되었습니다. 다시 로그인 해주세요.');
                }
            }
        })
    }

    signin(type?: string) {
        this.loading = true;
        this.auth.authenticate(type).subscribe(v => {
            this._router.navigate([this.routeParam.snapshot.paramMap.get('url') || '/']);
        }, e => {
            this.loading = false;
            if (e.error && e.error.email) {
                toastr.error(e.error.email + ' 해당 계정에 권한이 없습니다.');
            } else {
                toastr.error(e.message);
            }
        }, () => {
            this.loading = false;
        });
    }

    showAlert(target) {
        this[target].clear();
        let factory = this.cfr.resolveComponentFactory(AlertComponent);
        let ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }
}
