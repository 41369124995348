import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TodayService } from "../../../_services/theme/today.service";
declare let toastr: any;
declare let swal: any;
declare let mApp: any;

@Component({
    selector: 'app-today-upload',
    templateUrl: './today-upload.component.html',
    styleUrls: ['./today-upload.component.scss']
})
export class TodayUploadComponent implements OnInit {
    public uploadList = [];
    public dateTimeRegex = '\\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-4]):(0[0-9]|[0-5][0-9])(:(0[1-9]|[0-5][0-9]))?';
    public timeRegex = '(0?[0-9]|1[0-9]|2[0-4]):(0[0-9]|[0-5][0-9])(:(0[1-9]|[0-5][0-9]))?';
    public isUploading = false;
    public forceChk = false;
    @Input()
    public active = false;
    @Output()
    public activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public onSuccess: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private todayService: TodayService
    ) { }

    ngOnInit(): void {
    }

    mAppDisplay(bool) {
        if (bool) {
            mApp.block(`#uploadForm`, {
                overlayColor: '#000000',
                type: 'loader',
                state: 'primary',
                message: 'Please wait...'
            });
        } else {
            mApp.unblock(`#uploadForm`);
        }
    }

    successCsv({ response }) {
        this.uploadList = this.uploadList.concat(response);
        this.forceChk = true;
    }

    close() {
        this.activeChange.emit(false);
    }

    upload() {
        this.mAppDisplay(true);
        this.isUploading = true;
        this.todayService.postUpload(this.uploadList)
            .then(r => {
                toastr.success('업로드가 완료되었습니다.');
                this.onSuccess.emit();
                this.close();
            })
            .catch(e => {
                swal('Error!', e.error.message, 'error');
            })
            .finally(() => {
                this.mAppDisplay(false);
                this.isUploading = false;
            })
    }

    deleteItem(index: number) {
        this.uploadList = this.uploadList.filter((v, i) => i !== index);
    }

    addItem() {
        this.uploadList = [...this.uploadList, {
            type: 'item', releaseType: 'reserved', title: '', subtitle: '', fixedPosition: null, presetId: null
        }];
    }

    download() {
        const url = `${window.location.protocol}//${window.location.host}/api/upload/example/download/csv?keys=type,subtitle,title,firstValue,secondValue,releaseType,releaseStart,releaseEnd,fixedPosition,presetId`;
        window.open(url, 'blank');
    }

}
