<button *ngIf="!nodisplay" type="submit" class="btn btn-outline-info" (click)="openSelectModal()">
  {{subject}} - {{convertDataIdName()}}
</button>

<div class="modal fade" [id]="'image-selector-modal-'+subject" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div *ngIf="modalType == 'default'" class="modal-dialog  modal-dialog-centered guardian" role="document">
    <div class="modal-content">
      <div class="modal-header" style="align-items:center">
        <div class="modal-title">{{subject}}을 선택하세요</div>
        <button class="btn btn-outline-info" *ngIf="subject == '용신'" type="button" (click)="actHeaderBtnEvent('create')">용신 생성</button>
      </div>
      <div class="modal-body">
        <div class="m-scrollable" data-scrollbar-shown="true" data-scrollable="true" data-max-height="600">
          <div fx-col center-v
            (click)="selectData($event,data)"
            [ngClass]="{'selected': data.selected}"
            class="image-selector-select-col"
            *ngFor="let data of imageDatas"
          >
            <div class="g-thumbnail custom-background-image" [style.background-image]="'url(' + data.image_url + ')'"></div>
            <div class="g-name" center-vh>
              <span *ngIf="data.id > 0">{{data.id}}</span>
              <h4>{{data.name}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="modalType == 'create'" class="modal-dialog  modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-header" style="align-items:center">
              <div class="modal-title" id="exampleModalLabel">
                  <b>용신 생성</b>
              </div>
              <button class="btn btn-outline-info" *ngIf="subject == '용신'" type="button" (click)="actHeaderBtnEvent('default')">
                  용신 선택창으로
              </button>
          </div>
          <div class="modal-body">
              <app-image-uploader [uploaderId]="'app-image-selector-gaurdain-image-uploader'" [innerImageSrc]="newGuardian.avatar_img_url"
                  (successImageUrl)="successImageUrl($event)"></app-image-uploader>
              <form-input label="이름" placeholder="새로운 용신의 이름을 적어주세요." [(value)]="newGuardian.name"></form-input>
              <form-input label="물상/수식어" placeholder="새로운 용신의 물상을 적어주세요 (ex-붉은 말)" [(value)]="newGuardian.animal"></form-input>
              <form-textarea label="상세 설명" placeholder="용신 설명을 적어주세요." [(value)]="newGuardian.description"></form-textarea>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="unselectData()">
                  취소
              </button>
              <button (click)="applySelectedData()" type="button" [ngClass]="{'btn-primary': newGuardian.name ,'btn-disabled':!newGuardian.name}"
                  class="btn ">
                  생성
              </button>

          </div>
      </div>
  </div>
</div>
