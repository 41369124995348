import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class UserService extends BaseAPIService {

    private ROUTES = {
        getUsers: '/user/id/search/v2/:type',
        saveUserLog: '/user/log/save',
        resetOnborading: '/user/reset/onboarding'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    getUsers(type: string, keyword: string): Promise<any> {
        return this.GET(this.ROUTES.getUsers, { type }, { params: { keyword } }).toPromise();
    }

    saveUserLog() {
        return this.POST(this.ROUTES.saveUserLog).toPromise();
    }

    resetOnboarding(opts: any): Promise<any> {
        return this.DELETE(this.ROUTES.resetOnborading, null, opts).toPromise();
    }
}
