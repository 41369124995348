<ng-select [items]="searchItems"
           bindLabel="bindLabel"
           placeholder="엔터로 아이템을 추가하세요."
           [addTag]="addItem"
           [virtualScroll]="true"
           (change)="selectItem($event)"
           #ngSelect>
</ng-select>
<div class="w-100">
  <div *ngIf="!items.length; else hasItems">등록된 아이템이 없습니다.</div>
  <ng-template #hasItems>
    <div cdkDropList class="group-items" (cdkDropListDropped)="drop($event)">
      <div class="item" *ngFor="let item of items; index as i" cdkDrag>
          <div class="w-100">
            <ng-container *ngTemplateOutlet="contentElement; context: { item }"></ng-container>
          </div>
          <div class="delete">
            <a class="delete-btn" (click)="delete(0)">
              제거
            </a>
          </div>
      </div>
    </div>
  </ng-template>
</div>
