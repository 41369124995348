import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class GiftService extends BaseAPIService {

    private ROUTES = {
        create: '/gift/create',
        update: '/gift/update',
        gift: '/gift/:giftId',
        giftCondition: '/gift/:giftId/condition/:type',
        list: '/gift/list',
        copyGift: '/gift/copy',
        copyGifts: '/gift/copygifts',
        deleteGift: '/gift/:id',
        deleteGifts: '/gift/delete',
        upload: '/gift/upload'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    create(body: any): Promise<any> {
        return this.http.post(this.ROUTES.create, body).toPromise();
    }

    update(body: any): Promise<any> {
        return this.http.post(this.ROUTES.update, body).toPromise();
    }

    gift(context: any): Promise<any> {
        return this.GET(this.ROUTES.gift, context).toPromise();
    }

    giftCondition(context: any): Promise<any> {
        return this.GET(this.ROUTES.giftCondition, context).toPromise();
    }

    list(type = null): Promise<any> {
        let params = type ? { params: { type: type } } : {};
        return this.GET(this.ROUTES.list, null, params).toPromise();
    }

    copyGift(id: number): Promise<any> {
        return this.POST(this.ROUTES.copyGift, null, { id }).toPromise();
    }

    copyGifts(ids: number[]): Promise<any> {
        return this.POST(this.ROUTES.copyGifts, null, { ids }).toPromise();
    }

    deleteGift(id: number): Promise<any> {
        return this.DELETE(this.ROUTES.deleteGift, { id }).toPromise();
    }

    deleteGifts(ids: number[]): Promise<any> {
        return this.POST(this.ROUTES.deleteGifts, null, { ids }).toPromise();
    }

    giftUpload(gifts): Promise<any> {
        return this.POST(this.ROUTES.upload, null, { gifts }).toPromise();
    }
}
