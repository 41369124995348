import { Action } from "redux";
import { FortuneItemsAttribute } from "../../../types/types";
import { Dirty_check, SetDirtyAction } from "./form-dirty.action";
// import { createSelector } from 'reselect';

export interface formDirtyState {
    isDirty: boolean;
}


const initialState: formDirtyState = {
    isDirty: false

}

export const dirtyChekcReducer = function(state: formDirtyState = initialState, action: Action): formDirtyState {
    switch (action.type) {
        case Dirty_check:
            return {
                isDirty: (<SetDirtyAction>action).isDirty,
            }
        default:
            return state
    }
}
