import { NgModule } from '@angular/core';
import { KeyValuePipe } from "./keyvalue/keyvalue";
import { DateTimePipe } from "./keyvalue/dateTime";
import { SafeHtmlPipe } from "./safe-html/safe-html";

@NgModule({
    declarations: [KeyValuePipe, DateTimePipe, SafeHtmlPipe],
    imports: [],
    exports: [KeyValuePipe, DateTimePipe, SafeHtmlPipe]
})
export class PipesModule { }
