<div class="mt-4">
    <h4 class="d-inline-flex align-items-center justify-content-between">
        <div>선물</div>
    </h4>
    <div *ngIf="userGifts.length > 0; else noUserGifts" class="position-relative mt-4">
        <div class="m-timeline-2">
            <div class="m-timeline-2__items m--padding-top-25 m--padding-bottom-30">
                <div class="m-timeline-2__item minHeight-40" *ngFor="let userGift of userGifts">
                  <span class="m-timeline-2__item-time small-font">
                    {{ userGift.created_at | date:'yyyy-MM-dd HH:mm:ss':'+0900' }}
                    <small *ngIf="timeZone" class="small-date">{{ userGift.created_at_tz }}</small>
                  </span>
                    <div class="m-timeline-2__item-cricle">
                        <i class="fa fa-genderless "></i>
                    </div>
                    <div class="m-timeline-2__item-text m--padding-top-5 minHeight-40">
                        <a class="mb-1" routerLink="/force/gift/edit/{{userGift.gift_id}}" target="_blank">{{userGift.gift_id}} - {{ userGift.gift.title }} - {{userGift.gift.description}}</a>
                        <p class="mb-1" [innerHTML]="userGift.status"></p>
                        <p><span>{{userGift.expires_at | date:'날짜: yyyy-MM-dd HH:mm:ss':'+0900'}}</span></p>
                        <p *ngIf="timeZone && userGift.expires_at_tz" class="small-date"><span><small>현지 날짜: {{userGift.expires_at_tz }}</small></span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #noUserGifts>
        <p> 선물함이 비어 있습니다. </p>
    </ng-template>
</div>
