export const environment = {
    production: false,
    language: "ko",
    country: "한국",
    timezone: "Asia/Seoul",
    locationId: 1835847,
    link: {
        free_fortune: 'https://zeppelin.gc.un7qi3.co/#/notebook/2DS8MV14C',
        friend: 'https://zeppelin.gc.un7qi3.co/#/notebook/2DZSPFG5Q',
        search: 'https://search-forceteller-es-kr-bgafdgcqgbczusbzytou7iklpq.ap-northeast-2.es.amazonaws.com/_plugin/kibana',
        event: 'https://event-dev.gc.forceteller.com',
        studio: 'localhost'
    },
    address: "forceteller.com",
    betaBookUrl: 'https://beta-book.forceteller.com/fatebook/',
    bookUrl: 'https://book.forceteller.com/fatebook/',
    themes: {
        'recommend_top': '[앱] 추천탭 상단',
        'recommend_middle': '[앱] 추천탭 중단',
        'recommend_bottom': '[앱] 추천탭 하단',

        'default': '[앱] 일반테마',
        'goonghap': '[앱] 궁합테마',

        'web_top': '[웹] 홈 상단',

        'personal_ranking': 'personal_ranking',
        'personal_similar': 'personal_similar',
    },
    themeItems: {
        'full_image_carousel': {
            name: '이미지만 노출되는 캐로셀형',
            image: 'https://static.forceteller.com/_____8/33/7e30/dd48569fb27564796c41879d399ef8c49.png'
        },
        'thumbnail_carousel': {
            name: '썸네일과 메타정보가 노출되는 캐로셀형',
            image: 'https://static.forceteller.com/____8/fb/d10d/b1f92d605a7316cbeb789a4db35d13e3a.png'
        },
        'text_only': {
            name: '텍스트형',
            image: 'https://static.forceteller.com/5/2a/5467/cf38afe92817a8d613f2fb274912eb01c.png'
        },
        'keyword_cloud': {
            name: '키워드형',
            image: 'https://static.forceteller.com/___8/b3/1182/4eaba2e7cdfecb15b56effb3d8a62e3e8.png'
        },
        'home': {
            name: '홈형',
            image: ''
        },
        'filter': {
            name: '필터형',
            image: ''
        },
        'ranking': {
            name: '랭킹형',
            image: ''
        }
    }
};

