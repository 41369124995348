<input
	*ngIf="inputType == 'text'"
	type="text"
	class="form-control m-input"
	name="subTitle"
	(keypress)="inputTag($event)"
	[(ngModel)]="text"
    [placeholder]="placeholder"
/>
<ng-select
	*ngIf="inputType == 'select'"
	(change)="selectTag($event)"
	[clearable]="false"
	[searchable]="false"
>
	<ng-option value="">선택</ng-option>
	<ng-option
		*ngFor="let obj of objectList"
		[value]="type == 'object' ? obj[objectValue] : obj"
	>
		{{ type == 'object' ? obj[objectName] : obj }}
	</ng-option>
</ng-select>
<div class="d-flex flex-wrap pt-3">
	<span
		class="m-badge m-badge--brand m-badge--wide d-flex align-items-center mr-2 mb-3"
		*ngFor="let tag of list; let i = index"
	>
		{{ type == 'object' ? tag.name : tag }}
		<i class="fa fa-remove ml-2 cursor-pointer" (click)="deleteTag(i)"></i>
	</span>
</div>
