import { Component, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { ActivatedRoute } from "@angular/router";
import { ForceService } from "../../../_services/force/force.service";
import { Subscription } from "rxjs";
import * as momentTz from 'moment-timezone';


declare let $: any;
declare let moment: any;
declare let mApp: any;
declare let toastr: any;

@Component({
    selector: "force-history-list",
    templateUrl: "force-history-list.component.html",
    styleUrls: ['./force-history-list.component.scss'],

})
export class ForceHistoryListComponent implements OnChanges {
    @Input() timeZone;

    public categoryType: any = null;

    public expired_groupArr = [];
    public expired_group = {};

    public limit = 50;
    public infiniteScrollDisabled = false;
    public forceData = { force: 0, bonus: 0 };

    public transactions = [];
    public temp = [];
    public targetTransaction;
    public refundOrCancelReasonMemo = '';

    public now = new Date().getTime();
    paramsSubscription: Subscription;

    constructor(private http: HttpClient,
        private routeParam: ActivatedRoute,
        private forceService: ForceService
    ) {

    }

    ngOnChanges() {
        this.getData();
    }

    ngOnDestroy() {
        $('#refund_or_cancel_modal').modal('hide');
        $('#balance_modal').modal('hide');
        this.paramsSubscription.unsubscribe();
    }

    getData() {
        mApp.block(`#force-history-template`, {
            overlayColor: '#666',
            type: 'loader',
            state: 'primary',
            message: '히스토리 가져오는중',

        });
        this.paramsSubscription = this.routeParam.params.subscribe(param => {
            this.forceService.get({ id: param.id })
                .then((r: any) => {
                    this.transactions = r;
                    this.forceData = { force: 0, bonus: 0 };
                    let firstBonusIdx = _.findLastIndex(this.transactions, t =>
                        t.type == 'purchase' && t.Purchasable.meta.firstTimeBonus
                        && _.find(t.ForceDeposits, ['deposit_type', 'B']));
                    if (firstBonusIdx != -1) {
                        this.transactions[firstBonusIdx].firstPurchase = true;
                    }
                    this.transactions.forEach(t => {
                        t.expired = (t.balance && this.now > t.expires_at) ? true : false;
                        if (this.timeZone) t.created_at_tz = momentTz.tz(t.created_at, this.timeZone).format('YYYY-MM-DD HH:mm:ss');
                        if (t.type == 'purchase') {
                            t.isAbleRefund = t.ForceDeposits.every(fd => fd.amount == fd.balance);
                            t.isAbleCancel = t.ForceDeposits.every(fd => fd.status != 'CANCEL');
                            t.ForceDeposits.forEach(fd => {
                                this.forceBalance(fd);
                                this.groupingBalance(fd);
                            });
                        } else {
                            t.isAbleRefund = t.amount == t.balance;
                            t.isAbleCancel = t.status != 'CANCEL';
                            this.forceBalance(t);
                            this.groupingBalance(t);
                        }
                    });

                    this.expired_groupArr = _.toPairs(this.expired_group);
                    this.expired_groupArr.sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime());

                    this.temp = _.cloneDeep(this.transactions);
                    this.transactionFiltering();
                    mApp.unblock('#force-history-template');
                })
                .catch(err => {
                    console.log(err);
                    mApp.unblock('#force-history-template');
                });
        });
    }

    onScrollDown() {
        this.limit += this.limit;
        this.transactionFiltering();

        if (!this.transactions || this.transactions.length < this.limit) {
            this.infiniteScrollDisabled = true;
        }
    }

    onCategoryChange() {
        this.limit = 50;
        this.infiniteScrollDisabled = false;
        this.transactionFiltering();
    }

    transactionFiltering() {
        this.transactions = this.temp
            .filter(o => this.categoryType == 'deposit' ?
                ((o.type == this.categoryType || o.type == 'purchase') || !this.categoryType) : ((o.type == this.categoryType) || !this.categoryType))
            .slice(0, this.limit);
    }

    forceBalance(forceDeposit) {
        if (forceDeposit.deposit_type == 'B' && forceDeposit.status == 'OK' && forceDeposit.expires_at > this.now) {
            this.forceData.bonus += forceDeposit.balance;
        } else if (forceDeposit.deposit_type == 'F' && forceDeposit.status == 'OK') {
            this.forceData.force += forceDeposit.balance;
        }
    }

    groupingBalance(forceDeposit) {
        if (forceDeposit.expires_at == null || forceDeposit.status == 'REFUND') return;
        let date = moment(forceDeposit.expires_at).format('YYYY-MM-DD');

        if (this.expired_group[date]) {
            this.expired_group[date] += forceDeposit.balance;
        } else {
            if (!forceDeposit.balance) return;
            this.expired_group[date] = forceDeposit.balance;
        }

    }

    /* Refund */
    openRefundModal(transaction) {
        if ((transaction.type == 'purchase' || transaction.type == 'deposit') && transaction.isAbleRefund) {
            this.targetTransaction = transaction;
            $('#refund_modal').modal('show');
        }
    }

    refund() {
        $('#refund_modal').modal('hide');

        if (!this.targetTransaction.isAbleRefund) {
            return
        }

        mApp.block(`#content-body`, {
            overlayColor: '#000000',
            type: 'loader',
            state: 'primary',
            message: '요청하신 작업을 처리 중입니다...',
            css: {
                padding: 0,
                margin: 0,
                width: '30%',
                top: '40%',
                left: '35%',
                textAlign: 'center',
                color: '#000',
                border: '3px solid #aaa',
                backgroundColor: '#fff',
                cursor: 'wait'
            }
        });

        this.forceService.refund({
            purchaseId: this.targetTransaction.type == 'deposit' ? this.targetTransaction.Purchase.id : this.targetTransaction.id,
            memo: this.refundOrCancelReasonMemo
        })
            .then(() => {
                this.refundOrCancelReasonMemo = '';
                toastr.success('환불 되었습니다.', '성공', 1000);
                this.getData();
                mApp.unblock(`#content-body`);
            })
            .catch(e => {
                console.log(e);
                toastr.error('', '실패', 1000);
                this.getData();
                mApp.unblock(`#content-body`);
            });

    }

    openCancelModal(transaction) {
        if (transaction.type == 'withdrawal' && transaction.isAbleCancel) {
            const now = new Date().valueOf();
            this.targetTransaction = transaction;
            if (transaction.ForceWithdrawalDetails) {
                transaction.disableCancelAmount = transaction.ForceWithdrawalDetails.reduce((p, c) =>
                    (!c.ForceDeposit.expires_at && c.ForceDeposit.status === 'OK') || c.ForceDeposit.expires_at > now ? p + c.amount : p, 0);
            }
            $('#cancel_modal').modal('show');
        }
    }

    openRecoverModal(transaction) {
        if (transaction.type === 'withdrawal') {
            const now = new Date().valueOf();
            const depositExpired = [];
            this.targetTransaction = transaction;
            if (transaction.ForceWithdrawalDetails) {
                transaction.ForceWithdrawalDetails.forEach((fw) => {
                    if (fw.ForceDeposit.expires_at > now) {
                        depositExpired.push(true)
                    } else {
                        depositExpired.push(false)
                    }
                })
            }

            if (depositExpired.includes(false)) {
                toastr.error('구매했던 보너스포스가 기한이 만료되었습니다.', '실패', 1000);
            }

            $('#recover_modal').modal('show');
        }
    }

    cancel() {
        $('#cancel_modal').modal('hide');

        if (!this.targetTransaction.isAbleCancel) {
            return
        }

        mApp.block(`#content-body`, {
            overlayColor: '#000000',
            type: 'loader',
            state: 'primary',
            message: '요청하신 작업을 처리 중입니다...',
            css: {
                padding: 0,
                margin: 0,
                width: '30%',
                top: '40%',
                left: '35%',
                textAlign: 'center',
                color: '#000',
                border: '3px solid #aaa',
                backgroundColor: '#fff',
                cursor: 'wait'
            }
        });

        this.forceService.cancel({
            withdrawalId: this.targetTransaction.id,
            memo: this.refundOrCancelReasonMemo
        })
            .then(() => {
                this.refundOrCancelReasonMemo = '';
                toastr.success('취소 되었습니다.', '성공', 1000);
                this.getData();
                mApp.unblock(`#content-body`);
            })
            .catch(e => {
                console.log(e);
                e.status == 400 ? toastr.error('', '이미 취소되었습니다.', 1000) : toastr.error('', '실패', 1000);
                this.getData();
                mApp.unblock(`#content-body`);
            })
    }

    recover() {
        $('#recover_modal').modal('hide');
        this.forceService.recover({
            withdrawalId: this.targetTransaction.id,
        })
            .then(() => {
                this.refundOrCancelReasonMemo = '';
                toastr.success('취소 정정 되었습니다..', '성공', 1000);
                this.getData();
                mApp.unblock(`#content-body`);
            })
            .catch(e => {
                console.log(e);
                e.status == 400 ? toastr.error('', '이미 취소정정되었습니다.', 1000) : toastr.error('', '실패', 1000);
                this.getData();
                mApp.unblock(`#content-body`);
            })
    }

}
