import { NgModule } from "@angular/core";
import { ForceService } from "./force/force.service";
import { BannerService } from "./live/banner.service";
import { HelpService } from "./cs/help.service";
import { FatebookService } from "./cs/fatebook.service";
import { ReservationService } from "./reservation/reservation.service";
import { NoticeService } from "./live/notice.service";
import { CouponService } from "./force/coupon.service";
import { GiftService } from "./force/gift.service";
import { PremiumService } from "./premium/premium.service";
import { CelebService } from "./celeb/celeb.service";
import { ImagePackService } from "./image-pack/image-pack.service";
import { GuardianService } from "./guardian/guardian.service";
import { NotificationService } from "./cs/notification.service";
import { ResultSheetService } from "./fortune-item/result-sheet.service";
import { ChapterResultSheetService } from "./premium/chapter-result-sheet.service";
import { PromotionService } from "./premium/promotion.service";
import { PushService } from "./live/push.service";
import { LeafletService } from "./live/leaflet.service";
import { VersionService } from "./live/version.service";
import { PopularSearchService } from "./live/popular-search.service";
import { ConsumableService } from "./premium/consumable.service";
import { FreeForceTemplatesService } from "./live/free.force.templates.service";
import { ThemeService } from "./theme/theme.service";
import { EtcService } from "./etc/etc.service";
import { TodayService } from "./theme/today.service";
import { SequenceService } from "./premium/sequence.service";
import { ChapterService } from "./premium/chapter.service";
import { CategoryService } from "./fortune-item/category.service";
import { FasService } from "./etc/fas.service";
import { StatsticService } from "./statistic/statstic.service";
import { FortuneService } from "./fortune-item/fortune.service";
import { TagService } from "./etc/tag.service";
import { CommunityService } from "./cs/community.service";
import { ExternalService } from "./etc/external.service";
import { UserService } from "./cs/user.service";
import { MetaTemplatesService } from "./live/meta.templates.service";
import { DailyFortuneService } from "./fortune-item/daily-fortune.service";
import { AdService } from "./fortune-item/ad.service";
import { SectionService } from './section/section.service';
import { TempService } from "app/_services/etc/temp.service";

@NgModule({
    providers: [
        ForceService, BannerService, HelpService, ReservationService,
        NoticeService, CouponService, GiftService, PremiumService,
        CelebService, ImagePackService, GuardianService, NotificationService,
        ResultSheetService, ChapterResultSheetService, PromotionService,
        PushService, LeafletService, VersionService, ConsumableService,
        TagService, FreeForceTemplatesService, PopularSearchService,
        ThemeService, EtcService, TodayService, SequenceService, ChapterService,
        CategoryService, FasService, StatsticService, FortuneService, FatebookService,
        CommunityService, ExternalService, UserService, MetaTemplatesService,
        DailyFortuneService, AdService, TempService, SectionService
    ]
})
export class ServiceModule {
}
