import { Action } from "redux";
import { FortuneItemsAttribute } from "../../../types/types";
import { Edit_FortuneItem, EditFortuneItemAction, Dirty_FortuneItemEdit, SetDirtyFortuneItemEditAction } from "./fortune-item.action";
// import { createSelector } from 'reselect';

export interface FortuneItemState {
    fortuneItem: { FortuneItem: FortuneItemsAttribute };
    isDirty: boolean;
}


const initialState: FortuneItemState = {
    fortuneItem: null,
    isDirty: false

}

export const fortuneItemReducer = function(state: FortuneItemState = initialState, action: Action): FortuneItemState {
    switch (action.type) {
        case Edit_FortuneItem:
            return {
                fortuneItem: (<EditFortuneItemAction>action).fortuneItem,
                isDirty: state.isDirty
            }
        case Dirty_FortuneItemEdit:
            return {
                fortuneItem: state.fortuneItem,
                isDirty: (<SetDirtyFortuneItemEditAction>action).isDirty,
            }
        case Dirty_FortuneItemEdit:
            return {
                fortuneItem: state.fortuneItem,
                isDirty: (<SetDirtyFortuneItemEditAction>action).isDirty,
            }
        default:
            return state
    }
}
