import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChange } from '@angular/core';
import { AuthService } from 'ng2-ui-auth';
declare let Dropzone: any;

@Component({
    selector: 'app-ctsv-uploader',
    templateUrl: 'ctsv-uploader.component.html',
})

export class CTSVUploaderComponent implements OnInit {

    public dropzone;
    @Input('uploaderId') uploaderId: string;
    @Input('targetId') targetId: string;
    @Input('type') type: string;
    @Input() apiUrl: string;
    @Output() public successCsv = new EventEmitter();
    @Output() public errorCsv = new EventEmitter();

    constructor(private auth: AuthService) { }

    ngOnInit() {
    }

    preventEvent($event: MouseEvent) {
        $event.preventDefault();
    }

    ngOnChanges(changes: SimpleChange): void {
        if (this.dropzone) {
            this.dropzone.options.headers = {
                'item-id': this.targetId
            }
        }
    }

    ngAfterViewInit() {
        let url = this.apiUrl ? this.apiUrl : (this.type == 'chapter' ? '/api/upload/chapter/sheets/v2' : '/api/upload/sheets/v2');
        this.dropzone = new Dropzone(`#${this.uploaderId}`, {
            url: url,
            paramName: "file", // The name that will be used to transfer the file
            maxFiles: 1,
            maxFilesize: 5, // MB
            addRemoveLinks: true,
            dictRemoveFile: '파일 삭제',
            accept: function(file, done) {
                done();
            },
            sending: (file, xhr, formData) => {
            },
            success: (result, response) => {
                setTimeout(() => {
                    this.dropzone.files.forEach(file => {
                        this.dropzone.removeFile(file)
                    })
                }, 1000);
                this.successCsv.emit({ result, response });
            },
            error: (err) => {
                console.log(err)
                setTimeout(() => {
                    this.dropzone.files.forEach(file => {
                        this.dropzone.removeFile(file)
                    })
                }, 1000);
                if (err.xhr && err.xhr.response) {
                    this.errorCsv.emit(JSON.parse(err.xhr.response).message)

                } else {
                    this.errorCsv.emit('assets/custom/sahwa-upload-fail.png')
                }
            },
        })
        this.dropzone.options.headers = {
            'item-id': this.targetId,
            'Authorization': `Bearer ${this.auth.getToken()}`
        }
    }

}
