<div *ngFor="let tag of _tagList" class="m-stack__demo-item" (click)="selectTag(tag)" [class.selected]="selectedTag === tag">
    <div class="m-stack m-stack--ver m-stack--general ">
        <div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--fluid" style="border: 0">
            <span style="margin-left: 20px" style="font-size: 15px">
                {{tag}}
            </span>
        </div>
    </div>

</div>
