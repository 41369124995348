<div class="target-banner-item">
    <div class="d-flex align-items-center">
        <div class="id">#{{item.id}}</div>
        <div style="width: 100px;">{{item.type === 'TEXT' ? '텍스트' : (item.type === 'IMAGE' ? '이미지' : '텍스트이미지')}}</div>
        <div
                class="custom-background-image thumb-bg-img" *ngIf="item.type !== 'TEXT'">
            <img [src]="item.info.image" alt="">
        </div>
    </div>
    <div class="item-text">
        <div>
            <h5 class="text-dark mb-2">
                {{item.title}}
            </h5>
            <p class="text-primary mb-2">
                <span>{{item.info.text}}</span> <br/><br/> <span class="text-dark">링크1: {{item.info.link}}</span>
                &nbsp;<span class="text-dark" *ngIf="item.type === 'TEXTIMAGE' || item.type === 'TARGETING'">링크2: {{item.info.imageLink}}</span>
            </p>
        </div>
        <div class="m-badge m-badge--wide d-flex align-items-center"
            *ngIf="item.startedAt && item.endedAt"
        >
            {{item.startedAt | date:'yyyy-MM-dd HH:mm:ss':'+0900' }} ~
            {{item.endedAt | date:'yyyy-MM-dd HH:mm:ss':'+0900' }}
        </div>
    </div>
<div class="d-flex align-items-center">
    <div class="status {{item.status}}">{{item.status == 'S3' ? '활성' : item.status == 'S2' ? '어드민' : '비활성' }}</div>
    <app-checkbox style="margin-bottom: 20px;" (click)="$event.stopPropagation()"[(value)]="item.checked"></app-checkbox>
</div>
</div>
