import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class MetaTemplatesService extends BaseAPIService {

    private ROUTES = {
        get: '/meta/template/:type/:id',
        list: '/meta/template/:type',
        save: '/meta/template',
        update: '/meta/template/:id',
        delete: '/meta/template/:id'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    get(type: string, id: any): Promise<any> {
        return this.GET(this.ROUTES.get, { type, id }).toPromise();
    }

    list(type: string): Promise<any> {
        return this.GET(this.ROUTES.list, { type }).toPromise();
    }

    save(template): Promise<any> {
        return this.POST(this.ROUTES.save, null, { template }).toPromise();
    }

    update(id: any, template: any): Promise<any> {
        return this.PATCH(this.ROUTES.update, { id }, { template }).toPromise();
    }

    delete(id: any): Promise<any> {
        return this.DELETE(this.ROUTES.delete, { id }).toPromise();
    }
}
