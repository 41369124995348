import { Component, Input, OnInit } from '@angular/core';
import { AdBottomItemsAttribute } from "../../../../../types/types";

@Component({
    selector: 'app-fortune-ad-item',
    templateUrl: './fortune-ad-item.component.html',
    styleUrls: ['./fortune-ad-item.component.scss']
})
export class FortuneAdItemComponent implements OnInit {

    @Input()
    item: AdBottomItemsAttribute & { checked: boolean };
    constructor() { }

    ngOnInit(): void {
    }

}
