import { Observable, Subscription } from 'rxjs';
import {
    CanDeactivate, Router, ActivatedRoute,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { select } from '@angular-redux/store';
declare var swal: any;
declare var mApp: any;
export interface CanCompnentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean

}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanCompnentDeactivate> {
    // auth 경로를 타게되면 타사 인증으로부터 리다이렉트되므로 SOP 정책에 걸린다.
    //
    // @select(['fortuneItemState', 'isDirty']) fortuneItemIsDirty$: Observable<boolean>
    // @select(['fortuneItemCategoriesState', 'isDirty']) fortuneItemCategoiresIsDirty$: Observable<boolean>
    @select(['dirtyCheckState', 'isDirty']) dirtyCheckState$: Observable<boolean>
    private formIsDirty: boolean;
    constructor(private router: Router, private routerParam: ActivatedRoute) {
        // this.fortuneItemCategoiresIsDirty$.subscribe(isDirty => {
        //     this.formIsDirty = isDirty
        // })
        // this.fortuneItemIsDirty$.subscribe((isDirty) => {
        //     this.formIsDirty = isDirty
        // })
        this.dirtyCheckState$.subscribe(isDirty => {
            this.formIsDirty = isDirty
        })

    }
    canDeactivate() {
        // console.log(this.router.url)
        // if (this.router.url.includes('/premium-item/edit')) {
        //     return swal({
        //         title: '이곳을 나가시겠습니까?',
        //         text: "저장을 성공적으로 하셨으면 나가셔두 됩니다. 프리미엄 아이템은 수정은 무조건 이 창이 뜹니다. 실수하지 않게요.",
        //         type: 'question',
        //         // position: 'top',
        //         showCancelButton: true,
        //         confirmButtonText: '확인',
        //         cancelButtonText: '취소',
        //         reverseButtons: true
        //     }).then((result) => {
        //         if (result.value) {
        //             this.formIsDirty = false;
        //             return true
        //         } else if (result.dismiss === 'cancel') {
        //             return false
        //         }
        //     });


        // }


        if (this.formIsDirty) {
            return swal({
                title: '이곳을 나가시겠습니까?',
                text: "작성한 내용이 사라 질 수 있습니다.",
                type: 'question',
                // position: 'top',
                showCancelButton: true,
                confirmButtonText: '확인',
                cancelButtonText: '취소',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.formIsDirty = false;
                    return true
                } else if (result.dismiss === 'cancel') {
                    return false
                }
            });
        } else {
            this.formIsDirty = false;
            return true
        }


    }
}
