import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { AdService } from 'app/_services/fortune-item/ad.service';
declare let toastr: any;

@Component({
    selector: 'app-item-mapping-upload',
    templateUrl: './app-item-mapping-upload.component.html',
    styleUrls: ['./app-item-mapping-upload.component.scss']
})
export class AdItemMappingUploadComponent implements OnInit {
    public uploadList = [];
    public isUploading = false;
    public forceChk = false;
    @Input()
    public active = false;
    @Output()
    public activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public onSuccess: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private adService: AdService,
    ) { }

    ngOnInit(): void {
    }

    successCsv({ response }) {
        this.uploadList = this.uploadList.concat(response);
        this.forceChk = true;
    }

    upload() {
        this.isUploading = true;
        this.adService.uploadFortuneMappedItems(this.uploadList)
            .then(r => {
                toastr.success('업로드가 완료되었습니다.');
                this.onSuccess.emit();
                this.close();
            })
            .catch(e => {
                toastr.error(e.error.message);
            })
            .finally(() => {
                this.isUploading = false;
            })
    }

    close() {
        this.activeChange.emit(false);
    }

    deleteItem(index: number) {
        this.uploadList = this.uploadList.filter((v, i) => i !== index);
    }

    addItem() {
        this.uploadList = [...this.uploadList, {
            itemId: '',
            text: '',
            link: ''
        }];
    }

    download() {
        const url = `${window.location.protocol}//${window.location.host}/api/upload/example/download/csv?keys=itemId,text,link`;
        window.open(url, 'blank');
    }

}
