import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class NoticeService extends BaseAPIService {

    private ROUTES = {
        list: '/notice/list',
        get: '/notice/:id',
        edit: '/notice/id'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    get(context: any): Promise<any> {
        return this.GET(this.ROUTES.get, context).toPromise();
    }

    create(body: any): Promise<any> {
        return this.http.post(this.ROUTES.edit, body).toPromise();
    }

    update(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.edit, body).toPromise();
    }

}
