import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class BannerService extends BaseAPIService {

    private ROUTES = {
        list: '/banner/ad/list',
        tags: '/banner/ad/list/tags',
        get: '/banner/ad/:id',
        edit: '/banner/ad',
        delete: '/banner/ad/:id',
        reorder: '/banner/ad/item/reorder',
        itemCreate: '/banner/ad/items',
        itemUpdate: '/banner/ad/item',
        itemDelete: '/banner/ad/item/:id',
        itemsDelete: '/banner/ad/items/delete',
        itemsCopy: '/banner/ad/items/copy'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    tags(): Promise<any> {
        return this.GET(this.ROUTES.tags).toPromise();
    }

    get(context: any): Promise<any> {
        return this.GET(this.ROUTES.get, context).toPromise();
    }

    create(body: any): Promise<any> {
        return this.http.post(this.ROUTES.edit, body).toPromise();
    }

    update(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.edit, body).toPromise();
    }

    delete(context: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.delete, context)).toPromise();
    }

    reorder(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.reorder, body).toPromise();
    }

    itemDelete(context: any): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.itemDelete, context)).toPromise();
    }

    itemCreate(body: any): Promise<any> {
        return this.http.post(this.ROUTES.itemCreate, body).toPromise();
    }

    itemUpdate(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.itemUpdate, body).toPromise();
    }

    itemsDelete(body: any): Promise<any> {
        return this.http.post(this.ROUTES.itemsDelete, body).toPromise();
    }
    itemsCopy(body: any): Promise<any> {
        return this.http.post(this.ROUTES.itemsCopy, body).toPromise();
    }

}
