<div class="mt-4">
    <h4 class="d-inline-flex align-items-center justify-content-between">
        <div>알림함</div>
    </h4>
    <div *ngIf="notifications.length > 0; else noNotifications" class="position-relative mt-4">
        <div class="m-timeline-2">
            <div class="m-timeline-2__items m--padding-top-25 m--padding-bottom-30">
                <div class="m-timeline-2__item minHeight-40" *ngFor="let notification of notifications">
                  <span class="m-timeline-2__item-time small-font">
                    {{ notification.created_at  | date:'yyyy-MM-dd HH:mm:ss':'+0900' }}
                    <small *ngIf="timeZone" style="display: block">{{ notification.created_at_tz }}</small>
                  </span>
                    <div class="m-timeline-2__item-cricle">
                        <i class="fa fa-genderless "></i>
                    </div>
                    <div class="m-timeline-2__item-text m--padding-top-5 minHeight-40">
                        <p><b> {{ notification.content }}</b></p>
                        <p><span>- 링크: {{ notification.target }}</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #noNotifications>
        <p> 알림함이 비어 있습니다. </p>
    </ng-template>
</div>
