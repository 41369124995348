<div class="modal" [class.active]="active">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="csvPromotionsModalLabel">
                    <b>포스팩 예약</b>
                </h5>
                <button type="button" class="close" (click)="close()">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
            <div class="modal-body">
                    <div class="d-flex align-items-center justify-content-between m-2">
                        <h6>CSV 양식 샘플</h6>
                        <button class="btn btn-primary" type="button" (click)="download()">샘플 다운로드</button>
                    </div>
                    <table class="table table-striped m-table">
                        <thead class="m-datatable__head thead-light">
                        <tr>
                            <th>forcepackId</th>
                            <th>bonusForce</th>
                            <th>startsAt</th>
                            <th>endsAt</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>30</td>
                            <td>200</td>
                            <td>2025-01-01 00:00:00</td>
                            <td>2030-01-01 00:00:00</td>
                        </tr>
                        </tbody>
                    </table>
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <app-ctsv-uploader
                            class="app-content-body-max-width"
                            apiUrl="/api/upload/csv"
                            uploaderId="ctsv-uploader"
                            (successCsv)="successCsv($event)">
                    </app-ctsv-uploader>
                </div>
                <div class="my-2 d-flex justify-content-between">
                    <button class="btn btn-primary" [disabled]="!uploadList.length || !f.form.valid || isUploading" form="uploadForm">해당 리스트 업로드</button>
                    <a class="btn btn-outline-info m-btn m-btn--icon  m-btn--icon-only m-btn--pill m-btn--air"
                       href="javascript:;" (click)="addItem()">
                        <i class="la la-plus"></i>
                    </a>
                </div>
                <form id="uploadForm" #f="ngForm" (ngSubmit)="upload()">
                    <table class="table table-striped- table-bordered table-hover table-checkable dataTable no-footer dtr-inline">
                        <thead style="background: #ebedf2" class="m-datatable__head">
                        <tr>
                            <th>forcepackId</th>
                            <th>bonusForce</th>
                            <th>startsAt</th>
                            <th>endsAt</th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of uploadList; let i = index" class="banner-area-list-row">
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.forcepackId" name="forcepackId{{i}}" required>
                                <div class="form-control-feedback" *ngIf="forceChk && f.controls['forcepackId'+i]?.invalid">값을 확인해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="number" class="form-control" [(ngModel)]="item.bonusForce" name="bonusForce{{i}}" required>
                                <div class="form-control-feedback" *ngIf="forceChk && f.controls['bonusForce'+i]?.invalid">값을 확인해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.startsAt" name="startsAt{{i}}" [pattern]="dateRegex" placeholder="YYYY-MM-DD HH:mm:ss" required>
                                <div class="form-control-feedback" *ngIf="forceChk && f.controls['startsAt'+i]?.invalid">값을 확인해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <input type="text" class="form-control" [(ngModel)]="item.endsAt" name="endsAt{{i}}" [pattern]="dateRegex" placeholder="YYYY-MM-DD HH:mm:ss" required>
                                <div class="form-control-feedback" *ngIf="forceChk && f.controls['endsAt'+i]?.invalid">값을 확인해주세요</div>
                            </td>
                            <td class="form-group has-danger">
                                <a href="javascript:;" class="btn btn-danger m-btn m-btn--icon m-btn--icon-only mx-2"
                                   (click)="deleteItem(i)">
                                    <i class="fa fa-trash cc_pointer"></i>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    </div>
</div>
