import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class PushService extends BaseAPIService {

    private ROUTES = {
        list: '/push/list',
        targetUserQueryList: '/push/target-user-query/list',
        create: '/push/insert',
        testPushSend: '/onesignal/push',
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    targetUserQueryList(): Promise<any> {
        return this.GET(this.ROUTES.targetUserQueryList).toPromise();
    }

    create(body: any): Promise<any> {
        return this.http.post(this.ROUTES.create, body).toPromise();
    }

    testPush(body: any): Promise<any> {
        return this.http.post(this.ROUTES.testPushSend, body).toPromise();
    }

}
