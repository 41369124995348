<div class="form-group m-form__group ">
    <div class="">
        <div class="m-dropzone dropzone m-dropzone--primary" [id]="uploaderId">
            <div class="m-dropzone__msg dz-message needsclick">
                <span class="m-dropzone__msg-desc">
                    CSV, TSV 파일만 가능
                </span>
                <br>
                <span class="m-dropzone__msg-desc">
                    총 컬럼 : id, name, gender, year, month, day, hour, minute, hmUnsure, classification, hiddenTag, locationId,
                    avatar_url
                </span>
                <br>
                <span class="m-dropzone__msg-desc">
                    등록 전 CSV 다운 받아 최신 데이터로 유지하시길 권장 드립니다.
                </span>
                <br>
                <span class="m-dropzone__msg-desc">
                    name, gender, locationId, year, month, day, hmUnsure, hour, minute 값이 없거나 부적절한 경우 에러가 발생됩니다.
                </span>
                <br>
                <span class="m-dropzone__msg-desc">
                    id 값이 존재 할 경우 해당 id 의 데이터에 덮어쓰기가 적용되고 값이 비었을 경우 새로운 데이터가 생성됩니다.
                </span>
            </div>
        </div>

    </div>
</div>
