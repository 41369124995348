import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout/layout.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { DefaultComponent } from '../pages/default/default.component';
import { AsideNavComponent } from './aside-nav/aside-nav.component';
import { FooterComponent } from './footer/footer.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { TooltipsComponent } from './tooltips/tooltips.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HrefPreventDefaultDirective } from '../../_directives/href-prevent-default.directive';
import { UnwrapTagDirective } from '../../_directives/unwrap-tag.directive';
import { PageIndexComponent } from './page-index/page-index.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { NgDragDropModule } from 'ng-drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageSelectorComponent } from './image-selector/image-selector.component';
import { BlockUIDirective } from '../../_directives/block-ui.directive';
import { FormsModule } from '@angular/forms';
import { GuardianImageSelectorComponent } from './image-selector/guardian-image-selector/guardian-image-selector.component';
import { GuardianImageSelectorV2Component } from './image-selector/guardian-image-selector-v2/guardian-image-selector-v2.component';
import { UIComponentsModule } from "../../ui-components/ui-components.module";
import { DragulaModule } from "ng2-dragula";
import { DateValidatorDirective } from "../../_directives/date-validator.directive";


@NgModule({
    declarations: [
        LayoutComponent,
        HeaderNavComponent,
        DefaultComponent,
        AsideNavComponent,
        FooterComponent,
        ScrollTopComponent,
        TooltipsComponent,
        HrefPreventDefaultDirective,
        UnwrapTagDirective,
        DateValidatorDirective,
        PageIndexComponent,
        ImageSelectorComponent,
        BlockUIDirective,
        GuardianImageSelectorComponent,
        GuardianImageSelectorV2Component
    ],
    exports: [
        LayoutComponent,
        HeaderNavComponent,
        AsideNavComponent,
        FooterComponent,
        ScrollTopComponent,
        TooltipsComponent,
        HrefPreventDefaultDirective,
        DateValidatorDirective,
        PageIndexComponent,
        NgSelectModule,
        NgDragDropModule,
        DragDropModule,
        ImageSelectorComponent,
        BlockUIDirective,
        GuardianImageSelectorComponent,
        GuardianImageSelectorV2Component
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        DragDropModule,
        NgDragDropModule.forRoot(),
        UIComponentsModule,
        DragulaModule.forRoot(),
    ]
})
export class LayoutModule {
}
