import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class ForceService extends BaseAPIService {

    private ROUTES = {
        get: '/user/detail/:id/history/v2',
        refund: '/user/purchase/refund',
        cancel: '/user/withdrawal/cancel',
        recover: '/user/withdrawal/recover',
        getPurchasable: '/purchasables/:id',
        editPurchasable: '/purchasables/id',
        getPurchasableByStore: '/purchasables/list/nocoupon/:store',
        setOrder: '/purchasables/list/order',
        editLog: '/purchasables/log',
        logActive: '/purchasables/log/deactive',
        timeOfFirstPurchase: '/force/timeoffirstpurchase',
        newPurchase: '/force/newpurchase',
        sumByDate: '/force/purchase/sum/bydate',
        reVer2: '/force/purchase/re/ver2',
        countByForcePack: '/force/purchase/countbyforcepack/ver2',
        reservation: '/force/reservation'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    get(context: any): Promise<any> {
        return this.GET(this.ROUTES.get, context).toPromise();
    }

    refund(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.refund, body).toPromise();
    }

    cancel(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.cancel, body).toPromise();
    }

    recover(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.recover, body).toPromise();
    }

    getPurchasable(context: any): Promise<any> {
        return this.GET(this.ROUTES.getPurchasable, context).toPromise();
    }

    createPurchasable(body: any): Promise<any> {
        return this.http.post(this.ROUTES.editPurchasable, body).toPromise();
    }

    updatePurchasable(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.editPurchasable, body).toPromise();
    }

    getPurchasableByStore(context: any): Promise<any> {
        return this.GET(this.ROUTES.getPurchasableByStore, context).toPromise();
    }

    setOrder(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.setOrder, body).toPromise();
    }

    createLog(body: any): Promise<any> {
        return this.http.post(this.ROUTES.editLog, body).toPromise();
    }

    updateLogDeActive(body: any): Promise<any> {
        return this.http.patch(this.ROUTES.logActive, body).toPromise();
    }

    getTimeOfFirstPurchase(opt: any): Promise<any> {
        return this.GET(this.ROUTES.timeOfFirstPurchase, null, opt).toPromise();
    }

    getNewPurchase(opt: any): Promise<any> {
        return this.GET(this.ROUTES.newPurchase, null, opt).toPromise();
    }

    getSumByDate(opt: any): Promise<any> {
        return this.GET(this.ROUTES.sumByDate, null, opt).toPromise();
    }

    getReVer2(opt: any): Promise<any> {
        return this.GET(this.ROUTES.reVer2, null, opt).toPromise();
    }

    getCountByForcePack(opt: any): Promise<any> {
        return this.GET(this.ROUTES.countByForcePack, null, opt).toPromise();
    }

    reservation(list: any[]) {
        return this.POST(this.ROUTES.reservation, null, { list }).toPromise();
    }
}
