<!-- begin::Quick Nav -->
<ul class="m-nav-sticky tooltip-wrapper" style="margin-top: 30px;" appunwraptag="">
	<li class="m-nav-sticky__item" *ngFor="let tool of tools" (click)="eventEmit(tool.actionName)">
		<a href="">
			<span>{{tool.tooltipText}}</span>
			<i [ngClass]="tool.icon"></i>
		</a>
	</li>

</ul>
<!-- begin::Quick Nav -->