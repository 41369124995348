import { Component, OnInit, ViewEncapsulation, AfterViewInit, SimpleChange, Input } from '@angular/core';
import { Helpers } from '../../../helpers';
import { environment } from '../../../../environments/environment';

declare let mLayout: any;
@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    styleUrls: ["./header-nav.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {



    public togglePostion: boolean = true;
    public env = environment.production ? '운영' : '개발';
    @Input()
    public style = {
        text_color: '#fff',
        header_nav_color: '#000',
    };
    public windowWidth;
    constructor() {

        this.windowWidth = window.innerWidth

    }
    toogleHamburger() {

        this.togglePostion = this.togglePostion ? false : true;
    }

    toggleHambuger() {
        console.log('123')
    }



    ngOnInit() {

    }
    ngAfterViewInit() {

        mLayout.initHeader();

    }

}
