<button *ngIf="!nodisplay" type="submit" class="btn btn-outline-info" (click)="openSelectModal()">
  {{subject}} - {{convertDataIdName()}}
</button>

<div class="modal fade" [id]="'image-selector-modal-'+subject" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <b>{{subject}}을 선택하세요</b>
        </h5>
      </div>
      <div class="modal-body">
        <div class="m-scrollable" data-scrollbar-shown="true" data-scrollable="true" data-max-height="500">
          <div (click)="selectData($event,data)" [ngClass]="{'selected': data.selected}" class="image-selector-select-col"
               style="margin : 5px 5px 15px 5px;  display: flex ;flex-direction: column; align-items: center"
               *ngFor="let data of imageDatas ">
                        <span style="text-align: center; width: 100px;">
                            {{data.id >= 0 ? data.id + ' :' : '' }} {{data.name}}
                        </span>
            <div class="custom-background-image" style="width: 96px;height: 96px; margin-top: 8px;" [ngClass]="{'zoom': zoom}"
                 [ngStyle]="{ 'background-image': 'url('+data.image_url +')'}"></div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="unselectData()">
          취소
        </button>
        <button type="button" [ngClass]="{'btn-primary': isExistSelectedData ,'btn-disabled':!isExistSelectedData}" class="btn"
                (click)="applySelectedData()">
          선택
        </button>
      </div>
    </div>
  </div>
</div>
